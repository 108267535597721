<template>
    <div>


        <div style="margin-bottom: 15px;">
            <template>

                <el-form>

                    <el-select v-model="agentSelectedId" @change="handleAgentSelected" placeholder="请选择代理商">
                        <el-option
                                key="0"
                                label="请选择代理商"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in agentList"
                                :key="item.id"
                                :label="item.agent_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select style="margin-left:10px;width: 150px;"
                               v-model="salesmanSelectedId" placeholder="请选择业务员">
                        <el-option
                                key="0"
                                label="请选择业务员"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in salesmanList"
                                :key="item.id"
                                :label="item.real_name"
                                :value="item.id">
                        </el-option>
                    </el-select>

                    <el-input style="margin-left:10px;width: 150px;"
                              placeholder="请输入商家名称"
                              v-model="searchMerchantsName"
                              clearable>
                    </el-input>

                    <el-input style="margin-left:10px;width: 150px;"
                              placeholder="请输入手机号"
                              v-model="searchMerchantsPhone"
                              clearable>
                    </el-input>

                    <el-button style="margin-left:10px;" type="primary" @click="handleSearch()" icon="el-icon-search">
                        搜索
                    </el-button>

                    <el-button  v-if="permissions['api/op.merchants'] && permissions['api/op.merchants']['POST']" style="margin-left:10px;" type="success" icon="el-icon-add-location" @click="openDialog">
                        新增
                    </el-button>

                </el-form>


            </template>


        </div>

        <el-dialog
                top="1vh"
                :title="dialogTitle"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="handleDialogClose"
                :visible.sync="dialogVisible"
                width="50%"
        >

            <el-form ref="form" :rules="validateRulesObject" :validate-on-rule-change="false" :model="form"
                     label-width="130px"
                     style="padding-right: 20px;">

                <template>

                    <el-tabs tab-position="left">

                        <el-tab-pane label="基本设置">

                            <el-form-item label="商户名称" prop="name">
                                <el-input show-word-limit maxlength="50" v-model="form.name"></el-input>
                            </el-form-item>

                            <el-form-item label="联系人" prop="contacts">
                                <el-input show-word-limit maxlength="20" v-model="form.contacts"></el-input>
                            </el-form-item>

                            <el-form-item label="绑定手机号" prop="mobile">
                                <el-input show-word-limit maxlength="13" v-model="form.mobile"></el-input>
                            </el-form-item>

                            <el-form-item label="登录密码" prop="password">
                                <el-input maxlength="16" type="password" v-model="form.password"></el-input>
                            </el-form-item>

                            <el-form-item label="开店时间" prop="shopstart">
                                <el-time-select :picker-options="{
                                            start: '00:00',
                                            step: '00:30',
                                            end: '24:00'
                                            }" placeholder="开店时间" v-model="form.shopstart"
                                ></el-time-select>
                            </el-form-item>

                            <el-form-item label="关店时间" prop="shopend">
                                <el-time-select :picker-options="{
                                            start: '00:00',
                                            step: '00:30',
                                            end: '24:00'
                                            }" placeholder="关店时间" v-model="form.shopend"
                                ></el-time-select>
                            </el-form-item>

                            <el-form-item label="店铺电话" prop="phone">
                                <el-input show-word-limit maxlength="13" v-model="form.phone"></el-input>
                            </el-form-item>

                            <!--                            <el-form-item label="人均消费" prop="averageprice">-->
                            <!--                                <el-input show-word-limit maxlength="8" v-model="form.averageprice"></el-input>-->
                            <!--                            </el-form-item>-->

                            <el-form-item label="折扣信息" prop="ad_message">
                                <el-input show-word-limit maxlength="50" v-model="form.ad_message"></el-input>
                            </el-form-item>

                            <el-form-item label="营业状态" prop="status">
                                <el-switch v-model="form.status"></el-switch>
                            </el-form-item>

                        </el-tab-pane>

                        <el-tab-pane label="运营设置">

                            <el-form-item label="代理商" prop="agency">
                                <el-select v-model="form.agency" @change="handleFormAgentSelected" placeholder="">
                                    <el-option
                                            key="0"
                                            label="请选择代理商"
                                            :value="0">
                                    </el-option>
                                    <el-option
                                            v-for="item in agentList"
                                            :key="item.id"
                                            :label="item.agent_name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="业务员" prop="salesman">
                                <el-select v-model="form.salesman">
                                    <el-option
                                            key="0"
                                            label="请选择业务员"
                                            :value="0">
                                    </el-option>
                                    <el-option
                                            v-for="item in salesmanFormList"
                                            :key="item.id"
                                            :label="item.real_name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="计费模式" prop="per_billingunit">
                                <el-select v-model="form.per_billingunit" placeholder="请选选择收费模式">
                                    <el-option :key="1" label="小时" :value="1"></el-option>
                                    <el-option :key="2" label="分钟" :value="2"></el-option>
                                </el-select>
                            </el-form-item>

                            <!--                            <el-form-item label="佣金比例" prop="brokerage">-->
                            <!--                                <el-input show-word-limit maxlength="3" v-model="form.brokerage">-->
                            <!--                                    <template slot="append">%</template>-->
                            <!--                                </el-input>-->
                            <!--                            </el-form-item>-->

                            <el-form-item label="佣金比例" prop="rate">

                                <el-slider
                                        v-model="form.brokerage">
                                </el-slider>

                            </el-form-item>

                            <el-form-item label="押金" prop="per_deposit">
                                <el-input show-word-limit maxlength="8" v-model="form.per_deposit"></el-input>
                            </el-form-item>

                            <el-form-item label="免费时间" prop="per_freetime">
                                <el-input show-word-limit maxlength="8" v-model="form.per_freetime"></el-input>
                            </el-form-item>

                            <el-form-item label="24小时封顶消费" prop="per_ceiling">
                                <el-input show-word-limit maxlength="8" v-model="form.per_ceiling"></el-input>
                            </el-form-item>

                            <el-form-item label="单价" prop="price">
                                <el-input show-word-limit maxlength="8" v-model="form.price"></el-input>
                            </el-form-item>


                        </el-tab-pane>

                        <el-tab-pane label="位置设置">

                            <el-form-item label="省份" prop="province">
                                <el-select v-model="form.province" @change="handleFormProvinceSelected"
                                           placeholder="请选择省份">
                                    <el-option
                                            key="0"
                                            label="请选择省份"
                                            value="0">
                                    </el-option>
                                    <el-option
                                            v-for="item in provinceList"
                                            :key="item.addr_id"
                                            :label="item.addr_name"
                                            :value="item.addr_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="市" prop="province">
                                <el-select v-model="form.city" placeholder="请选择城市">
                                    <el-option
                                            key="0"
                                            label="请选择城市"
                                            value="0">
                                    </el-option>
                                    <el-option
                                            v-for="item in cityList"
                                            :key="item.addr_id"
                                            :label="item.addr_name"
                                            :value="item.addr_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="地址" prop="addr">
                                <el-input show-word-limit maxlength="255" style="margin-bottom: 10px;"
                                          v-model="form.addr"></el-input>
                            </el-form-item>

                            <el-form-item label="经度" prop="longitude">
                                <el-input show-word-limit maxlength="19" v-model="form.longitude"></el-input>
                            </el-form-item>

                            <el-form-item label="纬度" prop="latitude">
                                <el-input show-word-limit maxlength="19" v-model="form.latitude"></el-input>
                            </el-form-item>

                            <baidu-map v-loading="mapLoading" :center="center" :zoom="zoom" @ready="handlerMapInit"
                                       style="margin-left:15%;width:85%;height:250px" @click="getClickInfo"
                                       :scroll-wheel-zoom='true'>

                                <bm-local-search :keyword="form.addr" :auto-viewport="true"
                                                 zoom="12.8"
                                                 style="display: none"></bm-local-search>

                            </baidu-map>

                        </el-tab-pane>

                        <el-tab-pane label="门店图设置">

                            <el-form-item label="发现页照片" prop="background_image">
                                <el-upload
                                        ref="uploadFile1"
                                        class="upload-demo"
                                        action="api/op/merchantsImages"
                                        :headers="uploadHeader"
                                        :on-success="handeUploadFile1"
                                        :on-remove="handleRemoveFile1"
                                        :file-list="form.background_image"
                                        :limit="1"
                                        list-type="picture">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png文件，且不超过5MB</div>
                                </el-upload>
                            </el-form-item>

                            <el-form-item label="店铺图" prop="picture">
                                <el-upload
                                        ref="uploadFile2"
                                        class="upload-demo"
                                        action="api/op/merchantsImages"
                                        :headers="uploadHeader"
                                        :on-success="handeUploadFile2"
                                        :on-remove="handleRemoveFile2"
                                        :file-list="form.picture"
                                        :limit="5"
                                        list-type="picture">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png文件，且不超过5MB</div>
                                </el-upload>
                            </el-form-item>


                        </el-tab-pane>

                    </el-tabs>
                </template>

            </el-form>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
                class="box"
                title="设备"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialogVisible2"

                width="85%"
        >

            <el-scrollbar style="height:100%" v-if="dialogVisible2">

                <chargingtank :merchantsId="queryMerchantsId"></chargingtank>

            </el-scrollbar>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">关闭</el-button>
            </span>
        </el-dialog>

        <template>
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        label="商家名称"
                        width="250"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="代理商"
                        width="150"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.agent_details ? scope.row.agent_details.agent_name : '暂无'}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="业务员"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.salesman_details ? scope.row.salesman_details.real_name : '暂无'}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="绑定手机"
                        width="200"
                >
                    <template slot-scope="scope">
                        店铺电话：<span>{{ scope.row.phone }}</span><br/>
                        手机号码：<span>{{ scope.row.mobile }}</span><br/>
                    </template>
                </el-table-column>
                <el-table-column
                        label="押金"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.per_deposit }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="佣金"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.brokerage }}%</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="收费单价"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.per_price }} / <span
                                v-if="scope.row.per_billingunit == 1">小时</span> <span
                                v-if="scope.row.per_billingunit == 2">分钟</span>  </span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="每日最高消费"
                        width="120"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.per_ceiling }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="人均消费"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.averageprice }}</span>
                    </template>
                </el-table-column>

                <!--                <el-table-column-->
                <!--                        width="120px"-->
                <!--                        label="营业时间"-->
                <!--                >-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <span>{{ scope.row.shopstart }} - {{ scope.row.shopend }} </span>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->


                <el-table-column
                        width="180px"
                        label="入住时间"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span>{{ scope.row.create_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="250">
                    <template slot-scope="scope">
                        <el-button
                                v-if="permissions['api/op.merchants'] && permissions['api/op.merchants']['PUT']"
                                size="mini"
                                type="primary"
                                @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-button>

                        <el-button size="mini" type="info"
                                   v-if="permissions['api/op.charecabinet'] && permissions['api/op.charecabinet']['GET']"
                                   @click="dialogVisible2 = true; queryMerchantsId = scope.row.id;">设备
                        </el-button>

                        <el-popconfirm
                                v-if="permissions['api/op.merchants'] && permissions['api/op.merchants']['DELETE']"
                                title="确定要删除吗？"
                                @confirm="handleDelete(scope.$index, scope.row)"
                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="danger"
                                       slot="reference">删除
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import Vue from 'vue';
    import BaiduMap from 'vue-baidu-map';
    import Chargingtank from '../equipment/Chargingtank'

    let Base64 = require('js-base64').Base64
    Vue.use(BaiduMap, {
        ak: '15dMfakXjqgiSuOzho5qtskY1djXEkUP'
    })

    var validateProvince = (rule, value, callback) => {
        if (value == '0') {
            callback(new Error('请选择省份'));
        }
        callback();
    };

    var validateCity = (rule, value, callback) => {
        if (value == '0') {
            callback(new Error('请选择省份'));
        }
        callback();
    };

    var validateAtent = (rule, value, callback) => {
        if (value == '0') {
            callback(new Error('请选择代理商'));
        }
        callback();
    };

    export default {
        name: "Merchants",
        data() {
            return {
                BMap: '',
                mapLoading: false,
                uploadHeader: {
                    token: ''
                },
                form: {
                    name,
                    contacts: '',
                    phone: '',
                    mobile: '',
                    password: '',
                    shopstart: '',
                    shopend: '',
                    averageprice: '',
                    ad_message: '',
                    status: true,
                    agency: 0,
                    salesman: 0,
                    per_billingunit: "1",
                    brokerage: 20,
                    per_deposit: 450.00,
                    per_freetime: 5,
                    per_ceiling: 180.00,
                    price: 15.00,
                    province: '0',
                    city: '0',
                    addr: '',
                    longitude: '',
                    latitude: '',
                    picture: [],
                    background_image: [],
                },
                validateRulesObject: {},
                validateRules: {
                    add: {
                        name: [
                            {required: true, message: '请输入商家名称', trigger: 'blur'},
                        ],
                        contacts: [
                            {required: true, message: '请输入联系人名称', trigger: 'blur'},
                        ],
                        phone: [
                            {required: true, message: '请输入店铺电话', trigger: 'blur'},
                        ],
                        mobile: [
                            {required: true, message: '请输入商家负责人手机号', trigger: 'blur'},
                        ],
                        password: [
                            {required: true, message: '请输入登录密码', trigger: 'blur'},
                            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                        ],
                        shopstart: [
                            {required: true, message: '请选择开店时间', trigger: 'blur'},
                        ],
                        shopend: [
                            {required: true, message: '请选择关店时间', trigger: 'blur'},
                        ],
                        averageprice: [
                            {required: true, message: '请输入人均消费', trigger: 'blur'},
                        ],
                        agency: [
                            {validator: validateAtent, trigger: 'blur'},
                            {required: true, message: '请选择代理', trigger: 'blur'},
                        ],
                        per_billingunit: [
                            {required: true, message: '请选择计费模式', trigger: 'blur'},
                        ],
                        brokerage: [
                            {required: true, message: '请输入佣金', trigger: 'blur'},
                        ],
                        per_deposit: [
                            {required: true, message: '请输入押金', trigger: 'blur'},
                        ],
                        per_freetime: [
                            {required: true, message: '请输入免费时间', trigger: 'blur'},
                        ],
                        per_ceiling: [
                            {required: true, message: '请输入每日封顶', trigger: 'blur'},
                        ],
                        price: [
                            {required: true, message: '请输入收费单价', trigger: 'blur'},
                        ],
                        province: [
                            {validator: validateProvince, trigger: 'blur'},
                            {required: true, message: '请选择省份', trigger: 'blur'},
                        ],
                        city: [
                            {validator: validateCity, trigger: 'blur'},
                            {required: true, message: '请选择城市', trigger: 'blur'},
                        ],
                        addr: [
                            {required: true, message: '请输入详细地址', trigger: 'blur'},
                        ],
                        longitude: [
                            {required: true, message: '请输入经度', trigger: 'blur'},
                        ],
                        latitude: [
                            {required: true, message: '请输入纬度', trigger: 'blur'},
                        ],
                        picture: [
                            {required: true, message: '请上传店铺图', trigger: 'blur'},
                        ],
                        background_image: [
                            {required: true, message: '请上传发现页背景图', trigger: 'blur'},
                        ],
                    },
                    edit: {
                        name: [
                            {required: true, message: '请输入商家名称', trigger: 'blur'},
                        ],
                        contacts: [
                            {required: true, message: '请输入联系人名称', trigger: 'blur'},
                        ],
                        phone: [
                            {required: true, message: '请输入店铺电话', trigger: 'blur'},
                        ],
                        mobile: [
                            {required: true, message: '请输入商家负责人手机号', trigger: 'blur'},
                        ],
                        password: [
                            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                        ],
                        shopstart: [
                            {required: true, message: '请选择开店时间', trigger: 'blur'},
                        ],
                        shopend: [
                            {required: true, message: '请选择关店时间', trigger: 'blur'},
                        ],
                        averageprice: [
                            {required: true, message: '请输入人均消费', trigger: 'blur'},
                        ],
                        agency: [
                            {validator: validateAtent, trigger: 'blur'},
                            {required: true, message: '请选择代理', trigger: 'blur'},
                        ],
                        per_billingunit: [
                            {required: true, message: '请选择计费模式', trigger: 'blur'},
                        ],
                        brokerage: [
                            {required: true, message: '请输入佣金', trigger: 'blur'},
                        ],
                        per_deposit: [
                            {required: true, message: '请输入押金', trigger: 'blur'},
                        ],
                        per_freetime: [
                            {required: true, message: '请输入免费时间', trigger: 'blur'},
                        ],
                        per_ceiling: [
                            {required: true, message: '请输入每日封顶', trigger: 'blur'},
                        ],
                        price: [
                            {required: true, message: '请输入收费单价', trigger: 'blur'},
                        ],
                        province: [
                            {validator: validateProvince, trigger: 'blur'},
                            {required: true, message: '请选择省份', trigger: 'blur'},
                        ],
                        city: [
                            {validator: validateCity, trigger: 'blur'},
                            {required: true, message: '请选择城市', trigger: 'blur'},
                        ],
                        addr: [
                            {required: true, message: '请输入详细地址', trigger: 'blur'},
                        ],
                        longitude: [
                            {required: true, message: '请输入经度', trigger: 'blur'},
                        ],
                        latitude: [
                            {required: true, message: '请输入纬度', trigger: 'blur'},
                        ],
                        picture: [
                            {required: true, message: '请上传店铺图', trigger: 'blur'},
                        ],
                        background_image: [
                            {required: true, message: '请上传发现页背景图', trigger: 'blur'},
                        ],
                    },
                },
                dialogTitle: '',
                dialogVisible: false,
                dialogVisible2: false,
                dialogAction: 'add',
                edit_id: 0,
                queryMerchantsId: 0,
                salesmanFormList: [],
                addressList: [], //所有的地址列表
                provinceList: [],
                cityList: [],
                agentSelectedId: 0,
                salesmanSelectedId: 0,
                searchMerchantsName: '',
                searchMerchantsPhone: '',
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
                allAgentList: [],
                agentList: [],
                salesmanList: [],
                center: {lng: 113.333067, lat: 23.163023},
                zoom: 19,
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        }
        ,
        watch: {
            addressList: function (value) {

                const _this = this;
                _this.provinceList = [];
                _this.cityList = [];

                value.forEach(function (e) {

                    if (e.addr_pid == 0) {
                        _this.provinceList.push(e);
                    }

                });

            }
        }
        ,
        mounted: function () {
            this.setUploadHeader();
            this.getAgentData();
            this.getAddressData();
            this.getListData();
        }
        ,
        methods: {
            // 设置上传组件header
            setUploadHeader() {
                let user = JSON.parse(localStorage.getItem('user'));
                this.uploadHeader.token = user != undefined ? user.token : '';
            }
            ,
            // 处理图片上传 - 发现页图
            handeUploadFile1(response, file, list) {
                const _this = this;
                if (response.status != undefined && response.status == 1) {
                    _this.form.background_image.push({name: file.name, url: response.data.url});
                } else {
                    let message = response.message == undefined ? '上传失败' : response.message;
                    _this.$message.error(message);
                    list.splice(list.length - 1, 1);
                }
            }
            ,
            // 处理图片移除 - 发现页图
            handleRemoveFile1(file) {
                const _this = this;
                _this.form.background_image.forEach(function (e, i) {
                    if (e.url == file.url) {
                        _this.form.background_image.splice(i, 1);
                    }
                })
            }
            ,
            // 处理图片上传 - 店铺图
            handeUploadFile2(response, file, list) {
                const _this = this;
                if (response.status != undefined && response.status == 1) {
                    _this.form.picture.push({name: file.name, url: response.data.url});
                } else {
                    let message = response.message == undefined ? '上传失败' : response.message;
                    _this.$message.error(message);
                    list.splice(list.length - 1, 1);
                }
            }
            ,
            // 处理图片移除 - 店铺图
            handleRemoveFile2(file) {
                const _this = this;
                _this.form.picture.forEach(function (e, i) {
                    if (e.url == file.url) {
                        _this.form.picture.splice(i, 1);
                    }
                })
            }
            ,
            // 处理商家表单提交
            handleSubmit() {

                const _this = this;

                _this.$refs['form'].validate((valid) => {
                    if (valid) {


                        if (_this.dialogAction == 'add') {

                            const loading = this.$loading({
                                lock: true,
                                text: 'Loading',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)'
                            });

                            _this.axios.post('/api/op/merchants', _this.form).then(function (response) {

                                loading.close();

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {

                                    _this.$notify({
                                        title: '成功',
                                        message: message,
                                        type: 'success'
                                    });
                                    _this.dialogVisible = false;
                                    _this.getListData();
                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                loading.close();
                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })


                        } else if (_this.dialogAction == 'edit') {

                            const loading = this.$loading({
                                lock: true,
                                text: 'Loading',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)'
                            });

                            _this.axios.put('/api/op/merchants/' + _this.edit_id, _this.form).then(function (response) {

                                loading.close();

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {

                                    _this.$notify({
                                        title: '成功',
                                        message: message,
                                        type: 'success'
                                    });
                                    _this.dialogVisible = false;
                                    _this.getListData();
                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                loading.close();
                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })

                        } else {

                            _this.dialogVisible = false;

                            _this.$notify.error({
                                title: '错误',
                                message: '操作异常,请刷新页面重试'
                            });

                        }

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: '请按照要求填写信息'
                        });
                    }
                });


            }
            ,
            // 处理地图初始化
            handlerMapInit({BMap, map}) {
                console.log(map);
                this.BMap = BMap;
                // map.centerAndZoom(point, 13)
                // var marker = new BMap.Marker(point) // 创建标注
                // map.addOverlay(marker) // 将标注添加到地图中
                // var circle = new BMap.Circle(point, 6, {
                //     strokeColor: 'Red',
                //     strokeWeight: 6,
                //     strokeOpacity: 1,
                //     Color: 'Red',
                //     fillColor: '#f03'
                // })
                // map.addOverlay(circle)
            }
            ,
            // 点击地图上的坐标，把坐标保存
            getClickInfo(e) {
                this.form.longitude = e.point.lng;
                this.form.latitude = e.point.lat;
                // this.center.lng = e.point.lng
                // this.center.lat = e.point.lat

                // console.log(this.BMap);
                // var gc = new this.BMap.Geocoder();
                // var new_point = new this.BMap.Point(e.point.lng, e.point.lat);
                //
                // gc.getLocation(new_point, function (rs) {
                //     var addComp = rs.addressComponents;
                //     //获取详细的地址，精确到街道的名称
                //     // var  addrname = addComp.city + addComp.district + addComp.street + addComp.streetNumber;
                //
                //     console.log(addComp);
                //     // console.log(addrname);
                //
                // })
                //
                // console.log(this.gc)
                // // e.addressComponents;
                // console.log(e);

            }
            ,
            //加载代理商数据
            getAgentData() {

                const _this = this;

                _this.axios.get('api/op/agent', {
                    params: {},
                    headers: {'page': 1, "page-size": 9999999},
                }).then(function (response) {
                        if (response.status == 200 && response.data.status != undefined && response.data.status == 1) {
                            _this.allAgentList = response.data.data.list;
                            response.data.data.list.forEach(function (e) {
                                if (e.type == 1) {
                                    _this.agentList.push(e);
                                }
                            })
                        }
                    }
                ).catch(function () {

                });

            }
            ,
            //加载地址数据
            getAddressData() {
                const _this = this;
                _this.axios.get('api/op/address', {
                    params: {},
                    headers: {'page': 1, "page-size": 999999},
                }).then(function (response) {

                    if (response.status == 200 && response.data.status != undefined && response.data.status == 1) {
                        _this.addressList = response.data.data.list;
                    }

                }).catch(function () {


                });
            }
            ,
            //加载列表数据
            getListData(page) {


                const _this = this;
                _this.tabLoading = true;
                if (page != undefined) {
                    _this.currentPage = page;
                }


                _this.axios.get('api/op/merchants', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'agent': _this.agentSelectedId,
                        'salesman': _this.salesmanSelectedId,
                        'name':  Base64.toBase64(_this.searchMerchantsName),
                        'mobile': _this.searchMerchantsPhone,
                    },
                }).then(function (response) {

                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {

                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            }
            ,
            //处理选择代理商后，加载该代理商的员工账号 Form表单
            handleFormAgentSelected(id) {
                const _this = this;
                _this.form.salesman = 0;
                _this.allAgentList.forEach(function (e) {
                    if (e.id == id) {
                        _this.salesmanFormList = e.salesman;
                        return true;
                    }
                })
            }
            ,
            //处理选择代理商后，加载该代理商的员工账号
            handleAgentSelected(id) {
                const _this = this;
                _this.salesmanList = [];
                _this.salesmanSelectedId = 0;
                this.allAgentList.forEach(function (e) {
                    if (e.id == id) {
                        _this.salesmanList = e.salesman;
                        return true;
                    }
                })
            }
            ,
            //处理选择代理商后，加载该代理商的员工账号 Form表单
            handleFormProvinceSelected(id) {
                const _this = this;
                _this.form.city = "0";
                _this.cityList = [];
                _this.addressList.forEach(function (e) {
                    if (e.addr_pid == id) {
                        _this.cityList.push(e);
                        return true;
                    }
                })

            }
            ,
            // 打开表单弹层
            openDialog(index, row) {
                const _this = this;
                _this.dialogVisible = true;
                _this.dialogTitle = '添加商家';
                _this.dialogAction = 'add';
                _this.validateRulesObject = _this.validateRules.add;
                _this.salesmanFormList = [];
                _this.handleDialogClose();

                setTimeout(function () {
                    if (_this.addressList.length == 0) {
                        _this.getAddressData();
                    }
                    if (index != null && row != null) {
                        _this.dialogAction = 'edit';
                        _this.dialogTitle = '编辑商家';
                        _this.edit_id = row.id;
                        _this.validateRulesObject = _this.validateRules.edit;

                        for (let key in row) {

                            if (_this.form[key] != undefined) {

                                if (key == 'password') {
                                    //密码跳过
                                } else if (key == 'background_image' || key == 'picture') {
                                    row[key].forEach(function (e) {
                                        if (e == '') {
                                            return false;
                                        }
                                        _this.form[key].push({
                                            name: e,
                                            url: e,
                                        })
                                    })
                                } else if (key == 'latitude' || key == 'longitude') {
                                    _this.mapLoading = true;
                                    _this.form[key] = row[key];
                                    setTimeout(function () {
                                        if (key == 'latitude') {
                                            _this.center.lat = row['latitude'];
                                        }
                                        if (key == 'longitude') {
                                            _this.center.lng = row['longitude'];
                                        }
                                        _this.mapLoading = false;
                                    }, 1000)
                                } else if (key == 'province') {

                                    _this.form[key] = parseInt(row[key]);

                                } else if (key == 'city') {


                                    let cityInfo = null;

                                    _this.addressList.forEach(function (e) {

                                        if (e.addr_id == row[key]) {
                                            cityInfo = e;
                                        }

                                    });

                                    if (cityInfo == null) {
                                        row[key] = "0";
                                    } else {

                                        let cityList = [];

                                        _this.addressList.forEach(function (e) {

                                            if (e.addr_pid == cityInfo.addr_pid) {
                                                cityList.push(e);
                                            }

                                        });

                                        _this.cityList = cityList;
                                        _this.form.city = parseInt(cityInfo.addr_id)

                                    }

                                    _this.form[key] = parseInt(row[key]);

                                } else if (key == 'salesman') {

                                    let salesmanList = [];
                                    let agent = null;


                                    _this.allAgentList.forEach(function (e) {

                                        if (e.id == row.agency) {
                                            agent = e;
                                        }

                                    });


                                    agent.salesman.forEach(function (e) {
                                        salesmanList.push(e);
                                    })

                                    _this.salesmanFormList = salesmanList;

                                    _this.form[key] = parseInt(row[key]);


                                } else if (key == 'shopstart' || key == 'shopend') {

                                    setTimeout(function () {
                                        _this.form[key] = row[key];
                                    }, 100);

                                } else if (key == 'status') {

                                    if (row[key] == 1) {
                                        _this.form[key] = true;
                                    } else {
                                        _this.form[key] = false;
                                    }

                                } else {

                                    _this.form[key] = row[key];
                                }
                            }

                        }

                    }
                }, 0)

            }
            ,
            // 处理表单弹层关闭
            handleDialogClose() {
                const _this = this;
                _this.form.city = "0";
                if (_this.$refs['form'] != undefined) {
                    _this.$refs['form'].resetFields();
                    _this.form.picture = [];
                    _this.form.background_image = [];
                }
                if (_this.$refs['uploadFile1'] != undefined) {
                    _this.$refs['uploadFile1'].clearFiles();
                    _this.$refs['uploadFile2'].clearFiles();
                }
            }
            ,
            // 打开表单弹层
            handleEdit(index, row) {

                const _this = this;
                _this.openDialog(index, row);


            }
            ,
            // 删除商家
            handleDelete(index, row) {

                let _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/merchants/' + row.id).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.getListData();

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();

                    const message = error.response.data.message == undefined ? "操作失败,请重试" : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: message
                    });

                });

            }
            ,
            // 搜索
            handleSearch() {

                const _this = this;

                _this.getListData();


            }
            ,

        }
        ,
        components: {
            Chargingtank
        }
    }
</script>

<style scoped>

    .box /deep/ .el-dialog {
        margin: 5vh auto !important;
    }

    .box /deep/ .el-dialog__body {
        height: 60vh;
        overflow: auto;
    }

</style>