<template>
    <div>


        <div style="margin-bottom: 10px;">
            <template>


                <el-date-picker style="margin-left: 10px;"
                                v-model="search.time"
                                :picker-options="pickerOptions"
                                value-format="timestamp"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right">
                </el-date-picker>

            </template>


            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch">搜索
            </el-button>

        </div>


        <template>
            <el-table
                    show-summary

                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    :summary-method="getSummaries"
                    style="width: 100%">

                <el-table-column
                        label="日期"
                        >
                    <template slot-scope="scope">
                        <span>{{scope.row.date_time}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="新增代理商"
                        prop="agent_total_count"
                       >
                    <template slot-scope="scope">
                        <span>{{scope.row.agent_total_count ? scope.row.agent_total_count : 0}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="新增商家"
                        prop="merchants_total_count"
                        >
                    <template slot-scope="scope">
                        <span>{{scope.row.merchants_total_count ? scope.row.merchants_total_count : 0}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="新增用户"
                        prop="user_total_count"
                       >
                    <template slot-scope="scope">
                        <span>{{scope.row.user_total_count ? scope.row.user_total_count : 0}}</span>
                    </template>
                </el-table-column>

            </el-table>
        </template>


    </div>
</template>

<script>

    import Vue from 'vue'

    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2, '0')

        //小时
        const hh = (dt.getHours() + '').padStart(2, '0')
        //分钟
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        //秒数
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })


    export default {
        name: "UserStatistical",
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '7天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '30天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '90天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '180天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '3650天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3650);
                            picker.$emit('pick', [start, end]);
                        },
                    }]
                },
                search: {
                    userType: 0,
                    time: null,
                },
                tableTotal: 0,
                tabLoading: true,
                tableData:[],
            }
        },
        mounted: function () {
            this.getListData();
        },
        created(){
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3650);
            const array = [];
            array.push(start)
            array.push(end)
            this.search.time = array
        },
        methods: {
            getSummaries(param){
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '总计';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[index] = sums[index].toFixed(0);

                        sums[index] += '个';
                    } else {
                        sums[index] = '-';
                    }
                });

                return sums;
            },
            getListData() {

                const _this = this;
                _this.tabLoading = true;
                _this.axios.get('api/op/userStatistics', {
                    params: {},
                    headers: {
                        'start-time': _this.search.time != null ? _this.search.time[0] / 1000 : '',
                        'end-time': _this.search.time != null ? _this.search.time[1] / 1000 : '',
                    },
                }).then(function (response) {
                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;
                });
            },
            handleSearch() {
                const _this = this;
                _this.getListData();
            },

        }
    }
</script>

<style scoped>


    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a1a3a9;
        border-radius: 3px;
    }

</style>