<template>
  <div>


    <div style="margin-bottom: 10px;">
      <template>

        <el-select v-model="search.languageType" placeholder="请选择语言类型">
          <el-option
              v-for="item in languageTypeList"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>

        <el-input style="margin-left:10px;width: 150px;"
                  placeholder="请输入关键字"
                  v-model="search.keyWord"
                  clearable>
        </el-input>

        <el-date-picker style="margin-left: 10px;"
                        v-model="search.time"
                        :picker-options="pickerOptions"
                        value-format="timestamp"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
        </el-date-picker>

      </template>


      <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch">搜索
      </el-button>

      <el-button  v-if="permissions['api/op.helpArticles'] && permissions['api/op.helpArticles']['POST']" style="margin-left:10px;" type="success" icon="el-icon-add-location" @click="openDialog2">
        新增
      </el-button>

    </div>

    <el-dialog
        top="1vh"
        :title="details.question"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="handleDialogClose"
        :visible.sync="dialogVisible"
        width="35%"
    >

      <div v-html="details.answer"></div>


      <span slot="footer" class="dialog-footer">
                  <el-button style="margin-left: 10px" size="medium" type=""
                             @click="dialogVisible = false">关闭</el-button>

        </span>

    </el-dialog>

    <el-dialog
        top="1vh"
        title="编辑文章"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="handleDialogClose"
        :visible.sync="dialogVisible2"
        width="60%"
    >

      <template>

        <div>
          <el-form ref="form" :rules="vaildateRules" :model="form" label-width="80px">
            <el-form-item label="标题" prop="question">
              <el-input v-model="form.question"></el-input>
            </el-form-item>
            <el-form-item label="语言" prop="language">
              <el-select v-model="form.language" placeholder="请选择语言类型">
                <el-option
                    v-for="item in languageTypeList"
                    v-show="item.type!= ''"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="内容" prop="answer">
              <Editor
                  id="tinymceId"
                  :init="tinymceConfig"
                  v-model="form.answer"
              ></Editor>
            </el-form-item>
          </el-form>
        </div>

      </template>


      <span slot="footer" class="dialog-footer">

                <el-button
                    size="medium"
                    type="primary"
                    @click="submit"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialogVisible2 = false">关闭</el-button>

            </span>

    </el-dialog>

    <template>
      <el-table
          size="small"
          v-loading="tabLoading"
          :data="tableData"
          style="width: 100%">

        <el-table-column
            label="标题"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.question }}</span>
          </template>
        </el-table-column>

        <el-table-column
            label="语言"
        >
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <span v-if="scope.row.language == 'zh-cn'">中文</span>
              <span v-else-if="scope.row.language == 'en-us'">英文</span>
              <span v-else-if="scope.row.language == 'th-th'">泰文</span>
              <span v-else>未知</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            label="创建时间"
            width="200"
        >
          <template slot-scope="scope">
            <i class="el-icon-timer"/>
            <span>{{ scope.row.create_time }}</span>
          </template>
        </el-table-column>


        <el-table-column label="" width="350">
          <template slot-scope="scope">

            <el-button
                size="mini"
                @click="openDialog(scope.$index, scope.row)"><i class="el-icon-s-order"
                                                                style="margin-right: 5px;"/>查看文章
            </el-button>

            <el-button
                v-if="permissions['api/op.helpArticles'] && permissions['api/op.helpArticles']['PUT']"
                size="mini"
                type="primary"
                @click="openDialog2(scope.$index, scope.row)"><i class="el-icon-info"
                                                                 style="margin-right: 5px;"/>编辑
            </el-button>

            <el-popconfirm
                title="确定要删除吗？"
                @confirm="deleteCooperation(scope.$index, scope.row)"

            >
              <el-button style="margin-left: 10px" size="mini"
                         v-if="permissions['api/op.helpArticles'] && permissions['api/op.helpArticles']['DELETE']"
                         type="danger"
                         slot="reference"><i style="margin-right: 5px;" class="el-icon-error"/>删除
              </el-button>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
    </template>

    <div style="margin-top: 10px;">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getListData"
          :page-size="pageSize"
          :total="tableTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>

let Base64 = require('js-base64').Base64
import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue'//编辑器引入
import 'tinymce/themes/silver/theme'//编辑器主题
import 'tinymce/plugins/link'  //超链接
import 'tinymce/plugins/paste'  //粘贴过滤
import 'tinymce/plugins/preview'  //预览
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/textcolor'

export default {
  name: "Helparticles",
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '7天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '30天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '90天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '180天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '365天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '3650天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3650);
            picker.$emit('pick', [start, end]);
          },
        }]
      },
      search: {
        status: 0,
        languageType: '',
        time: null,
        keyWord: '',
      },
      languageTypeList: [
        {name: '全部', type: ''},
        {name: '中文', type: 'zh-cn'},
        {name: '英文', type: 'en-us'},
        {name: '泰文', type: 'th-th'},
      ],
      details: {
        answer: '',
        question: {},
      },
      form: {
        question: '',
        answer: '',
        language: ''
      },
      vaildateRules: {
        question: [
          {required: true, message: '请填写标题', trigger: 'blur'},
        ],
        answer: [
          {required: true, message: '请填写内容', trigger: 'blur'},
        ],
        language: [
          {required: true, message: '请选择语言', trigger: 'blur'},
        ],
      },
      tinymceConfig: {
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        height: 300,
        skin_url: '/tinymce/skins/ui/oxide',
        plugins: 'link lists image code table textcolor wordcount contextmenu',
        toolbar: 'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat',
        branding: false,
        menubar: false,
        /* images_upload_handler: (blobInfo, success, failure) => {
          const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          success(img)
        } */
      },
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible2Action:'Add',
      pageSize: 20,
      currentPage: 1,
      tableTotal: 0,
      tabLoading: false,
      tableData: [],
    }
  },
  computed: {
    permissions() {
      return this.$store.state.user.allPermissions;
    }
  },
  mounted: function () {
    tinymce.init({})
    this.getListData();

  },
  methods: {
    // 获取文章列表
    getListData(page) {

      const _this = this;
      _this.tabLoading = true;
      if (page != undefined) {
        _this.currentPage = page;
      }

      _this.axios.get('api/op/helparticles', {
        params: {},
        headers: {
          'page': _this.currentPage,
          "page-size": _this.pageSize,
          'language-type': _this.search.languageType,
          'key-word': Base64.toBase64(_this.search.keyWord),
          'start-time': _this.search.time != null ? _this.search.time[0] / 1000 : '',
          'end-time': _this.search.time != null ? _this.search.time[1] / 1000 : '',
        },
      }).then(function (response) {


            if (response.status != 200 || response.data.status == undefined) {
              _this.$message.error('加载数据失败，正在重试');
            } else if (response.data.status == 0) {
              _this.$message.error(response.data.message);
            } else {
              _this.tableData = [];
              _this.tableTotal = response.data.data.total;
              _this.tableData = response.data.data.list;

            }
            _this.tabLoading = false;
          }
      ).catch(function (error) {
        console.log(error);
        if (error.response.status != 200 || error.response.data.status == undefined) {
          _this.$message.error('加载数据失败，正在重试');
        } else if (error.response.data.status == 0) {
          _this.$message.error(error.response.data.message);
        }

        _this.tabLoading = false;

      });
    },
    // 打开文章预览弹层
    openDialog(index, row) {
      const _this = this;
      _this.dialogVisible = true;
      _this.details = JSON.parse(JSON.stringify(row));
    },
    // 打开文章编辑弹层
    openDialog2(index, row) {
      const _this = this;
      if (_this.$refs['form'] != undefined) {
        _this.$refs['form'].resetFields();
      }
      _this.dialogVisible2 = true;
      if (index != undefined && row != undefined){
        _this.form = row
        _this.dialogVisible2Action = 'edit'
      }else{
        _this.form = {
          question: '',
          answer: '',
          language: 'zh-cn'
        }
        _this.dialogVisible2Action = 'add'
      }
    },
    // 表单提交
    submit() {

      const _this = this;

      this.$refs['form'].validate((valid) => {
        if (valid) {

          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          if (_this.dialogVisible2Action == 'edit'){
            _this.axios.put('/api/op/helparticles/' + _this.form.id, _this.form).then(function (response) {

              loading.close();

              const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

              if (response.data.status != undefined && response.data.status == 1) {

                _this.$notify({
                  title: '成功',
                  message: message,
                  type: 'success'
                });
                _this.dialogVisible2 = false;
                _this.getListData();
              } else {
                _this.$notify.error({
                  title: '错误',
                  message: message
                });
              }

            }).catch(function (error) {

              loading.close();
              let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

              _this.$notify.error({
                title: '错误',
                message: msg
              });

            })
          }else {

            _this.axios.post('/api/op/helparticles', _this.form).then(function (response) {

              loading.close();

              const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

              if (response.data.status != undefined && response.data.status == 1) {

                _this.$notify({
                  title: '成功',
                  message: message,
                  type: 'success'
                });
                _this.dialogVisible2 = false;
                _this.getListData();
              } else {
                _this.$notify.error({
                  title: '错误',
                  message: message
                });
              }

            }).catch(function (error) {

              loading.close();
              let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

              _this.$notify.error({
                title: '错误',
                message: msg
              });

            })

          }

        } else {
          _this.$notify.error({
            title: '错误',
            message: '请按照要求填写信息'
          });
        }
      });

    },
    // 确认处理
    confirmHandle(index, row) {

      const _this = this;

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      let rquestOption = {
        params: {},
        headers: {
          action: 'handle'
        }
      };

      _this.axios.put('/api/op/helparticles/' + row.id, '', rquestOption).then(function (response) {

        loading.close();

        const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

        if (response.data.status != undefined && response.data.status == 1) {

          _this.$notify({
            title: '成功',
            message: message,
            type: 'success'
          });
          _this.dialogVisible = false;
          _this.getListData();
        } else {
          _this.$notify.error({
            title: '错误',
            message: message
          });
        }

      }).catch(function (error) {

        console.log(error)

        loading.close();
        let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

        _this.$notify.error({
          title: '错误',
          message: msg
        });

      })
      console.log(index);

    },
    // 删除
    deleteCooperation(index, row) {

      const _this = this;

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      _this.axios.delete('/api/op/helparticles/' + row.id, '').then(function (response) {

        loading.close();

        const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

        if (response.data.status != undefined && response.data.status == 1) {

          _this.$notify({
            title: '成功',
            message: message,
            type: 'success'
          });
          _this.dialogVisible = false;
          _this.getListData();
        } else {
          _this.$notify.error({
            title: '错误',
            message: message
          });
        }

      }).catch(function (error) {

        console.log(error)

        loading.close();
        let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

        _this.$notify.error({
          title: '错误',
          message: msg
        });

      })
      console.log(index);

    },
    // 搜索
    handleSearch() {
      const _this = this;
      _this.getListData();
    },

  },
  components: {Editor}
}
</script>

<style scoped>

.html {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

</style>