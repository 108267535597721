<template>
    <div>


        <div style="margin-bottom: 15px;">
            <template>
                <el-form>
                    <el-input style="width:250px;margin-left:10px;"
                              placeholder="请输入代理商名称"
                              v-model="searchAgentName"
                              clearable>
                    </el-input>
                    <el-input style="margin-left:10px;width: 250px;"
                              placeholder="请输入手机号"
                              v-model="searchAgentPhone"
                              clearable>
                    </el-input>
                    <el-button style="margin-left:10px;" type="primary" @click="handleSearch()" icon="el-icon-search">
                        搜索
                    </el-button>
                    <el-button v-if="permissions['api/op.agent'] && permissions['api/op.agent']['POST']" style="margin-left:10px;" type="success" icon="el-icon-add-location" @click="openDialog">
                        新增
                    </el-button>
                </el-form>
            </template>


        </div>

        <el-dialog
                top="1vh"
                :title="dialogTitle"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="handleDialogClose"
                :visible.sync="dialogVisible"
                width="50%"
        >

            <el-form ref="form" :rules="validateRulesObject" :validate-on-rule-change="false" :model="form"
                     label-width="130px"
                     style="padding-right: 20px;">

                <template>

                    <el-tabs tab-position="left">

                        <el-tab-pane label="基本设置">

                            <el-form-item label="代理商名称" prop="agent_name">
                                <el-input show-word-limit maxlength="50" v-model="form.agent_name"></el-input>
                            </el-form-item>

                            <el-form-item label="手机号" prop="phone">
                                <el-input show-word-limit maxlength="20" v-model="form.phone"></el-input>
                            </el-form-item>

                            <el-form-item label="密码" prop="password">
                                <el-input maxlength="16" type="password" v-model="form.password"></el-input>
                            </el-form-item>

                            <el-form-item label="真实姓名" prop="real_name">
                                <el-input show-word-limit maxlength="30" v-model="form.real_name"></el-input>
                            </el-form-item>

                            <el-form-item label="佣金比例" prop="rate">

                                <el-slider
                                        v-model="form.rate"
                                >
                                </el-slider>

                            </el-form-item>

                            <el-form-item label="状态" prop="status">
                                <el-switch v-model="form.status"></el-switch>
                            </el-form-item>

                        </el-tab-pane>


                    </el-tabs>
                </template>

            </el-form>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit()">确 定</el-button>
            </span>
        </el-dialog>

        <template>
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        label="代理商名称"
                        width="400"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.agent_name }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="真实名字"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.real_name }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="手机号码"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.phone}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="佣金比例"

                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.rate }}%</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="状态"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.rate }}</span>
                        <span v-if="scope.row.status == 1">开启</span>
                        <span v-if="scope.row.status != 1">禁止</span>
                    </template>
                </el-table-column>


                <el-table-column

                        label="入住时间"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span>{{ scope.row.addtime}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="primary"
                                v-if="permissions['api/op.agent'] && permissions['api/op.agent']['PUT']"
                                @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-button>
                        <el-popconfirm
                                title="确定要删除吗？"
                                @confirm="handleDelete(scope.$index, scope.row)"
                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       v-if="permissions['api/op.agent'] && permissions['api/op.agent']['DELETE']"
                                       type="danger"
                                       slot="reference">删除
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    let Base64 = require('js-base64').Base64

    export default {
        name: "Agent",
        data() {
            return {
                form: {
                    agent_name: '',
                    phone: '',
                    password: '',
                    real_name: '',
                    rate: 50,
                    status: true,
                },
                validateRulesObject: {},
                validateRules: {
                    add: {
                        agent_name: [
                            {required: true, message: '请输入商家名称', trigger: 'blur'},
                        ],
                        phone: [
                            {required: true, message: '请输入手机号', trigger: 'blur'},
                        ],
                        password: [
                            {required: true, message: '请输入登录密码', trigger: 'blur'},
                            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                        ],
                        real_name: [
                            {required: true, message: '请输入真实名字', trigger: 'blur'},
                        ],
                        rate: [
                            {required: true, message: '请选择佣金比例', trigger: 'blur'},
                        ],
                    },
                    edit: {
                        agent_name: [
                            {required: true, message: '请输入商家名称', trigger: 'blur'},
                        ],
                        phone: [
                            {required: true, message: '请输入手机号', trigger: 'blur'},
                        ],
                        password: [
                            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                        ],
                        real_name: [
                            {required: true, message: '请输入真实名字', trigger: 'blur'},
                        ],
                        rate: [
                            {required: true, message: '请选择佣金比例', trigger: 'blur'},
                        ],
                    },
                },
                dialogTitle: '',
                dialogVisible: false,
                dialogAction: 'add',
                edit_id: 0,
                searchAgentName: '',
                searchAgentPhone: '',
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
                zoom: 19,
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted: function () {
            this.getListData();
        }
        ,
        methods: {
            // 处理商家表单提交
            handleSubmit() {

                const _this = this;

                _this.$refs['form'].validate((valid) => {
                    if (valid) {


                        if (_this.dialogAction == 'add') {

                            const loading = this.$loading({
                                lock: true,
                                text: 'Loading',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)'
                            });

                            _this.axios.post('/api/op/agent', _this.form).then(function (response) {

                                loading.close();

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {

                                    _this.$notify({
                                        title: '成功',
                                        message: message,
                                        type: 'success'
                                    });
                                    _this.dialogVisible = false;
                                    _this.getListData();
                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                loading.close();
                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })


                        } else if (_this.dialogAction == 'edit') {

                            const loading = this.$loading({
                                lock: true,
                                text: 'Loading',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)'
                            });

                            _this.axios.put('/api/op/agent/' + _this.edit_id, _this.form).then(function (response) {

                                loading.close();

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {

                                    _this.$notify({
                                        title: '成功',
                                        message: message,
                                        type: 'success'
                                    });
                                    _this.dialogVisible = false;
                                    _this.getListData();
                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                loading.close();
                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })

                        } else {

                            _this.dialogVisible = false;

                            _this.$notify.error({
                                title: '错误',
                                message: '操作异常,请刷新页面重试'
                            });

                        }

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: '请按照要求填写信息'
                        });
                    }
                });


            }
            ,
            // 处理地图初始化
            //加载列表数据
            getListData(page) {


                const _this = this;
                _this.tabLoading = true;
                _this.tableData = [];
                if (page != undefined) {
                    _this.currentPage = page;
                }

                // let user = JSON.parse(localStorage.getItem('user'));

                _this.axios.get('api/op/agent', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        // token: user.token,
                        'agent': _this.agentSelectedId,
                        'salesman': _this.salesmanSelectedId,
                        'name': Base64.toBase64(_this.searchAgentName),
                        'mobile': _this.searchAgentPhone,
                        'type': 1,
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            }
            ,
            // 打开表单弹层
            openDialog(index, row) {
                const _this = this;
                _this.dialogVisible = true;
                _this.dialogTitle = '添加代理商';
                _this.dialogAction = 'add';
                _this.validateRulesObject = _this.validateRules.add;
                _this.salesmanFormList = [];
                _this.handleDialogClose();

                setTimeout(function () {

                    if (index != null && row != null) {
                        _this.dialogAction = 'edit';
                        _this.dialogTitle = '编辑代理商';
                        _this.edit_id = row.id;
                        _this.validateRulesObject = _this.validateRules.edit;

                        for (let key in row) {

                            if (_this.form[key] != undefined) {

                                if (key == 'password') {
                                    //密码跳过
                                } else if (key == 'status') {

                                    if (row[key] == 1) {
                                        _this.form[key] = true;
                                    } else {
                                        _this.form[key] = false;
                                    }

                                } else {

                                    _this.form[key] = row[key];
                                }
                            }

                        }

                    }
                }, 0)

            }
            ,
            // 处理表单弹层关闭
            handleDialogClose() {
                const _this = this;
                if (_this.$refs['form'] != undefined) {
                    _this.$refs['form'].resetFields();
                }
            }
            ,
            // 打开表单弹层
            handleEdit(index, row) {

                const _this = this;
                _this.openDialog(index, row);


            }
            ,
            // 删除商家
            handleDelete(index, row) {

                let _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/agent/' + row.id).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.getListData();

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();

                    const message = error.response.data.message == undefined ? "操作失败,请重试" : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: message
                    });

                });

            }
            ,
            // 搜索
            handleSearch() {

                const _this = this;

                _this.getListData();


            }
            ,

        }
    }
</script>

<style scoped>

</style>