<template>
    <div>


        <div style="margin-bottom: 10px;">
            <template>

                <el-select v-model="search.userType" placeholder="请选择用户类型">
                    <el-option
                            v-for="item in userTypeList"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                    </el-option>
                </el-select>

                <el-date-picker style="margin-left: 10px;"
                                v-model="search.time"
                                :picker-options="pickerOptions"
                                value-format="timestamp"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right">
                </el-date-picker>

            </template>


            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch">搜索
            </el-button>

        </div>

        <el-dialog
                top="1vh"
                :title="dialogTitle"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="handleDialogClose"
                :visible.sync="dialogVisible"
                width="90%"
        >
            <template>
                <el-table
                        size="small"
                        v-loading="orderDetails.tabLoading"
                        :data="orderDetails.tableData"
                        style="width: 100%">

                    <el-table-column
                            label="订单ID"
                            width="250">
                        <template slot-scope="scope">
                            <span>{{scope.row.order_no}}</span>
                        </template>
                    </el-table-column>


                    <el-table-column
                            label="订单名称"
                            width="300">
                        <template slot-scope="scope">
                            <span>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="租借时间"
                            width="150">
                        <template slot-scope="scope">
                            <i class="el-icon-timer"/>
                            <span>{{scope.row.starttime | dateFormat}}</span>
                            <div></div>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="归还时间"
                            width="150">
                        <template slot-scope="scope">
                            <span>{{scope.row.endtime | dateFormat}}</span>
                            <div></div>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="订单总额"
                    >
                        <template slot-scope="scope">
                            <span>{{scope.row.real_amount}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="订单单价"
                    >
                        <template slot-scope="scope">
                            <span>{{scope.row.per_price}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="成本价"
                    >
                        <template slot-scope="scope">
                            <span>{{scope.row.cost_price}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1">租借中</span>
                            <span v-else-if="scope.row.status == 2">已完成</span>
                            <span v-else-if="scope.row.status == 3" style="color: red;">超时</span>
                            <span v-else-if="scope.row.status == 4" style="color: red;">异常</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="平台比例">
                        <template slot-scope="scope">
                            <span>{{scope.row.plat_rate}}%</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="代理商比例">
                        <template slot-scope="scope">
                            <span>{{scope.row.agent_rate}}%</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="商户比例">
                        <template slot-scope="scope">
                            <span>{{scope.row.seller_rate}}%</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="平台佣金">
                        <template slot-scope="scope">
                            <span>{{scope.row.plat_brokerage}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="代理商佣金">
                        <template slot-scope="scope">
                            <span>{{scope.row.agent_brokerage}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="商户佣金">
                        <template slot-scope="scope">
                            <span>{{scope.row.seller_brokerage}}</span>
                        </template>
                    </el-table-column>

                </el-table>
            </template>

            <div style="margin-top: 10px;">
                <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="getorderDetailsListData"
                        :page-size="orderDetails.pageSize"
                        :total="orderDetails.tableTotal">
                </el-pagination>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
            </span>

        </el-dialog>

        <template>
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="名称"
                        width="250">
                    <template slot-scope="scope">
                        <span>{{scope.row.name}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="完成订单数"
                        width="180">
                    <template slot-scope="scope">
                        <span>{{scope.row.totalOrder}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="超时订单数"
                        width="180">
                    <template slot-scope="scope">
                        <span>{{scope.row.totalOutTimeOrder}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="订单总额"
                        width="180">
                    <template slot-scope="scope">
                        <span>{{scope.row.totalAmount}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="佣金"
                        width="250">
                    <template slot-scope="scope">
                        <span>{{scope.row.totalBrokerage}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="已提现"
                        width="250">
                    <template slot-scope="scope">
                        <span>{{scope.row.withdrawal_amount}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="primary"
                                v-if="permissions['api/op.orders'] && permissions['api/op.orders']['GET']"
                                @click="openDialog(scope.$index, scope.row)">订单详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import Vue from 'vue'

    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2, '0')

        //小时
        const hh = (dt.getHours() + '').padStart(2, '0')
        //分钟
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        //秒数
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })


    export default {
        name: "Commission",
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '7天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '30天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '90天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '180天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '3650天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3650);
                            picker.$emit('pick', [start, end]);
                        },
                    }]
                },
                search: {
                    userType: 0,
                    time: null,
                },
                userTypeList: [
                    {name: '代理商', type: 0},
                    {name: '商户', type: 1},
                ],
                dialogTitle: '订单详情',
                dialogVisible: false,
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                orderDetails: {
                    userInfo: [],
                    pageSize: 10,
                    currentPage: 1,
                    tableTotal: 0,
                    tabLoading: false,
                    tableData: [],
                },
                tableData: [],
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted: function () {
            this.getListData();

        },
        methods: {
            // 获取佣金信息列表
            getListData(page) {


                const _this = this;
                _this.tabLoading = true;
                if (page != undefined) {
                    _this.currentPage = page;
                }


                _this.axios.get('api/op/commission', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'user-type': _this.search.userType,
                        'start-time': _this.search.time != null ? _this.search.time[0] / 1000 : '',
                        'end-time': _this.search.time != null ? _this.search.time[1] / 1000 : '',
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 获取订单详情列表
            getorderDetailsListData(page) {

                const _this = this;
                _this.orderDetails.tabLoading = true;
                if (page != undefined) {
                    _this.orderDetails.currentPage = page;
                }

                _this.axios.get('api/op/orders', {
                    params: {},
                    headers: {
                        'page': _this.orderDetails.currentPage,
                        "page-size": _this.orderDetails.pageSize,
                        "merchants-id": _this.search.userType == 1 ? _this.orderDetails.userInfo.id : 0,
                        "agent-id": _this.search.userType == 0 ? _this.orderDetails.userInfo.id : 0,
                        'start-time': _this.search.time != null ? _this.search.time[0] / 1000 : '',
                        'end-time': _this.search.time != null ? _this.search.time[1] / 1000 : '',
                    },
                }).then(function (response) {

                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.orderDetails.tableData = [];
                            _this.orderDetails.tableTotal = response.data.data.total;
                            _this.orderDetails.tableData = response.data.data.list;

                        }
                        _this.orderDetails.tabLoading = false;
                    }
                ).catch(function (error) {

                    console.log(error);

                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }
                    _this.orderDetails.tabLoading = false;

                });
            },
            // 打开订单详情弹层
            openDialog(index, row) {
                const _this = this;
                _this.dialogVisible = true;
                _this.orderDetails.userInfo = row;
                _this.orderDetails.currentPage = 1;
                _this.getorderDetailsListData();
            },
            // 搜索
            handleSearch() {
                const _this = this;
                _this.getListData();
            },

        }
    }
</script>

<style scoped>


    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a1a3a9;
        border-radius: 3px;
    }

</style>