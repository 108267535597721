<template>
  <div class="box">
    <el-header class="header" style="height:61px;padding-bottom: 1px;padding:0;">
      <div class="left">
        <i class="logo el-icon-cpu"></i><h4 style="    margin-top: 17px;">Hi Charge 充电宝管理系统</h4>
      </div>
      <div class="right">
        <el-menu class="el-menu-demo" mode="horizontal">
          <el-submenu index="2">
            <template slot="title"><i class="el-icon-user-solid"/>{{ user.details.account }}</template>
            <!--                        <el-menu-item index="2-1" >修改密码</el-menu-item>-->
            <el-menu-item index="2-2" @click="loginOut">退出登录</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div style="clear: both;"></div>


    </el-header>
    <el-container style="width: 100%;height: calc(100% - 61px)">
      <Menu ref="menu" style="max-width: 200px;" :permissions="user.allPermissions" @menuClick="openTabs"></Menu>
      <el-main class="main">

        <div style="background-color: #fff; padding-left:10px;height: 100%">

          <el-tabs @tab-click="tabsClick" v-model="editableTabsValue" closable
                   @tab-remove="removeTab">
            <el-tab-pane
                v-for="(item) in editableTabs"
                :key="item.name"
                :label="item.title"
                :name="item.name"
            >
              <span slot="label"><i class="el-icon-date"></i> {{ item.title }} <i style="position: relative"
                                                                                  class="el-icon-refresh"
                                                                                  @click="refresh(item)"></i></span>
            </el-tab-pane>
          </el-tabs>

          <el-scrollbar style="height:93%">
            <keep-alive :include="keepAlive.join(',')">
              <router-view v-if="show" :permissions="user.allPermissions" :key="key" style="padding:10px;">
              </router-view>
            </keep-alive>
          </el-scrollbar>


        </div>

      </el-main>
    </el-container>

  </div>
</template>

<script>

import Menu from '../../components/menu/Menu';
import Vue from 'vue';
import Statistical from '../../page/statistical/statistical';
import VueScroller from 'vue-scroller'

var routeMap = {
  '/home': {
    index: '1-1',
    title: '首页',
    componentsName: 'Global',
  },
  '/op-user': {
    index: '1-2',
    title: '员工管理',
    componentsName: 'UserOp',
  },
  '/user-group': {
    index: '1-3',
    title: '用户组管理',
    componentsName: 'UserGroup',
  },
  '/user': {
    index: '3-1',
    title: '用户列表',
    componentsName: 'User',
  },
  '/merchants': {
    index: '3-2',
    title: '商家列表',
    componentsName: 'Merchants',
  },
  '/agent': {
    index: '3-3',
    title: '代理商列表',
    componentsName: 'Agent',
  },
  '/salesman': {
    index: '3-4',
    title: '业务员列表',
    componentsName: 'Salesman',
  },
  '/rentOrder': {
    index: '3-5',
    title: '订单列表',
    componentsName: 'Order',
  },
  '/coupons': {
    index: '3-6',
    title: '优惠券',
    componentsName: 'Coupons',
  },
  '/advertising': {
    index: '3-7',
    title: '广告营销',
    componentsName: 'Marketing',
  },
  '/Helparticles': {
    index: '3-8',
    title: '常见问题',
    componentsName: 'Helparticles',
  },
  '/cooperation': {
    index: '3-9',
    title: '合作申请',
    componentsName: 'Cooperation',
  },
  '/problemReport': {
    index: '3-10',
    title: '投诉与建议',
    componentsName: 'ProblemReport',
  },
  '/commission': {
    index: '4-1',
    title: '佣金列表',
    componentsName: 'Commission',
  },
  '/topup': {
    index: '4-2',
    title: '充值列表',
    componentsName: 'Topup',
  },
  '/refund': {
    index: '4-3',
    title: '退款列表',
    componentsName: 'Refund',
  },
  '/withdrawal': {
    index: '4-4',
    title: '提现列表',
    componentsName: 'Withdrawal',
  },
  '/refundApp': {
    index: '4-5',
    title: '退款列表(APP)',
    componentsName: 'RefundApp',
  },
  '/chargingtank': {
    index: '5-1',
    title: '机柜列表',
    componentsName: 'Chargingtank',
  },
  '/power': {
    index: '5-3',
    title: '充电宝列表',
    componentsName: 'Power',
  },
  '/monitoring': {
    index: '5-2',
    title: '设备监控',
    componentsName: 'Monitoring',
  },
  '/instruction': {
    index: '5-4',
    title: '发送指令',
    componentsName: 'Instruction',
  },
  '/busines': {
    index: '6-1',
    title: '经营报表',
    componentsName: 'Busines',
  },
  '/userStatistics': {
    index: '6-2',
    title: '用户报表',
    componentsName: 'UserStatistical',
  },
}

Vue.use(VueScroller)

export default {
  name: "Home",
  data() {
    return {
      show: true,
      editableTabsValue: "1",
      editableTabs: [],
      tabIndex: 1,
      keepAlive: []
    }
  },
  beforeCreate() {

    if (this.$route.path != 'home') {

      const routePath = this.$route.path;

      this.$router.replace('/home');

      const _this = this;

      setTimeout(function () {
        _this.openTabs(routePath, routeMap[routePath].title);
        let index = routeMap[routePath];
        if (index != undefined) {
          index = index.index;
        }
        _this.$refs['menu'].openMenu(index)

      }, 500)

    }
  },
  mounted() {

    const user = JSON.parse(localStorage.getItem('user'));

    // 检查用户信息是否还存在，不存在跳去登录页

    if (user == undefined) {
      window.vm.$router.replace('/login');
      setTimeout(function () {
        window.vm.$notify.error({
          title: '错误',
          message: '登录已过期，请重新登录'
        });
      }, 100)
      return false;
    }

    let allPermissions = {};
    const userGrou = user.details.group;
    userGrou.forEach(function (group) {
      group.details.permissions.forEach(function (permissions) {

        if (allPermissions[permissions.details.router_path] == undefined) {

          let value = {
            GET: false,
            PUT: false,
            DELETE: false,
            UPDATE: false,
          }
          allPermissions[permissions.details.router_path] = value;
        }

        allPermissions[permissions.details.router_path][permissions.details.action] = true;

      });
    });

    let object = {
      details: user,
      allPermissions: allPermissions,
    }

    this.$store.commit('setUser', object);

  },
  components: {
    Menu,
    Statistical,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    key() {
      return this.$route.path
    }
  },
  methods: {
    //选项卡切换
    loginOut() {
      localStorage.clear('user');
      this.$router.replace('/login');
    },
    tabsClick(item) {
      const route = this.$router;
      const routePath = this.$route.path;
      let index = routeMap[item.name];


      if (index != undefined) {
        index = index.index;
      }

      this.$refs['menu'].openMenu(index)
      this.editableTabs.forEach(function (e) {


        if (e.name == item.name) {

          if (routePath !== e.path) {
            // this.$refs['menu']

            route.replace(e.path)
          }

        }

      });

    },
    //打开选项卡
    openTabs(path, title) {

      let isHave = false;
      let editableTabsValue;

      this.editableTabs.forEach(function (e) {

        if (e.path == path) {

          isHave = true;
          editableTabsValue = e.name;

        }

      });


      if (!isHave) {

        let newTabName = path;
        this.editableTabs.push({
          title: title,
          name: newTabName,
          path: path,
        });
        this.editableTabsValue = newTabName;

      } else {
        this.editableTabsValue = editableTabsValue;
      }


      let isAppend = true;
      this.keepAlive.forEach(function (e) {
        if (e == routeMap[path].componentsName) {
          isAppend = false;
        }
      });
      if (isAppend) {
        this.keepAlive.push(routeMap[path].componentsName)
      }


      if (this.$route.path !== path) {

        this.$router.replace(path)
      }


    },
    //删除选项卡
    removeTab(targetName) {

      const _this = this;
      if (_this.editableTabs.length <= 1) {
        _this.$notify.error({
          title: 'Hi Charge',
          message: '您不能够关闭该选项卡'
        });
        return false;
      }

      _this.keepAlive.forEach(function (e, i) {
        if (e == routeMap[targetName].componentsName) {
          _this.keepAlive.splice(i, 1)
        }
      })

      let tabs = _this.editableTabs;

      tabs.forEach((tab, index) => {
        if (tab.name === targetName) {
          let nextTab = tabs[index - 1];
          if (nextTab == undefined) {
            nextTab = tabs[index + 1];
          }
          _this.$router.replace(nextTab.path)
          _this.$refs['menu'].openMenu('0-1')
          setTimeout(function () {
            _this.$refs['menu'].openMenu(routeMap[nextTab.path].index)
            _this.editableTabsValue = nextTab.path;
          }, 100)
          tabs.splice(index, 1);

        }
      });


    },
    refresh(item) {
      const _this = this;
      console.log(_this.keepAlive);
      _this.keepAlive.forEach(function (e, i) {
        if (e == routeMap[item.path].componentsName) {
          _this.keepAlive.splice(i, 1)
          _this.show = false;
          Vue.nextTick(function () {
            setTimeout(function () {
              _this.show = true;
              _this.keepAlive.push(routeMap[item.path].componentsName)
            }, 100)
          });
        }
      })
    }
  }
}

</script>

<style scoped>

.box >>> .el-tabs__content {
  min-height: 100%;
  background-color: #fff;
}

.box {
  height: 100%;
}


.box .header {
  border-bottom: 1px solid #E4E7ED;
}

.box .header .left {
  height: 100%;
  width: 30%;
  float: left;
  text-align: left;
  padding-left: 1.5rem;
}

.box .header .left .logo {
  line-height: 61px;
  float: left;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.box .header .right {

  float: right;

}

.box .main {
  padding: 0;
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.el-icon-refresh:hover {
  color: #2a3746;
}

>>> .el-tabs__nav-wrap::after {

  background-color: #fff;
}

</style>