import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: {
            details:{},
            allPermissions:{},
        }
    },
    mutations: {
        setUser(state, newValue){
            state.user.details = newValue.details;
            state.user.allPermissions = newValue.allPermissions;
        }
    }
})

export default store;