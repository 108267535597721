<template>
    <div>


        <div style="margin-bottom: 15px;">
            <template>
                <el-form>
                    <el-select v-model="search.agentId" placeholder="请选择代理商">
                        <el-option
                                key="0"
                                label="全部"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in agentList"
                                :key="item.id"
                                :label="item.agent_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                    <el-input style="width:250px;margin-left:10px;"
                              placeholder="请输入业务员名称"
                              v-model="search.salesmanName"
                              clearable>
                    </el-input>
                    <el-input style="margin-left:10px;width: 250px;"
                              placeholder="请输入手机号"
                              v-model="search.salesmanPhone"
                              clearable>
                    </el-input>
                    <el-button style="margin-left:10px;" type="primary" @click="handleSearch()" icon="el-icon-search">
                        搜索
                    </el-button>
                    <el-button v-if="permissions['api/op.salesman'] && permissions['api/op.salesman']['POST']" style="margin-left:10px;" type="success" icon="el-icon-add-location" @click="openDialog">
                        新增
                    </el-button>
                </el-form>
            </template>


        </div>

        <el-dialog
                top="1vh"
                :title="dialogTitle"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="handleDialogClose"
                :visible.sync="dialogVisible"
                width="50%"
        >

            <el-form ref="form" :rules="validateRulesObject" :validate-on-rule-change="false" :model="form"
                     label-width="130px"
                     style="padding-right: 20px;">

                <template>

                    <el-tabs tab-position="left">

                        <el-tab-pane label="基本设置">

                            <el-form-item label="所属代理商" prop="belong_agent">
                                <el-select disabled v-if="details" v-model="form.belong_agent"
                                           @change="handleFormAgentSelected" placeholder="请选择归属的代理商">
                                    <el-option
                                            v-for="item in agentListForm"
                                            :key="item.id"
                                            :label="item.agent_name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-select v-if="!details" v-model="form.belong_agent" @change="handleFormAgentSelected"
                                           placeholder="请选择归属的代理商">
                                    <el-option
                                            v-for="item in agentListForm"
                                            :key="item.id"
                                            :label="item.agent_name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                                <div v-show="details" style="color:red;margin-bottom: -15px;">PS:暂不支持修改所属代理商</div>
                            </el-form-item>

                            <el-form-item label="手机号" prop="phone">
                                <el-input show-word-limit maxlength="15" v-model="form.phone"></el-input>
                            </el-form-item>

                            <el-form-item label="密码" prop="password">
                                <el-input maxlength="16" type="password" v-model="form.password"></el-input>
                            </el-form-item>

                            <el-form-item label="真实姓名" prop="real_name">
                                <el-input show-word-limit maxlength="30" v-model="form.real_name"></el-input>
                            </el-form-item>


                            <el-form-item label="状态" prop="status">
                                <el-switch v-model="form.is_use"></el-switch>
                            </el-form-item>

                        </el-tab-pane>


                    </el-tabs>
                </template>

            </el-form>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit()">确 定</el-button>
            </span>
        </el-dialog>

        <template>
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="业务员名字"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.real_name }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="代理商名称"
                        width="400"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.agent ? scope.row.agent.agent_name : '暂无上级' }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="手机号码"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.phone}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="状态"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.rate }}</span>
                        <span v-if="scope.row.is_use == 1">开启</span>
                        <span v-if="scope.row.is_use != 1">禁止</span>
                    </template>
                </el-table-column>


                <el-table-column

                        label="入住时间"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span>{{ scope.row.addtime}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="primary"
                                v-if="permissions['api/op.salesman'] && permissions['api/op.salesman']['PUT']"
                                @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-button>
                        <el-popconfirm
                                title="确定要删除吗？"
                                v-if="permissions['api/op.salesman'] && permissions['api/op.salesman']['DELETE']"
                                @confirm="handleDelete(scope.$index, scope.row)"
                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="danger"
                                       slot="reference">删除
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    let Base64 = require('js-base64').Base64

    export default {
        name: "Salesman",
        data() {
            return {
                form: {
                    belong_agent: '',
                    phone: '',
                    password: '',
                    real_name: '',
                    is_use: true,
                },
                validateRulesObject: {},
                validateRules: {
                    add: {
                        belong_agent: [
                            {required: true, message: '请选择归属的代理商', trigger: 'blur'},
                        ],
                        phone: [
                            {required: true, message: '请输入手机号', trigger: 'blur'},
                        ],
                        password: [
                            {required: true, message: '请输入登录密码', trigger: 'blur'},
                            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                        ],
                        real_name: [
                            {required: true, message: '请输入真实名字', trigger: 'blur'},
                        ],
                        rate: [
                            {required: true, message: '请选择佣金比例', trigger: 'blur'},
                        ],
                    },
                    edit: {
                        phone: [
                            {required: true, message: '请输入手机号', trigger: 'blur'},
                        ],
                        password: [
                            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                        ],
                        real_name: [
                            {required: true, message: '请输入真实名字', trigger: 'blur'},
                        ],
                        rate: [
                            {required: true, message: '请选择佣金比例', trigger: 'blur'},
                        ],
                    },
                },
                dialogTitle: '',
                dialogVisible: false,
                search: {
                    agentId: 0,
                    salesmanName: '',
                    salesmanPhone: '',
                },
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
                agentList: [],
                agentListForm: [],
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted: function () {
            this.getListData();
            this.getAgentData();
        },
        methods: {
            // 处理商家表单提交
            handleSubmit() {

                const _this = this;

                _this.$refs['form'].validate((valid) => {
                    if (valid) {


                        if (!_this.details) {

                            const loading = this.$loading({
                                lock: true,
                                text: 'Loading',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)'
                            });

                            let requestData = JSON.parse(JSON.stringify(_this.form));

                            requestData.is_use = requestData.is_use ? 1 : 2;

                            _this.axios.post('/api/op/salesman', requestData).then(function (response) {

                                loading.close();

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {

                                    _this.$notify({
                                        title: '成功',
                                        message: message,
                                        type: 'success'
                                    });
                                    _this.dialogVisible = false;
                                    _this.getListData();
                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                loading.close();
                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })


                        } else {

                            const loading = this.$loading({
                                lock: true,
                                text: 'Loading',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)'
                            });

                            let requestData = JSON.parse(JSON.stringify(_this.form));

                            requestData.is_use = requestData.is_use ? 1 : 2;

                            _this.axios.put('/api/op/salesman/' + _this.details.id, requestData).then(function (response) {

                                loading.close();

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {

                                    _this.$notify({
                                        title: '成功',
                                        message: message,
                                        type: 'success'
                                    });
                                    _this.dialogVisible = false;
                                    _this.getListData();
                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                loading.close();
                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })

                        }

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: '请按照要求填写信息'
                        });
                    }
                });


            },
            //加载代理商数据
            getAgentData() {

                const _this = this;

                _this.axios.get('api/op/agent', {
                    params: {},
                    headers: {'page': 1, "page-size": _this.pageSize},
                }).then(function (response) {
                        if (response.status == 200 && response.data.status != undefined && response.data.status == 1) {
                            _this.allAgentList = response.data.data.list;
                            response.data.data.list.forEach(function (e) {
                                if (e.type == 1) {
                                    _this.agentList.push(e);
                                    _this.agentListForm.push(e);
                                }
                            })
                        }
                    }
                ).catch(function () {

                });

            },
            //加载列表数据
            getListData(page) {


                const _this = this;
                _this.tabLoading = true;
                _this.tableData = [];
                if (page != undefined) {
                    _this.currentPage = page;
                }

                // let user = JSON.parse(localStorage.getItem('user'));

                _this.axios.get('api/op/salesman', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        // token: user.token,
                        'name': Base64.toBase64(_this.search.salesmanName),
                        'mobile': _this.search.salesmanPhone,
                        'agent-id': _this.search.agentId,
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 打开表单弹层
            openDialog(index, row) {
                const _this = this;
                _this.dialogVisible = true;
                _this.dialogTitle = '添加业务员';
                _this.dialogAction = 'add';
                _this.validateRulesObject = _this.validateRules.add;
                _this.salesmanFormList = [];
                _this.handleDialogClose();

                setTimeout(function () {

                    if (index != null && row != null) {
                        _this.dialogAction = 'edit';
                        _this.dialogTitle = '编辑业务员';
                        _this.details = row;
                        _this.validateRulesObject = _this.validateRules.edit;

                        for (let key in row) {

                            if (_this.form[key] != undefined) {

                                if (key == 'password') {
                                    //密码跳过
                                } else if (key == 'status') {

                                    console.log('');

                                } else if (key == 'is_use') {

                                    if (row[key] == 1) {
                                        _this.form['is_use'] = true;
                                    } else {
                                        _this.form['is_use'] = false;
                                    }

                                } else if (key == 'belong_agent') {

                                    _this.form[key] = parseInt(row[key])

                                } else {

                                    _this.form[key] = row[key];
                                }
                            }

                        }

                    } else {
                        _this.details = null;
                        _this.form.is_use = true;
                    }
                }, 0)

            },
            // 处理表单弹层关闭
            handleDialogClose() {
                const _this = this;
                if (_this.$refs['form'] != undefined) {
                    _this.$refs['form'].resetFields();
                }
                _this.details = null;
                _this.form.is_use = true;
            },
            // 打开表单弹层
            handleEdit(index, row) {

                const _this = this;
                _this.openDialog(index, row);


            },
            // 删除业务员
            handleDelete(index, row) {

                let _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/salesman/' + row.id).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.getListData();

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();

                    const message = error.response.data.message == undefined ? "操作失败,请重试" : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: message
                    });

                });

            },
            // 搜索
            handleSearch() {

                const _this = this;

                _this.getListData();


            },

        }
    }
</script>

<style scoped>

</style>