<template>

    <div>

        <div class="block" v-for="item in list" :key="item">
            <el-timeline>
                <el-timeline-item :timestamp="item.title" placement="top">
                    <el-card>
                        <p>{{item.text}}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </div>

    </div>

</template>

<script>

    import Vue from "vue";



    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2, '0')

        //小时
        const hh = (dt.getHours() + '').padStart(2, '0')
        //分钟
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        //秒数
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })

    export default {
        name: "Monitoring",
        data() {
            return {
                list: []
            }
        },
        mounted() {
            // 连接服务端
            connect();
            var ws;
            var _this = this;

            function getNowFormatDate() {
                var date = new Date();
                var seperator1 = "-";
                var seperator2 = ":";
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
                    + " " + date.getHours() + seperator2 + date.getMinutes()
                    + seperator2 + date.getSeconds();
                return currentdate;
            }

            function connect() {
                // 创建websocket
                ws = new WebSocket("wss://hicharge.net:7272");
                // 当socket连接打开时，输入用户名
                ws.onopen = onopen;
                // 当有消息时根据消息类型显示不同信息
                ws.onmessage = onmessage;
                ws.onclose = function (e) {
                    console.log(e)
                    _this.list.push({text: '链接关闭，30秒后重新链接', title: getNowFormatDate()});
                    window.setTimeout(function () {
                        connect();
                    }, 30000);

                };
                ws.onerror = function (e) {
                    console.log(e)
                    _this.list.push({text: '出现错误', title: getNowFormatDate()});
                };


            }

            // 连接建立时发送登录信息
            function onopen() {
                // 登录
                var login_data = 'listen';//'service,ab2506cbbb3be96eb6839cf63dd4168b,1617963429,listen'
                _this.list.push({text: '链接成功', title: getNowFormatDate()});
                heartbeat();
                ws.send(login_data);
            }

            // 服务端发来消息时
            function onmessage(e) {
                if (e) {
                    _this.list.push({text: e.data, title: getNowFormatDate()});
                }
            }

            //心跳
            function heartbeat() {
                var data = 'service,heartbeat';
                ws.send(data);
                window.setTimeout(function(){
                    heartbeat();
                },30000);
            }


        },
        methods: {}
    }
</script>

<style scoped>

</style>