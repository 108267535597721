<template>
    <div>


        <div style="margin-bottom: 10px;">
            <template>
                <el-select v-model="search.userType" placeholder="请选择用户类型">
                    <el-option
                            v-for="item in userTypeList"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                    </el-option>
                </el-select>
            </template>
            <el-input style="margin-left:10px;width: 150px;"
                      placeholder="请输入用户昵称"
                      v-model="search.nickName"
                      clearable>
            </el-input>
            <el-input style="margin-left:10px;width: 150px;"
                      placeholder="请输入手机号"
                      v-model="search.mobilePhoneNo"
                      clearable>
            </el-input>

            <el-input style="margin-left:10px;width: 150px;"
                      placeholder="请输入用户ID"
                      v-model="search.uid"
                      clearable>
            </el-input>

            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch()">搜索
            </el-button>

        </div>

        <el-dialog
                top="1vh"
                title="编辑余额"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog1.dialogVisible"
                width="50%"
        >

            <el-form ref="form" :model="form"
                     label-width="130px"
                     style="padding-right: 20px;">

                <template>

                    <el-tabs tab-position="left">

                        <el-tab-pane label="余额设置">


                            <el-form-item label="客户信息">

                                <img v-if="dialog1.details.user_type == 1" :src="dialog1.details.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog1.details.user_type == 3" :src="dialog1.details.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog1.details.user_type == 11" :src="dialog1.details.app_headimgurl"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog1.details.user_type == 12" :src="dialog1.details.ali_avatar"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog1.details.user_type == 13" :src="dialog1.details.google_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog1.details.user_type == 14" :src="dialog1.details.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog1.details.user_type == 15" :src="dialog1.details.line_pictureUrl"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog1.details.user_type == 16" :src="dialog1.details.facebook_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog1.details.user_type == 16" :src="dialog1.details.facebook_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else style="width:40px;border-radius: 2px;"
                                     src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7cxCzyScSH79WkL5fqaGCm73xbS4tsWAp18BzvfPCp1kAK5NtWUt5KDCp6LQHr5ylR5jsshuSSKQ/132"/>

                                <span style="position: relative;left:5px;" size="medium">
                                    <span v-if="dialog1.details.user_type == 1">{{ dialog1.details.name}}</span>
                                    <span v-else-if="dialog1.details.user_type == 3">{{ dialog1.details.name}}</span>
                                    <span v-else-if="dialog1.details.user_type == 11">{{ dialog1.details.app_nickname}}</span>
                                    <span v-else-if="dialog1.details.user_type == 12">{{ dialog1.details.ali_nick_name}}</span>
                                    <span v-else-if="dialog1.details.user_type == 13">{{ dialog1.details.google_display_name}}</span>
                                    <span v-else-if="dialog1.details.user_type == 14">{{ dialog1.details.apple_name}}</span>
                                    <span v-else-if="dialog1.details.user_type == 15">{{ dialog1.details.line_displayname}}</span>
                                    <span v-else-if="dialog1.details.user_type == 16">{{ dialog1.details.facebook_name}}</span>
                                    <span v-else>暂无</span>
                                </span>

                            </el-form-item>

                            <el-form-item label="余额">
                                <el-input disabled v-model="dialog1.details.balance"></el-input>
                            </el-form-item>

                            <el-form-item label="押金">
                                <el-input disabled v-model="dialog1.details.deposit"></el-input>
                            </el-form-item>

                            <el-form-item label="操作" prop="changeType">
                                <el-radio-group v-model="dialog1.form.changeType">
                                    <el-radio-button label="1">增加</el-radio-button>
                                    <el-radio-button label="2">减少</el-radio-button>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label="金额" prop="amount">
                                <template>
                                    <el-input-number v-model="dialog1.form.amount" :precision="2" :step="1"
                                                     :max="999999.99" :min="0.01"></el-input-number>
                                </template>
                            </el-form-item>


                        </el-tab-pane>


                    </el-tabs>
                </template>

            </el-form>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialog1.dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
                class="box"
                title="订单详情"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialogVisible2"
                width="85%"
        >

            <el-scrollbar style="height:100%" v-if="dialogVisible2">

                <rent-order :uid="queryOrderUid"></rent-order>

            </el-scrollbar>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">关闭</el-button>
            </span>
        </el-dialog>

        <el-dialog
                class="box"
                title="订单详情"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialogVisible3"
                width="85%"
        >

            <el-scrollbar style="height:100%" v-if="dialogVisible3">

                <topup-odrer :uid="queryOrderUid"></topup-odrer>

            </el-scrollbar>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false">关闭</el-button>
            </span>
        </el-dialog>

        <el-dialog
                class="box"
                title="订单详情"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialogVisible4"
                width="85%"
        >

            <el-scrollbar style="height:100%" v-if="dialogVisible4">

                <refund-order :uid="queryOrderUid"></refund-order>

            </el-scrollbar>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible4 = false">关闭</el-button>
            </span>
        </el-dialog>

        <el-dialog
                top="1vh"
                title="发起退款"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog5.dialogVisible"
                width="50%"
        >

            <el-form ref="form" :model="form"
                     label-width="130px"
                     style="padding-right: 20px;">

                <template>

                    <el-tabs tab-position="left">

                        <el-tab-pane label="退款">

                            <el-form-item label="客户信息">

                                <img v-if="dialog5.details.user_type == 1" :src="dialog5.details.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog5.details.user_type == 3" :src="dialog5.details.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog5.details.user_type == 11" :src="dialog5.details.app_headimgurl"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog5.details.user_type == 12" :src="dialog5.details.ali_avatar"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog5.details.user_type == 13" :src="dialog5.details.google_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog5.details.user_type == 14" :src="dialog5.details.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog5.details.user_type == 15" :src="dialog5.details.line_pictureUrl"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog5.details.user_type == 16" :src="dialog5.details.facebook_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="dialog5.details.user_type == 16" :src="dialog5.details.facebook_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else style="width:40px;border-radius: 2px;"
                                     src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7cxCzyScSH79WkL5fqaGCm73xbS4tsWAp18BzvfPCp1kAK5NtWUt5KDCp6LQHr5ylR5jsshuSSKQ/132"/>

                                <span style="position: relative;left:5px;" size="medium">
                                    <span v-if="dialog5.details.user_type == 1">{{ dialog5.details.name}}</span>
                                    <span v-else-if="dialog5.details.user_type == 3">{{ dialog5.details.name}}</span>
                                    <span v-else-if="dialog5.details.user_type == 11">{{ dialog5.details.app_nickname}}</span>
                                    <span v-else-if="dialog5.details.user_type == 12">{{ dialog5.details.ali_nick_name}}</span>
                                    <span v-else-if="dialog5.details.user_type == 13">{{ dialog5.details.google_display_name}}</span>
                                    <span v-else-if="dialog5.details.user_type == 14">{{ dialog5.details.apple_name}}</span>
                                    <span v-else-if="dialog5.details.user_type == 15">{{ dialog5.details.line_displayname}}</span>
                                    <span v-else-if="dialog5.details.user_type == 16">{{ dialog5.details.facebook_name}}</span>
                                    <span v-else>暂无</span>
                                </span>

                            </el-form-item>

                            <el-form-item label="余额">
                                <el-input disabled v-model="dialog5.details.balance"></el-input>
                            </el-form-item>

                            <el-form-item label="总充值金额">
                                <el-input disabled v-model="dialog5.details.total_recharge_amount"></el-input>
                            </el-form-item>

                            <el-form-item label="已退金额">
                                <el-input disabled v-model="dialog5.details.already_refund_amount"></el-input>
                            </el-form-item>

                            <el-form-item label="退款金额">
                                <el-input v-model="dialog5.form.refund_amount"></el-input>
                            </el-form-item>

                            <el-form-item>
                                <span style="color: red;">PS:最多可退：{{dialog5.details.max_refund_amount}}</span>
                            </el-form-item>

                        </el-tab-pane>


                    </el-tabs>
                </template>

            </el-form>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialog5.dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="refund()">退款</el-button>
            </span>
        </el-dialog>


        <template>
            <el-table
                    size="mini"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="用户类型"
                        width="250">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px" v-if="scope.row.user_type == 1">微信/小程序</span>
                        <span style="margin-left: 10px" v-else-if="scope.row.user_type == 3">手机号</span>
                        <span style="margin-left: 10px" v-else-if="scope.row.user_type == 11">微信/APP</span>
                        <span style="margin-left: 10px" v-else-if="scope.row.user_type == 12">支付宝/APP</span>
                        <span style="margin-left: 10px" v-else-if="scope.row.user_type == 13">Google</span>
                        <span style="margin-left: 10px" v-else-if="scope.row.user_type == 14">Apple</span>
                        <span style="margin-left: 10px" v-else-if="scope.row.user_type == 15">Line</span>
                        <span style="margin-left: 10px" v-else-if="scope.row.user_type == 16">Facebook</span>
                        <span style="margin-left: 10px" v-else> 未知</span>
                    </template>
                </el-table-column>

                <el-table-column style="line-height: 35px;"
                                 label="用户信息"
                                 width="250">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">

                            <p v-if="scope.row.picture && scope.row.user_type != 1 && scope.row.user_type != 3 &&  scope.row.user_type != 14">

                                <img v-if="scope.row.picture" :src="scope.row.picture"
                                     style="width:40px;border-radius: 2px;"/>

                            </p>

                            <p v-if="scope.row.name && scope.row.user_type != 1 &&  scope.row.user_type != 3 ">APP昵称:{{
                                scope.row.name}}</p>
                            <p>性别:
                                <span v-if="scope.row.user_type == 1">
                                    <span v-if="scope.row.gender == 1">男</span>
                                    <span v-else-if="scope.row.gender == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                                <span v-else-if="scope.row.user_type == 11">
                                    <span v-if="scope.row.app_sex == 1">男</span>
                                    <span v-else-if="scope.row.app_sex == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                                <span v-else>
                                    <span>未知</span>
                                </span>
                            </p>
                            <p v-if="scope.row.mobile">手机号: +{{scope.row.country_code}}{{scope.row.mobile}}</p>
                            <p>语言:{{scope.row.language ? scope.row.language : 'zh-cn'}}</p>
                            <p>地区:
                                <span v-if="scope.row.user_type == 1">
                                    {{scope.row.province}} {{scope.row.province}} {{scope.row.city}}
                                </span>
                                <span v-else-if="scope.row.user_type == 11">
                                    {{scope.row.app_country}} {{scope.row.app_province}} {{scope.row.app_city}}
                                </span>
                                <span v-else>
                                    <span></span>
                                </span>
                            </p>
                            <div slot="reference" class="name-wrapper">


                                <img v-if="scope.row.user_type == 1" :src="scope.row.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="scope.row.user_type == 3" :src="scope.row.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="scope.row.user_type == 11" :src="scope.row.app_headimgurl"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="scope.row.user_type == 12" :src="scope.row.ali_avatar"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="scope.row.user_type == 13" :src="scope.row.google_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="scope.row.user_type == 14" :src="scope.row.picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="scope.row.user_type == 15" :src="scope.row.line_pictureUrl"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="scope.row.user_type == 16" :src="scope.row.facebook_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else-if="scope.row.user_type == 16" :src="scope.row.facebook_picture"
                                     style="width:40px;border-radius: 2px;"/>
                                <img v-else style="width:40px;border-radius: 2px;"
                                     src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7cxCzyScSH79WkL5fqaGCm73xbS4tsWAp18BzvfPCp1kAK5NtWUt5KDCp6LQHr5ylR5jsshuSSKQ/132"/>

                                <span style="position: relative;left:5px;" size="medium">
                                    <span v-if="scope.row.user_type == 1">{{ scope.row.name}}</span>
                                    <span v-else-if="scope.row.user_type == 3">{{ scope.row.name}}</span>
                                    <span v-else-if="scope.row.user_type == 11">{{ scope.row.app_nickname}}</span>
                                    <span v-else-if="scope.row.user_type == 12">{{ scope.row.ali_nick_name}}</span>
                                    <span v-else-if="scope.row.user_type == 13">{{ scope.row.google_display_name}}</span>
                                    <span v-else-if="scope.row.user_type == 14">{{ scope.row.apple_name}}</span>
                                    <span v-else-if="scope.row.user_type == 15">{{ scope.row.line_displayname}}</span>
                                    <span v-else-if="scope.row.user_type == 16">{{ scope.row.facebook_name}}</span>
                                    <span v-else>暂无</span>
                                </span>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column
                        label="余额"
                        width="180">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.balance }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="押金"
                        width="180">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.deposit }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="注册时间"
                        width="250">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                                v-if="permissions['api/op.members'] && permissions['api/op.members']['PUT']"
                                size="mini"
                                type="primary"
                                @click="opendialog1(scope.$index, scope.row)">编辑余额
                        </el-button>
                        <el-button
                                v-if="permissions['api/op.refund'] && permissions['api/op.refund']['POST']"
                                @click="opendialog5(scope.$index, scope.row)"
                                size="mini"
                                type="danger"
                        >退款
                        </el-button>
                        <el-button
                                v-if="permissions['api/op.orders'] && permissions['api/op.orders']['GET']"
                                size="mini"
                                @click="dialogVisible2= true;queryOrderUid = scope.row.id"
                        >租借订单
                        </el-button>
                        <el-button
                                v-if="permissions['api/op.topup'] && permissions['api/op.topup']['GET']"
                                size="mini"
                                @click="dialogVisible3= true;queryOrderUid = scope.row.id"
                        >充值订单
                        </el-button>
                        <el-button
                                v-if="permissions['api/op.refund'] && permissions['api/op.refund']['GET']"
                                size="mini"
                                @click="dialogVisible4= true;queryOrderUid = scope.row.id"
                        >退款订单
                        </el-button>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    let Base64 = require('js-base64').Base64

    import RentOrder from '../order/Order'
    import TopupOdrer from '../financial/Topup'
    import RefundOrder from '../financial/Refund'

    export default {
        name: "User",
        data() {
            return {
                search: {
                    userType: 0,
                    nickName: '',
                    mobilePhoneNo: '',
                    uid: ''
                },
                row: {
                    balance: 0.00,
                    deposit: 0.00,
                },
                userTypeList: [
                    {name: '全部', type: 0},
                    {name: '微信/小程序', type: 1},
                    {name: '微信/APP', type: 11},
                    {name: '支付宝', type: 12}, //App
                    {name: '手机号', type: 3},
                    {name: 'Google', type: 13},
                    {name: 'Apple', type: 14},
                    {name: 'Line', type: 15},
                    {name: 'Facebook', type: 16},
                ],
                edit_id: 0,
                dialogTitle: '',
                dialog1: {
                    dialogVisible: false,
                    details: {},
                    form: {
                        changeType: "1",
                        amount: 0.00,
                    },
                },
                dialogVisible2: false,
                dialogVisible3: false,
                dialogVisible4: false,
                dialog5: {
                    dialogVisible: false,
                    details: {
                        balance: '',
                        total_recharge_amount: '',
                        total_refund_amount: '',
                    },
                    form: {
                        refund_amount: '',
                    }
                },
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
                queryOrderUid: 0,

            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted: function () {
            this.getListData();
        },
        methods: {
            // 提交编辑余额表单
            handleSubmit() {
                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.put('/api/op/members/' + _this.dialog1.details.id, _this.dialog1.form).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.dialog1.dialogVisible = false;
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            //加载列表数据
            getListData(page) {

                const _this = this;
                _this.tabLoading = true;
                if (page != undefined) {
                    _this.currentPage = page;
                }

                _this.axios.get('api/op/members', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'user-type': _this.search.userType,
                        'nickName': Base64.toBase64(_this.search.nickName),
                        'mobilePhoneNo': _this.search.mobilePhoneNo,
                        'uid': _this.search.uid,
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {

                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 打开编辑余额弹层
            opendialog1(index, row) {
                const _this = this;
                _this.dialog1.dialogVisible = true;
                _this.dialog1.details = row
                _this.dialog1.form.amount = 0.000;
                _this.dialog1.form.changeType = 1;
            },
            // 打开退款弹层
            opendialog5(index, row) {

                const _this = this;

                if (row.user_type != 1) {

                    _this.$notify.error({
                        title: '错误',
                        message: '暂不支持除小程序外的退款'
                    });

                    return false;

                }

                _this.dialog5.dialogVisible = true;
                _this.dialog5.form.refund_amount = '';
                _this.dialog5.details = row;

            },
            // 发起退款
            refund() {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.post('/api/op/refund', {
                    uid: _this.dialog5.details.id,
                    refund_amount: _this.dialog5.form.refund_amount
                }).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.dialog5.dialogVisible = false;
                        _this.getListData();

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 搜索
            handleSearch() {

                const _this = this;


                _this.currentPage = 1;


                _this.getListData();


            },

        },
        components: {
            RentOrder,
            TopupOdrer,
            RefundOrder,
        }
    }
</script>

<style scoped>

    .box /deep/ .el-dialog {
        margin: 5vh auto !important;
    }

    .box /deep/ .el-dialog__body {
        height: 60vh;
        overflow: auto;
    }

</style>