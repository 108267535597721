<template>

    <div>

        <div>
            <el-button v-if="permissions['api/op.charecabinet'] && permissions['api/op.charecabinet']['POST']" type="success"
                       @click="openDialog2">
                新增机柜
            </el-button>
            <el-button v-if="permissions['api/op.charecabinet'] && permissions['api/op.charecabinet']['POST'] && permissions['api/op.excelFile'] && permissions['api/op.excelFile']['POST']" type="success" @click="openDialog3">批量导入机柜</el-button>
        </div>

        <div style="margin-top: 10px;margin-bottom: 10px;">

            <el-select v-model="search.agentId"
                       placeholder="请选择代理商">
                <el-option
                        key="0"
                        label="全部"
                        :value="0">
                </el-option>
                <el-option
                        v-for="item in agentList"
                        :key="item.id"
                        :label="item.agent_name"
                        :value="item.id">
                </el-option>
            </el-select>

            <el-input style="margin-left:10px;width: 150px;"
                      placeholder="商家名称"
                      v-model="search.merchantsName"
                      clearable>
            </el-input>

            <el-input style="margin-left:10px;width: 150px;"
                      placeholder="机柜ID"
                      v-model="search.charecabinetId"
                      clearable>
            </el-input>

            <el-input style="margin-left:10px;width: 150px;"
                      placeholder="ICCID"
                      v-model="search.iccId"
                      clearable>
            </el-input>

            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch()">搜索
            </el-button>

        </div>

        <el-dialog
                append-to-body
                top="1vh"
                title="修改充电宝"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog1.dialogVisible"
                width="35%"
        >

            <el-form :model="dialog.dialog1.form" :rules="dialog.dialog1.validateRules" ref="dialog1Form"
                     label-position="left" label-width="100px">
                <el-form-item label="机柜号" prop="cabinet_id">
                    <el-input placeholder="机柜号" show-word-limit clearable maxlength="20"
                              v-model="dialog.dialog1.form.cabinet_id"></el-input>
                </el-form-item>
                <el-form-item label="机柜型号" prop="device_model">
                    <el-input placeholder="机柜型号" show-word-limit clearable maxlength="10"
                              v-model="dialog.dialog1.form.device_model"></el-input>
                </el-form-item>
                <el-form-item label="插槽数" prop="device_number">
                    <el-input placeholder="插槽数" show-word-limit clearable maxlength="3"
                              v-model.number="dialog.dialog1.form.device_number"></el-input>
                </el-form-item>
                <el-form-item label="运行模式" prop="device_mode">
                    <el-radio-group v-model="dialog.dialog1.form.device_mode">
                        <el-radio-button label="1">运营</el-radio-button>
                        <el-radio-button label="2">调试</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="归属代理商">
                    <el-select @change="editAgentSelectChange" style="width: 100%;"
                               v-model="dialog.dialog1.form.agent_id"
                               placeholder="请选择代理商">
                        <el-option
                                key="0"
                                label="暂不选择"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in agentList"
                                :key="item.id"
                                :label="item.agent_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="归属商家">
                    <el-select style="width: 100%;" v-model="dialog.dialog1.form.merchants_id"
                               placeholder="请选择商家">
                        <el-option
                                key="0"
                                label="暂不选择"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in merchantsList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-button
                        size="medium"
                        type="primary"
                        @click="update"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialog.dialog1.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <el-dialog
                append-to-body
                top="1vh"
                title="新增机柜"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog2.dialogVisible"
                width="35%"
        >

            <el-form :model="dialog.dialog2.form" :rules="dialog.dialog2.validateRules" ref="dialog2Form"
                     label-position="left" label-width="100px">
                <el-form-item label="机柜号" prop="cabinet_id">
                    <el-input placeholder="机柜号" show-word-limit clearable maxlength="20"
                              v-model="dialog.dialog2.form.cabinet_id"></el-input>
                </el-form-item>
                <el-form-item label="机柜型号" prop="device_model">
                    <el-input placeholder="机柜型号" show-word-limit clearable maxlength="10"
                              v-model="dialog.dialog2.form.device_model"></el-input>
                </el-form-item>
                <el-form-item label="插槽数" prop="device_number">
                    <el-input placeholder="插槽数" show-word-limit clearable maxlength="3"
                              v-model.number="dialog.dialog2.form.device_number"></el-input>
                </el-form-item>
                <el-form-item label="归属代理商">
                    <el-select @change="addOneAgentSelectChange" style="width: 100%;"
                               v-model="dialog.dialog2.form.agent_id"
                               placeholder="请选择代理商">
                        <el-option
                                key="0"
                                label="暂不选择"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in agentList"
                                :key="item.id"
                                :label="item.agent_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="归属商家">
                    <el-select style="width: 100%;" v-model="dialog.dialog2.form.merchants_id"
                               placeholder="请选择商家">
                        <el-option
                                key="0"
                                label="暂不选择"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in merchantsList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-button
                        size="medium"
                        type="primary"
                        @click="add"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialog.dialog2.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <el-dialog
                append-to-body
                top="1vh"
                title="批量导入机柜"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog3.dialogVisible"
                width="35%"
        >

            <el-form :model="dialog.dialog3.form" :rules="dialog.dialog3.validateRules" ref="dialog3Form"
                     label-position="left" label-width="100px">

                <el-form-item prop="agent_id" label="归属代理商">
                    <el-select style="width: 100%;" v-model="dialog.dialog3.form.agent_id"
                               placeholder="请选择代理商">
                        <el-option
                                key="0"
                                label="请选择代理商"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in agentList"
                                :key="item.id"
                                :label="item.agent_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="file_name" label="Excel文件">
                    <el-upload
                            ref="uploadFile1"
                            style="width: 100%"
                            limit="1"
                            :headers="uploadHeader"
                            :file-list="dialog.dialog3.fileList"
                            :on-success="handeUploadFile1"
                            :on-remove="handleRemoveFile1"
                            drag
                            action="/api/op/excelFile"
                            multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip" style="color: red;">PS：只能上传.xsl/.excel文件，且不超过2mb</div>
                        <div class="el-upload__tip" slot="tip" style="color: red;">文件模板，<a style="color: #409EFF;"
                                                                                           target="_blank"
                                                                                           href="http://hicharge.net/uploads/Cabinet%20import%20template.xls">点此下载</a>
                        </div>
                    </el-upload>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-button
                        size="medium"
                        type="primary"
                        @click="batchAdd"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialog.dialog3.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <el-dialog
                append-to-body
                fullscreen
                class="box2"
                title="设备详情"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog4.dialogVisible"

        >

            <div>

                <el-row>
                    <el-col :span="24">

                        <div class="transverse" v-loading="dialog.dialog4.loading"
                             v-if="dialog.dialog4.details.devicenum <= 9"
                             style="margin-top: 10px;">

                            <div style="text-align: center;position: absolute;left:45%;top:50px;font-size: 17px;"><a
                                    @click="openDialog5(dialog.dialog4.details)" href="#">{{dialog.dialog4.details.cabinet_id}}</a>
                            </div>

                            <div class="row">

                                <div class="dl_main">

                                    <ul class="dl_list">

                                        <li class="list" :key="n" v-for="n in 9">
                                            <a href="#" @click="openDialog6(n)" title="点击弹出充电宝"
                                               v-if="dialog.dialog4.details.stock_detail.info[n]">
                                                <div style="position: relative">
                                                    <div role="progressbar"
                                                         v-if="dialog.dialog4.details.stock_detail.info[n].power == 4"
                                                         class=" progress-bar-striped progress-bar progress-bar-80-100 progress-bar-animated"
                                                         style=" height: 100% ;" aria-valuenow=" 80~100 "> 电 量
                                                        80%~100%
                                                    </div>
                                                    <div role="progressbar"
                                                         v-if="dialog.dialog4.details.stock_detail.info[n].power == 3"
                                                         class=" progress-bar-striped progress-bar progress-bar-60-80 progress-bar-animated"
                                                         style=" height: 80% ;" aria-valuenow=" 60~80 "> 电 量
                                                        60%~80%
                                                    </div>
                                                    <div role="progressbar"
                                                         v-else-if="dialog.dialog4.details.stock_detail.info[n].power == 2"
                                                         class=" progress-bar-striped progress-bar progress-bar-40-60 progress-bar-animated"
                                                         style=" height: 60% ;" aria-valuenow=" 40~60 "> 电 量
                                                        40%~60%
                                                    </div>
                                                    <div role="progressbar"
                                                         v-else-if="dialog.dialog4.details.stock_detail.info[n].power == 1"
                                                         class=" progress-bar-striped progress-bar progress-bar-20-40 progress-bar-animated"
                                                         style=" height: 40% ;" aria-valuenow=" 20~40 "> 电 量
                                                        20%~40%
                                                    </div>
                                                    <div role="progressbar"
                                                         v-else-if="dialog.dialog4.details.stock_detail.info[n].power == 0"
                                                         class=" progress-bar-striped progress-bar progress-bar-0-20 progress-bar-animated"
                                                         style=" height: 20% ;" aria-valuenow="0~20 "> 电 量
                                                        0%~20%
                                                    </div>
                                                </div>
                                            </a>
                                            <a v-else href="javascript:void(0)">
                                                <img src="https://new.test.baomiduo.com/statics/images/null.png?v1.0"
                                                     alt="">
                                            </a>
                                        </li>
                                    </ul>

                                </div>

                                <div style="position: absolute; bottom: 30px;margin-left:20px;">
                                    <div class="row">
                                        <div class="col-sm-12" style="margin-left:300px;width:600px;">
                                            <el-button
                                                    size="medium "
                                                    type="primary"
                                                    @click="toViewVersion"
                                            >
                                                查看版本
                                            </el-button>

                                            <!--                                            <el-button-->
                                            <!--                                                    size="mini"-->
                                            <!--                                                    type="primary"-->
                                            <!--                                                    @click="toViewVersion"-->
                                            <!--                                            >-->
                                            <!--                                                查看APN-->
                                            <!--                                            </el-button>-->

                                            <el-popconfirm
                                                    title="确定要重新获取库存吗？"
                                                    @confirm="refreshDetail"

                                            >
                                                <el-button style="margin-left: 10px"
                                                           type="primary"
                                                           size="medium "
                                                           slot="reference">重新获取库存
                                                </el-button>
                                            </el-popconfirm>

                                            <el-popconfirm

                                                    placement="top"
                                                    title="确定要重启机柜吗？"
                                                    @confirm="restart"

                                            >
                                                <el-button style="margin-left: 10px"
                                                           type="primary"
                                                           size="medium "
                                                           slot="reference">重启机柜
                                                </el-button>
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col-sm-12" style="margin-left:300px;width:600px;">

                                            <el-button
                                                    size="medium "
                                                    type="primary"
                                                    @click="openDialog8"
                                            >
                                                更新配置
                                            </el-button>

                                            <!--                                            <el-button-->
                                            <!--                                                    size="mini"-->
                                            <!--                                                    type="primary"-->
                                            <!--                                                    @click="batchAdd"-->
                                            <!--                                            >-->
                                            <!--                                                设置APN-->
                                            <!--                                            </el-button>-->

                                            <el-popover
                                                    v-model="dialog.dialog4.popover"
                                                    placement="top"
                                                    width="300"
                                                    trigger="click">

                                                <el-form style="padding-top:30px;padding-left:10px;padding-right: 20px;"
                                                         label-position="left" label-width="50px">

                                                    <el-form-item label="锁ID">

                                                        <el-input style="width: 100%;"
                                                                  v-model="dialog.dialog4.lockId"></el-input>

                                                        <div STYLE="color: red;">PS:从左往右数，从1开始。</div>

                                                    </el-form-item>

                                                    <el-form-item style="text-align: right;">

                                                        <el-popconfirm

                                                                placement="top"
                                                                title="确定要打开吗？"
                                                                @confirm="openLock2(dialog.dialog4.lockId)"

                                                        >
                                                            <el-button style="margin-left: 10px"
                                                                       type="primary"
                                                                       size="medium"
                                                                       slot="reference">打开
                                                            </el-button>
                                                        </el-popconfirm>

                                                        <el-button style="margin-left: 10px"
                                                                   size="medium"
                                                                   @click="dialog.dialog4.popover = false;"
                                                        >关闭
                                                        </el-button>

                                                    </el-form-item>

                                                </el-form>


                                                <el-button
                                                        style="margin-left: 10px;"
                                                        type="primary"
                                                        size="medium "
                                                        @click="dialog.dialog4.lockId = '';"
                                                        slot="reference">打开指定锁
                                                </el-button>
                                            </el-popover>

                                            <el-popconfirm
                                                    title="确定要打开全部电子锁吗？"
                                                    @confirm="openAllLock"

                                            >
                                                <el-button style="margin-left: 10px"
                                                           type="primary"
                                                           size="medium"
                                                           slot="reference">打开全部锁
                                                </el-button>
                                            </el-popconfirm>

                                        </div>
                                    </div>
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col-sm-12" style="margin-left:300px;width:600px;">

                                            <!--                                            <el-button-->
                                            <!--                                                    size="mini"-->
                                            <!--                                                    type="primary"-->
                                            <!--                                                    @click="batchAdd"-->
                                            <!--                                            >-->
                                            <!--                                                查看配置-->
                                            <!--                                            </el-button>-->

                                            <!--                                            <el-button-->
                                            <!--                                                    size="mini"-->
                                            <!--                                                    type="primary"-->
                                            <!--                                                    @click="batchAdd"-->
                                            <!--                                            >-->
                                            <!--                                                OAT升级-->
                                            <!--                                            </el-button>-->

                                            <!--                                            <el-button-->
                                            <!--                                                    size="mini"-->
                                            <!--                                                    type="primary"-->
                                            <!--                                                    @click="batchAdd"-->
                                            <!--                                            >-->
                                            <!--                                                机柜日志-->
                                            <!--                                            </el-button>-->

                                            <!--                                            <el-button-->
                                            <!--                                                    size="mini"-->
                                            <!--                                                    type="primary"-->
                                            <!--                                                    @click="batchAdd"-->
                                            <!--                                            >-->
                                            <!--                                                链接情况-->
                                            <!--                                            </el-button>-->


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div v-else class="longitudinal" v-loading="dialog.dialog4.loading">

                            <div class="dl_main">


                                <ul class="dl_list">

                                    <li  :key="n" v-for="n in 24">
                                        <a href="#" @click="openDialog6(n)" title="点击弹出充电宝"
                                           v-if="dialog.dialog4.dialogVisible && dialog.dialog4.details.stock_detail.info[n]">
                                            <div >
                                                <div class="progress"  v-if="dialog.dialog4.details.stock_detail.info[n].power == 4">
                                                    <div role="progressbar"
                                                         class=" progress-bar-striped progress-bar progress-bar-80-100 progress-bar-animated"
                                                         style=" height: 100%; width: 100%;" aria-valuenow=" 80~100 "> 电 量
                                                        80%~100%
                                                    </div>
                                                </div>
                                                <div class="progress" v-else-if="dialog.dialog4.details.stock_detail.info[n].power == 3">
                                                    <div role="progressbar"
                                                         class=" progress-bar-striped progress-bar progress-bar-60-80 progress-bar-animated"
                                                         style=" height: 80%; width: 80%;" aria-valuenow=" 60~80 "> 电 量
                                                        60%~80%
                                                    </div>
                                                </div>
                                                <div class="progress" v-else-if="dialog.dialog4.details.stock_detail.info[n].power == 2">
                                                    <div role="progressbar"
                                                         class=" progress-bar-striped progress-bar progress-bar-40-60 progress-bar-animated"
                                                         style=" height: 60%;width: 60%;" aria-valuenow=" 40~60 "> 电 量
                                                        40%~60%
                                                    </div>
                                                </div>
                                                <div class="progress" v-else-if="dialog.dialog4.details.stock_detail.info[n].power == 1">
                                                    <div role="progressbar"
                                                         class=" progress-bar-striped progress-bar progress-bar-20-40 progress-bar-animated"
                                                         style=" height: 40%;width: 40%;" aria-valuenow=" 20~40 "> 电 量
                                                        20%~40%
                                                    </div>
                                                </div>
                                                <div class="progress" v-else-if="dialog.dialog4.details.stock_detail.info[n].power == 0">
                                                    <div role="progressbar"

                                                         class=" progress-bar-striped progress-bar progress-bar-0-20 progress-bar-animated"
                                                         style=" height: 20%;width: 20%;" aria-valuenow="0~20 "> 电 量
                                                        0%~20%
                                                    </div>
                                                </div>

                                            </div>
                                        </a>
                                        <a v-else href="javascript:void(0)">
                                            <img src="https://new.test.baomiduo.com/statics/images/null.png?v1.0"
                                                 alt="">
                                        </a>
                                    </li>
                                </ul>



                                <div class="clearfix"></div>

                            </div>

                            <div style="width:400px;margin: auto;margin-top: 20px;">

                                <div style="margin-top:-20px;margin-left:10 0px;font-size: 17px;margin-bottom: 20px;"><a
                                        @click="openDialog5(dialog.dialog4.details)" href="#">{{dialog.dialog4.details.cabinet_id}}</a>
                                </div>


                                <div class="row">
                                    <div class="col-sm-12" >
                                        <el-button
                                                size="medium "
                                                type="primary"
                                                @click="toViewVersion"
                                        >
                                            查看版本
                                        </el-button>

                                        <!--                                            <el-button-->
                                        <!--                                                    size="mini"-->
                                        <!--                                                    type="primary"-->
                                        <!--                                                    @click="toViewVersion"-->
                                        <!--                                            >-->
                                        <!--                                                查看APN-->
                                        <!--                                            </el-button>-->

                                        <el-popconfirm
                                                title="确定要重新获取库存吗？"
                                                @confirm="refreshDetail"

                                        >
                                            <el-button style="margin-left: 10px"
                                                       type="primary"
                                                       size="medium "
                                                       slot="reference">重新获取库存
                                            </el-button>
                                        </el-popconfirm>

                                        <el-popconfirm

                                                placement="top"
                                                title="确定要重启机柜吗？"
                                                @confirm="restart"

                                        >
                                            <el-button style="margin-left: 10px"
                                                       type="primary"
                                                       size="medium "
                                                       slot="reference">重启机柜
                                            </el-button>
                                        </el-popconfirm>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 10px;">
                                    <div class="col-sm-12" style="width:600px;">

                                        <el-button
                                                size="medium "
                                                type="primary"
                                                @click="openDialog8"
                                        >
                                            更新配置
                                        </el-button>

                                        <!--                                            <el-button-->
                                        <!--                                                    size="mini"-->
                                        <!--                                                    type="primary"-->
                                        <!--                                                    @click="batchAdd"-->
                                        <!--                                            >-->
                                        <!--                                                设置APN-->
                                        <!--                                            </el-button>-->

                                        <el-popover
                                                v-model="dialog.dialog4.popover"
                                                placement="top"
                                                width="300"
                                                trigger="click">

                                            <el-form style="padding-top:30px;padding-left:10px;padding-right: 20px;"
                                                     label-position="left" label-width="50px">

                                                <el-form-item label="锁ID">

                                                    <el-input style="width: 100%;"
                                                              v-model="dialog.dialog4.lockId"></el-input>

                                                    <div STYLE="color: red;">PS:从左往右数，从1开始。</div>

                                                </el-form-item>

                                                <el-form-item style="text-align: right;">

                                                    <el-popconfirm

                                                            placement="top"
                                                            title="确定要打开吗？"
                                                            @confirm="openLock2(dialog.dialog4.lockId)"

                                                    >
                                                        <el-button style="margin-left: 10px"
                                                                   type="primary"
                                                                   size="medium"
                                                                   slot="reference">打开
                                                        </el-button>
                                                    </el-popconfirm>

                                                    <el-button style="margin-left: 10px"
                                                               size="medium"
                                                               @click="dialog.dialog4.popover = false;"
                                                    >关闭
                                                    </el-button>

                                                </el-form-item>

                                            </el-form>


                                            <el-button
                                                    style="margin-left: 10px;"
                                                    type="primary"
                                                    size="medium "
                                                    @click="dialog.dialog4.lockId = '';"
                                                    slot="reference">打开指定锁
                                            </el-button>
                                        </el-popover>

                                        <el-popconfirm
                                                title="确定要打开全部电子锁吗？"
                                                @confirm="openAllLock"

                                        >
                                            <el-button style="margin-left: 10px"
                                                       type="primary"
                                                       size="medium"
                                                       slot="reference">打开全部锁
                                            </el-button>
                                        </el-popconfirm>

                                    </div>
                                </div>
                                <div class="row" style="margin-top: 10px;">
                                    <div class="col-sm-12" style="margin-left:300px;width:600px;">

                                        <!--                                            <el-button-->
                                        <!--                                                    size="mini"-->
                                        <!--                                                    type="primary"-->
                                        <!--                                                    @click="batchAdd"-->
                                        <!--                                            >-->
                                        <!--                                                查看配置-->
                                        <!--                                            </el-button>-->

                                        <!--                                            <el-button-->
                                        <!--                                                    size="mini"-->
                                        <!--                                                    type="primary"-->
                                        <!--                                                    @click="batchAdd"-->
                                        <!--                                            >-->
                                        <!--                                                OAT升级-->
                                        <!--                                            </el-button>-->

                                        <!--                                            <el-button-->
                                        <!--                                                    size="mini"-->
                                        <!--                                                    type="primary"-->
                                        <!--                                                    @click="batchAdd"-->
                                        <!--                                            >-->
                                        <!--                                                机柜日志-->
                                        <!--                                            </el-button>-->

                                        <!--                                            <el-button-->
                                        <!--                                                    size="mini"-->
                                        <!--                                                    type="primary"-->
                                        <!--                                                    @click="batchAdd"-->
                                        <!--                                            >-->
                                        <!--                                                链接情况-->
                                        <!--                                            </el-button>-->


                                    </div>
                                </div>
                            </div>


                        </div>


                    </el-col>
                </el-row>


            </div>


        </el-dialog>

        <el-dialog
                append-to-body
                top="25vh"
                title="二维码"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog5.dialogVisible"
                width="30%"
        >

            <el-row :gutter="20">

                <el-col :span="12" style="text-align: center;">

                    <div>公众号二维码</div>

                    <el-image style="width: 80%" :preview-src-list="[dialog.dialog5.qrcode1, dialog.dialog5.qrcode2]"
                              :src="dialog.dialog5.qrcode1">
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>

                    <img src=""/>

                </el-col>

                <el-col :span="12" style="text-align: center;">

                    <div>H5二维码</div>

                    <el-image style="width: 80%" :preview-src-list="[dialog.dialog5.qrcode1, dialog.dialog5.qrcode2]"
                              :src="dialog.dialog5.qrcode2">
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>

                </el-col>

            </el-row>

            <span slot="footer" class="dialog-footer">

                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialog.dialog5.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <el-dialog
                append-to-body
                top="20vh"
                title="充电宝详情"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog6.dialogVisible"
                width="30%"
        >

            <el-form label-position="left" style="margin-right: 10px;">
                <el-form-item label="充电宝ID" label-width="80px">
                    <el-input disabled v-model="dialog.dialog6.details.batteryid"></el-input>
                </el-form-item>
                <el-form-item label="电量" label-width="80px">
                    <el-input disabled v-if="dialog.dialog6.details.power == 4" value="80%~100%"></el-input>
                    <el-input disabled v-else-if="dialog.dialog6.details.power == 3" value="60%~80%"></el-input>
                    <el-input disabled v-else-if="dialog.dialog6.details.power == 2" value="40%~60%"></el-input>
                    <el-input disabled v-else-if="dialog.dialog6.details.power == 1" value="20%~40%"></el-input>
                    <el-input disabled v-else-if="dialog.dialog6.details.power == 0" value="0%~20%"></el-input>
                    <el-input disabled v-else>未知</el-input>
                </el-form-item>
                <el-form-item label="锁位" label-width="80px">
                    <el-input disabled :value="dialog.dialog6.details.lockIndex"></el-input>

                </el-form-item>
            </el-form>


            <span slot="footer" class="dialog-footer">

                    <el-popconfirm
                            title="确认要弹出充电宝吗？"
                            @confirm="openLock(dialog.dialog6.details.lockIndex)"

                    >
                        <el-button style="margin-left: 10px"
                                   type="danger"
                                   slot="reference"><i style="margin-right: 5px;" class="el-icon-error"/>弹出
                        </el-button>
                    </el-popconfirm>

                <el-button style="margin-left: 10px" type=""
                           @click="dialog.dialog6.dialogVisible = false">关闭</el-button>


            </span>

        </el-dialog>

        <el-dialog
                append-to-body
                top="20vh"
                title="固件版本"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog7.dialogVisible"
                width="30%"
        >

            <el-form label-position="left" style="margin-right: 10px;">
                版本: {{dialog.dialog7.details.version}}
            </el-form>


            <span slot="footer" class="dialog-footer">

                <el-button style="margin-left: 10px" type=""
                           @click="dialog.dialog7.dialogVisible = false">关闭</el-button>


            </span>

        </el-dialog>

        <el-dialog
                append-to-body
                top="20vh"
                title="更新配置"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog8.dialogVisible"
                width="30%"
        >

            <el-form :model="dialog.dialog8.form" :rules="dialog.dialog8.validateRules" ref="dialog8Form"
                     label-position="left" label-width="100px">

                <el-form-item label="服务器地址:" prop="addr">
                    <el-input maxlength="23" clearable show-word-limit v-model="dialog.dialog8.form.addr"></el-input>
                </el-form-item>

                <el-form-item label="端口:" prop="port">
                    <el-input maxlength="6" clearable show-word-limit
                              v-model.number="dialog.dialog8.form.port"></el-input>
                </el-form-item>

                <el-form-item label="心跳间隔:" prop="heartbeat">
                    <el-input maxlength="3" clearable show-word-limit
                              v-model.number="dialog.dialog8.form.heartbeat"></el-input>
                    <div style="color: red">PS：单位为S，尽量不要超过30S</div>
                </el-form-item>

            </el-form>


            <span slot="footer" class="dialog-footer">

                <el-popconfirm
                        top
                        icon="el-icon-info"
                        @confirm="setConfig"
                        title="请核对信息是否正确，信息错误会导致严重后果">


                      <el-button slot="reference" style="margin-left: 10px" type="primary">更新</el-button>

                </el-popconfirm>

                <el-button style="margin-left: 10px" type=""
                           @click="dialog.dialog8.dialogVisible = false">关闭</el-button>


            </span>

        </el-dialog>

        <el-table
                size="small"
                v-loading="table.loading"
                :data="table.list"
        >


            <el-table-column
                    label="机柜号"
                    prop="cabinet_id"
                    width="150"
            >
            </el-table-column>

            <el-table-column
                    label="ICCID"
                    width="200"
                    prop="networking_card"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.networking_card">{{ scope.row.networking_card }}</span>
                    <span v-else>未知</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="插槽数"
                    prop="devicenum"
            >
            </el-table-column>

            <el-table-column
                    label="IP"
                    width="100"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.client_ip">{{ scope.row.client_ip }}</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="入库时间"
                    width="200"
            >
                <template slot-scope="scope">
                    <i class="el-icon-time"></i><span>{{scope.row.create_time}}</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="所属代理"
                    width="150"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.agent">{{ scope.row.agent.agent_name }}</span>
                    <span v-else>未知</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="所属商家"
                    width="200"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.merchants">{{ scope.row.merchants.name }}</span>
                    <span v-else>未知</span>
                </template>
            </el-table-column>


            <!--            <el-table-column-->
            <!--                    label="状态"-->
            <!--            >-->
            <!--                <template slot-scope="scope">-->
            <!--                    <span v-if="scope.row.status == 0">损坏</span>-->
            <!--                    <span v-else-if="scope.row.status == 1">正常</span>-->
            <!--                    <span v-else-if="scope.row.status == 2">测试</span>-->
            <!--                    <span v-else-if="scope.row.status == 3">异常</span>-->
            <!--                    <span v-else>未知</span>-->
            <!--                </template>-->
            <!--            </el-table-column>-->


            <el-table-column
                    fixed="right"
                    label="模式"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.mode == 1">运营</span>
                    <span style="color: red;" v-else-if="scope.row.mode == 2">调试</span>
                    <span v-else>未知</span>
                </template>
            </el-table-column>

            <el-table-column
                    fixed="right"
                    label="状态"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.online == 1">
                         <el-tag type="success" effect="dark">在线</el-tag>
                    </span>
                    <span v-else>
                        <el-tag type="info" effect="dark">离线</el-tag>
                    </span>
                </template>
            </el-table-column>


            <el-table-column
                    v-if="permissions['api/op.qrcode'] && permissions['api/op.qrcode']['GET']"
                    fixed="right"
                    label="二维码"
            >
                <template slot-scope="scope">
                    <div><i style="margin-right: 2px;" class="el-icon-zoom-in"></i><a
                            style="color: red; text-decoration: none;" href="#" @click="openDialog5(scope.row)">生成</a>
                    </div>
                </template>
            </el-table-column>


            <el-table-column fixed="right" label="操作" width="320">
                <template slot-scope="scope">

                    <el-button
                            v-if="permissions['api/op.instructionEx'] && permissions['api/op.instructionEx']['POST']"
                            size="mini"
                            @click="openDialog4(scope.row)">
                        <i style="margin-right: 2px;" class="el-icon-view"></i>设备详情
                    </el-button>

                    <el-button
                            v-if="permissions['api/op.charecabinet'] && permissions['api/op.charecabinet']['PUT']"
                            size="mini"
                            type="primary"
                            @click="openDialog1(scope.row)">
                        <i style="margin-right: 2px;"
                           class="el-icon-edit"></i>修改
                    </el-button>

                    <el-popconfirm
                            v-if="permissions['api/op.charecabinet'] && permissions['api/op.charecabinet']['DELETE']"
                            title="确定要删除吗？"
                            @confirm="deletes(scope.$index, scope.row)"

                    >
                        <el-button style="margin-left: 10px" size="mini"
                                   type="danger"
                                   slot="reference"><i style="margin-right: 5px;" class="el-icon-error"/>删除
                        </el-button>
                    </el-popconfirm>

                </template>
            </el-table-column>
        </el-table>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getData"
                    :current-page="table.currentPage"
                    :page-size="table.pageSize"
                    :total="table.total">
            </el-pagination>
        </div>


    </div>

</template>

<script>

    let Base64 = require('js-base64').Base64

    import Vue from 'vue';

    import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

    // Install BootstrapVue
    Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
    Vue.use(IconsPlugin)

    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'

    import RentOrder from '../order/Order'

    export default {
        name: "Chargingtank",
        props: [
            'merchantsId',
        ],
        data() {
            return {
                search: {
                    agentId: 0,
                    charecabinetId: '',
                    iccId: '',
                    merchantsName: '',
                },
                uploadHeader: {
                    token: ''
                },
                dialog: {
                    dialog1: {
                        dialogVisible: false,
                        device_id_int: 0,
                        merchantsList: [],
                        form: {
                            true: true,
                            cabinet_id: '',
                            device_model: 1,
                            device_mode: 1,
                            device_number: null,
                            merchants_id: 0,
                            agent_id: 0,
                        },
                        validateRules: {
                            cabinet_id: [
                                {required: true, message: '请输入机柜号', trigger: 'blur'},
                            ],
                            device_model: [
                                {required: true, message: '请输入机柜型号', trigger: 'blur'},
                            ],
                            device_mode: [
                                {required: true, message: '请选择运行模式', trigger: 'blur'},
                            ],
                            device_number: [
                                {required: true, message: '请输入插槽数', trigger: 'blur'},
                                {type: 'number', message: '请输入数字', trigger: 'blur'},
                            ],
                        },
                    },
                    dialog2: {
                        dialogVisible: false,
                        agentList: [],
                        merchantsList: [],
                        form: {
                            mode: 'one',
                            cabinet_id: '',
                            device_model: '',
                            device_number: null,
                            merchants_id: 0,
                            agent_id: 0,
                        },
                        validateRules: {
                            cabinet_id: [
                                {required: true, message: '请输入机柜号', trigger: 'blur'},
                            ],
                            device_model: [
                                {required: true, message: '请输入机柜型号', trigger: 'blur'},
                            ],
                            device_number: [
                                {required: true, message: '请输入插槽数', trigger: 'blur'},
                                {type: 'number', message: '请输入数字', trigger: 'blur'},
                            ],
                        },
                    },
                    dialog3: {
                        dialogVisible: false,
                        fileList: [],
                        form: {
                            mode: 'multiple',
                            file_name: '',
                            agent_id: 0
                        },
                        validateRules: {
                            cabinet_id: [
                                {required: true, message: '请输入机柜号', trigger: 'blur'},
                            ],
                            file_name: [
                                {required: true, message: '请上传Excel文件', trigger: 'blur'},
                            ]
                        },
                    },
                    dialog4: {
                        popover: false,
                        loading: false,
                        dialogVisible: false,
                        details: {},
                        device_id: '',
                        lockId: '',
                        array1: [
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                        ],
                    },
                    dialog5: {
                        dialogVisible: false,
                        qrcode1: '',
                        qrcode2: '',
                    },
                    dialog6: {
                        dialogVisible: false,
                        details: {
                            batteryid: '',
                            power: '',
                            lockIndex: 0,
                        },
                    },
                    dialog7: {
                        dialogVisible: false,
                        details: {
                            version: ''
                        },
                    },
                    dialog8: {
                        dialogVisible: false,
                        visible: false,
                        details: {},
                        form: {
                            addr: '',
                            port: '',
                            heartbeat: '',
                        },
                        validateRules: {
                            addr: [
                                {required: true, message: '请输入服务器地址', trigger: 'blur'},
                            ],
                            port: [
                                {required: true, message: '请输入端口号', trigger: 'blur'},
                                {type: 'number', message: '请输入正确的端口号', trigger: 'blur'},
                            ],
                            heartbeat: [
                                {required: true, message: '请输入心跳间隔', trigger: 'blur'},
                                {type: 'number', message: '请输入正确的心跳间隔', trigger: 'blur'}
                            ]
                        },
                    }
                },
                table: {
                    list: [],
                    currentPage: 1,
                    total: 0,
                    pageSize: 20,
                    loading: true,
                    activeName: '1',
                    details: {},
                    form: {
                        note: ''
                    },
                },
                merchantsList: [],
                allMerchantsList: [],
                agentList: [],
                allAgentList: [],
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted() {
            this.setUploadHeader();
            this.getData();
            this.getAgentData();
            this.getMerchantsData();
        },
        methods: {
            setUploadHeader() {
                let user = JSON.parse(localStorage.getItem('user'));
                this.uploadHeader.token = user != undefined ? user.token : '';
            },
            //加载代理商数据
            getAgentData() {

                const _this = this;

                _this.axios.get('api/op/agent', {
                    params: {},
                    headers: {'page': 1, "page-size": 9999999},
                }).then(function (response) {
                        if (response.status == 200 && response.data.status != undefined && response.data.status == 1) {
                            _this.allAgentList = response.data.data.list;
                            response.data.data.list.forEach(function (e) {
                                if (e.type == 1) {
                                    _this.agentList.push(e);
                                }
                            })
                        }
                    }
                ).catch(function () {

                });

            },
            getMerchantsData() {

                const _this = this;

                _this.axios.get('api/op/merchants', {
                    params: {},
                    headers: {'page': 1, "page-size": 9999999},
                }).then(function (response) {
                        if (response.status == 200 && response.data.status != undefined && response.data.status == 1) {
                            _this.allMerchantsList = response.data.data.list;
                        }
                    }
                ).catch(function () {

                });

            },
            getData(page) {
                const _this = this;

                if (page != undefined) {
                    _this.table.currentPage = page;
                }

                _this.table.loading = true;
                _this.axios.get('api/op/charecabinet', {
                    headers: {
                        'page': _this.table.currentPage,
                        "page-size": _this.table.pageSize,
                        "agent-id": _this.search.agentId,
                        'merchants-name': Base64.toBase64(_this.search.merchantsName),
                        "charecabinet-id": Base64.toBase64(_this.search.charecabinetId),
                        "icc-id": Base64.toBase64(_this.search.iccId),
                        "merchants-id": _this.merchantsId,
                    }
                }).then(function (response) {
                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.table.list = response.data.data.list;
                            _this.table.total = response.data.data.total;
                            _this.table.loading = false;
                        }
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }
                });
            },
            // 搜索
            handleSearch() {

                const _this = this;
                _this.getData(1);

            },
            // 删除
            deletes(index, row) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/charecabinet/' + row.id, '').then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                        _this.getData();

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 编辑
            update() {

                const _this = this;

                _this.$refs['dialog1Form'].validate((valid) => {
                    if (valid) {

                        const loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });

                        _this.axios.put('/api/op/charecabinet/' + _this.dialog.dialog1.device_id_int, _this.dialog.dialog1.form).then(function (response) {

                            loading.close();

                            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                            if (response.data.status != undefined && response.data.status == 1) {

                                _this.$notify({
                                    title: '成功',
                                    message: message,
                                    type: 'success'
                                });

                                _this.dialog.dialog1.dialogVisible = false;
                                _this.getData();

                            } else {
                                _this.$notify.error({
                                    title: '错误',
                                    message: message
                                });
                            }

                        }).catch(function (error) {

                            console.log(error)

                            loading.close();
                            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                            _this.$notify.error({
                                title: '错误',
                                message: msg
                            });

                        })


                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: '请按照要求填写信息'
                        });
                    }
                });

            },
            // 新增
            add() {

                const _this = this;

                _this.$refs['dialog2Form'].validate((valid) => {
                    if (valid) {

                        const loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });

                        _this.axios.post('/api/op/charecabinet', _this.dialog.dialog2.form).then(function (response) {

                            loading.close();

                            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                            if (response.data.status != undefined && response.data.status == 1) {

                                _this.$notify({
                                    title: '成功',
                                    message: message,
                                    type: 'success'
                                });

                                _this.dialog.dialog2.dialogVisible = false;
                                _this.getData();

                            } else {
                                _this.$notify.error({
                                    title: '错误',
                                    message: message
                                });
                            }

                        }).catch(function (error) {

                            console.log(error)

                            loading.close();
                            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                            _this.$notify.error({
                                title: '错误',
                                message: msg
                            });

                        })

                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: '请按照要求填写信息'
                        });
                    }
                });


            },
            // 新增-批量
            batchAdd() {

                const _this = this;

                _this.$refs['dialog3Form'].validate((valid) => {
                    if (valid) {

                        const loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });

                        _this.axios.post('/api/op/charecabinet', _this.dialog.dialog3.form).then(function (response) {

                            loading.close();

                            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                            if (response.data.status != undefined && response.data.status == 1) {

                                _this.$notify({
                                    title: '成功',
                                    message: message,
                                    type: 'success'
                                });

                                _this.dialog.dialog3.dialogVisible = false;
                                _this.getData();

                            } else {

                                _this.$notify.error({
                                    title: '错误',
                                    message: message
                                });


                            }

                        }).catch(function (error) {

                            console.log(error)

                            loading.close();
                            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                            _this.$notify.error({
                                title: '错误',
                                message: msg
                            });

                        })


                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: '请按照要求填写信息'
                        });
                    }
                });

            },
            // 上传文件成功钩子
            handeUploadFile1(response, file, list) {
                const _this = this;
                if (response.status != undefined && response.status == 1) {
                    //啥也不干 ：P 是不可能的
                    _this.dialog.dialog3.form.file_name = response.data.file_name;
                } else {
                    let message = response.message == undefined ? '上传失败' : response.message;
                    _this.$message.error(message);
                    list.splice(list.length - 1, 1);
                }
            },
            // 移除文件钩子
            handleRemoveFile1(file) {
                const _this = this;
                _this.dialog.dialog3.form.file_name = '';
                console.log(file);
            },
            // 打开修改机柜谈层
            openDialog1(row) {

                const _this = this;

                _this.dialog.dialog1.agentList = this.agentList;
                _this.merchantsList = [];

                if (row.aid != 0) {
                    _this.allMerchantsList.forEach(function (e) {
                        if (e.agency == row.aid) {
                            _this.merchantsList.push(e);
                        }
                    })
                }

                _this.dialog.dialog1.device_id_int = row.id;
                _this.dialog.dialog1.form.device_model = row.model;
                _this.dialog.dialog1.form.cabinet_id = row.cabinet_id;
                _this.dialog.dialog1.form.device_number = row.devicenum;
                _this.dialog.dialog1.form.device_mode = row.mode;
                _this.dialog.dialog1.form.merchants_id = row.sid;
                _this.dialog.dialog1.form.agent_id = row.aid;
                _this.dialog.dialog1.merchantsList = [];
                _this.dialog.dialog1.dialogVisible = true;
            },
            // 打开新增机柜谈层
            openDialog2() {
                const _this = this;
                _this.dialog.dialog2.form.device_model = '';
                _this.dialog.dialog2.form.cabinet_id = '';
                _this.dialog.dialog2.form.device_number = '';
                _this.dialog.dialog2.form.merchants_id = 0;
                _this.dialog.dialog2.form.agent_id = 0;
                _this.dialog.dialog2.dialogVisible = true;
            },
            // 打开导入机柜谈层
            openDialog3() {
                const _this = this;
                _this.dialog.dialog3.form.agent_id = 0;
                _this.dialog.dialog3.form.file_name = '';
                _this.dialog.dialog3.dialogVisible = true;
                if (_this.$refs['uploadFile1'] != undefined) {
                    _this.$refs['uploadFile1'].clearFiles();
                }
            },
            // 打开设备详情弹层
            openDialog4(row) {
                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.get('/api/op/charecabinet/' + row.id).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.dialog.dialog4.dialogVisible = true;
                        _this.dialog.dialog4.details = response.data.data;

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 打开充电宝详情弹层
            openDialog6(index) {

                const _this = this;
                _this.dialog.dialog6.details = _this.dialog.dialog4.details.stock_detail.info[index];
                _this.dialog.dialog6.details.lockIndex = index;
                _this.dialog.dialog6.dialogVisible = true;

            },
            // 打开充电宝详情弹层
            openDialog8() {

                const _this = this;
                _this.dialog.dialog8.form.addr = '';
                _this.dialog.dialog8.form.port = '';
                _this.dialog.dialog8.form.heartbeat = '';
                _this.dialog.dialog8.dialogVisible = true;

            },
            // 新增单个代理商谈层，代理商ID被选中
            addOneAgentSelectChange(agentId) {

                const _this = this;
                _this.merchantsList = [];
                _this.dialog.dialog2.form.merchants_id = 0;

                if (agentId == 0) {
                    return false;
                }

                _this.allMerchantsList.forEach(function (e) {
                    if (e.agency == agentId) {
                        _this.merchantsList.push(e);
                    }
                })

            },
            // 编辑单个代理商谈层，代理商ID被选中
            editAgentSelectChange(agentId) {

                const _this = this;
                _this.merchantsList = [];
                _this.dialog.dialog1.form.merchants_id = 0;

                if (agentId == 0) {
                    return false;
                }

                _this.allMerchantsList.forEach(function (e) {
                    if (e.agency == agentId) {
                        _this.merchantsList.push(e);
                    }
                })

            },
            // 生成二维码
            openDialog5(data) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.get('/api/op/qrcode/' + data.id).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.dialog.dialog5.dialogVisible = true;
                        _this.dialog.dialog5.qrcode1 = response.data.data.qrcode1;
                        _this.dialog.dialog5.qrcode2 = response.data.data.qrcode2;

                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });


                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })


            },
            // 打开指定电子锁
            openLock(lockId) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });


                _this.axios.post('/api/op/instructionEx', {
                    cabinet_id: _this.dialog.dialog4.details.cabinet_id,
                    instruction_code: '250',
                    lock_id: lockId,
                }).then(function (response) {

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    loading.close();

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.dialog.dialog6.dialogVisible = false;

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                        _this.dialog.dialog4.loading = true;

                        setTimeout(function () {

                            // _this.dialog.dialog4.details.stock_detail.info = [];

                            _this.axios.get('/api/op/charecabinet/' + _this.dialog.dialog4.details.id).then(function (response) {

                                _this.dialog.dialog4.loading = false;

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {


                                    _this.dialog.dialog4.details.stock_detail.info = response.data.data.stock_detail.info;

                                    // console.log(response.data)

                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                _this.dialog.dialog4.details.stock_detail.info = [];

                                _this.dialog.dialog4.loading = false;

                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })


                        }, 500)

                        // _this.dialog.dialog7.details.version = response.data.data.versions;

                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });


                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();

                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 打开指定电子锁,加强版
            openLock2(lockId) {

                const _this = this;

                if (!lockId) {
                    _this.$notify.error({
                        title: '错误',
                        message: '请填写锁ID'
                    });
                    return false;
                }

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.post('/api/op/instructionEx', {
                    cabinet_id: _this.dialog.dialog4.details.cabinet_id,
                    instruction_code: '252',
                    lock_id: lockId,
                }).then(function (response) {

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    loading.close();

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.dialog.dialog4.popover = false;

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                        _this.dialog.dialog4.loading = true;

                        setTimeout(function () {

                            // _this.dialog.dialog4.details.stock_detail.info = [];

                            _this.axios.get('/api/op/charecabinet/' + _this.dialog.dialog4.details.id).then(function (response) {

                                _this.dialog.dialog4.loading = false;

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {


                                    _this.dialog.dialog4.details.stock_detail.info = response.data.data.stock_detail.info;

                                    // console.log(response.data)

                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                _this.dialog.dialog4.details.stock_detail.info = [];

                                _this.dialog.dialog4.loading = false;

                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })


                        }, 500)

                        // _this.dialog.dialog7.details.version = response.data.data.versions;

                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });


                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();

                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 打开全部电子锁
            openAllLock() {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });


                _this.axios.post('/api/op/instructionEx', {
                    cabinet_id: _this.dialog.dialog4.details.cabinet_id,
                    instruction_code: '251'
                }).then(function (response) {

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    loading.close();

                    if (response.data.status != undefined && response.data.status == 1) {


                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });

                    }

                    _this.dialog.dialog4.loading = true;

                    setTimeout(function () {

                        // _this.dialog.dialog4.details.stock_detail.info = [];

                        _this.axios.get('/api/op/charecabinet/' + _this.dialog.dialog4.details.id).then(function (response) {

                            _this.dialog.dialog4.loading = false;

                            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                            if (response.data.status != undefined && response.data.status == 1) {


                                _this.dialog.dialog4.details.stock_detail.info = response.data.data.stock_detail.info;

                                // console.log(response.data)

                            } else {
                                _this.$notify.error({
                                    title: '错误',
                                    message: message
                                });
                            }

                        }).catch(function (error) {

                            _this.dialog.dialog4.details.stock_detail.info = [];

                            _this.dialog.dialog4.loading = false;

                            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                            _this.$notify.error({
                                title: '错误',
                                message: msg
                            });

                        })


                    }, 500)

                }).catch(function (error) {

                    console.log(error)

                    loading.close();

                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 刷新库存
            refreshDetail() {

                const _this = this;

                _this.dialog.dialog4.loading = true;

                _this.axios.get('/api/op/charecabinet/' + _this.dialog.dialog4.details.id).then(function (response) {

                    _this.dialog.dialog4.loading = false;

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {


                        _this.dialog.dialog4.details.stock_detail.info = response.data.data.stock_detail.info;

                        // console.log(response.data)

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    _this.dialog.dialog4.details.stock_detail.info = [];

                    _this.dialog.dialog4.loading = false;

                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
            },
            // 重启机柜
            restart() {

                const _this = this;

                _this.dialog.dialog4.loading = true;

                _this.axios.post('/api/op/instructionEx', {
                    cabinet_id: _this.dialog.dialog4.details.cabinet_id,
                    instruction_code: '203',
                }).then(function (response) {

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    _this.dialog.dialog4.details.stock_detail.info = [];
                    _this.dialog.dialog4.loading = false;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });


                    }

                }).catch(function (error) {

                    console.log(error)

                    _this.dialog.dialog4.loading = false;

                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 查看版本
            toViewVersion() {

                const _this = this;

                _this.dialog.dialog4.loading = true;

                _this.axios.post('/api/op/instructionEx', {
                    cabinet_id: _this.dialog.dialog4.details.cabinet_id,
                    instruction_code: '114',
                }).then(function (response) {

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    _this.dialog.dialog4.loading = false;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.dialog.dialog7.dialogVisible = true;
                        _this.dialog.dialog7.details.version = response.data.data.versions;

                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });


                    }

                }).catch(function (error) {

                    console.log(error)

                    _this.dialog.dialog4.loading = false;

                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            setConfig() {

                const _this = this;

                _this.$refs['dialog8Form'].validate((valid) => {
                    if (valid) {

                        const loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });

                        _this.axios.post('/api/op/instructionEx', {
                            addr: _this.dialog.dialog8.form.addr,
                            port: _this.dialog.dialog8.form.port,
                            heartbeat: _this.dialog.dialog8.form.heartbeat,
                            cabinet_id: _this.dialog.dialog4.details.cabinet_id,
                            instruction_code: '202'
                        }).then(function (response) {

                            loading.close();

                            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                            if (response.data.status != undefined && response.data.status == 1) {

                                _this.dialog.dialog8.dialogVisible = false;

                                _this.$notify({
                                    title: '成功',
                                    message: message,
                                    type: 'success'
                                });

                            } else {
                                _this.$notify.error({
                                    title: '错误',
                                    message: message
                                });
                            }

                        }).catch(function (error) {

                            loading.close();

                            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                            _this.$notify.error({
                                title: '错误',
                                message: msg
                            });

                        })


                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: '请按照要求填写信息'
                        });
                    }

                });
            }
        },
        components: {
            RentOrder
        }
    }
</script>

<style scoped>

    /deep/ .el-upload--text .el-upload-dragger {
        width: 100%;
    }

    /deep/ .el-upload--text {
        width: 100%;
    }

    .box /deep/ .el-dialog {
        margin: 5vh auto !important;
    }

    .box /deep/ .el-dialog__body {
        height: 60vh;
        overflow: auto;
    }





    .longitudinal .dl_list li {
        height: 45px;
        width: 221px;
        float: left;
        margin-right: 51px;
        margin-bottom: 10px;
    }

    .longitudinal .dl_list li img {
        height: 45px;
        width: 221px;

    }


    .longitudinal .dl_list {
        overflow: hidden;
        margin: auto;
        width: 550px;

        list-style-type: none;
    }

    .longitudinal .dl_list .progress {
        width: 221px;
        height: 45px;
    }


    .transverse {
        width: 990px;
        height: 542px;
        background: url('https://new.test.baomiduo.com/statics/images/cdg.png?v1.1') 0% 0% / 100% 100% no-repeat;
        margin: 100px auto 0px;
        background-repeat: no-repeat;
    }

    .transverse .dl_list {
        position: relative;
        top: 143px;
        margin-left: 104px;

        list-style-type: none;
    }

    .transverse .dl_list li {
        width: 45px;
        height: 221px;
        float: left;
        margin-right: 51px;
    }

    .transverse .dl_list li img {
        width: 45px;
        height: 221px;
    }

    .transverse .dl_list li div {
        width: 45px;
        height: 221px;
        line-height: 149px;
    }

    .transverse .progress-bar {
        position: absolute;
        border-radius: 4px;
        bottom: 0;
        color: #333;
        writing-mode: tb-rl;
    }


    .progress-bar-0-20 {
        background-color: #ed3745;
    }

    .progress-bar-20-40 {
        background-color: #f9d023;
    }

    .progress-bar-40-60 {
        background-color: #d9e021;
    }

    .progress-bar-60-80 {
        background-color: #b8db23;
    }

    .progress-bar-80-100 {
        background-color: #97dd45 !important;
    }

    .dl_list a {
        text-decoration: none;
    }


</style>