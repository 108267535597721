<template>
    <div>

        <template>

            <el-button  v-if="permissions['api/op.userGroup'] && permissions['api/op.userGroup']['POST']"  style="margin-bottom: 10px;" type="primary" @click="openDialog('新增用户组', 'insert')">添加
            </el-button>

            <el-dialog

                    :title="dialogTitle"
                    :visible.sync="dialogVisible"
                    width="25%"
            >

                <el-form :rules="validateRules" ref="form" :model="form" label-width="100px">
                    <el-form-item label="用户组名称" ref="form" prop="user_group_name">
                        <el-input v-model="form.user_group_name" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="描述" ref="form" prop="user_group_description">
                        <el-input v-model="form.user_group_description" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch v-model="form.status"></el-switch>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                                <el-button @click="closeLayer">取 消</el-button>
                                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
                            </span>
            </el-dialog>

            <el-dialog

                    :title="setUpPermissionsDialogTitle"
                    :visible.sync="setUpPermissionsDialogVisible"
                    width="30%"
            >

                <div style="margin-bottom: 15px;margin-left: 10px;">
                    <el-button size="mini" @click="checkedAll">全选</el-button>
                    <el-button size="mini" @click="resetChecked">清空</el-button>
                </div>

                <el-tree
                        ref="tree"
                        node-key="id"
                        :data="permissionsList"
                        :props="defaultProps"
                        accordion
                        highlight-current
                        show-checkbox
                >
                </el-tree>

                <div style="margin-top: 35px; text-align: right;">
                    <el-button @click="setUpPermissionsDialogVisible = false;">取消</el-button>
                    <el-button type="primary" @click="submitSetUpPermissions">保存</el-button>
                </div>

            </el-dialog>

            <el-table v-loading="tabLoading"

                      :data="tableData"
                      style="width: 100%">
                <el-table-column style="line-height: 35px;"
                                 label="用户组"
                >
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <el-tag size="medium">{{ scope.row.user_group_name }}</el-tag>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column style="line-height: 35px;"
                                 label="描述"
                >
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <el-tag size="medium">{{ scope.row.user_group_description ? scope.row.user_group_description
                                : '暂无描述' }}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>


                <el-table-column
                        label="状态"
                >
                    <template slot-scope="scope">
                        <el-tag type="danger"
                                style="margin-right: 5px; margin-bottom: 5px;"><span>{{scope.row.status}}</span>
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        label="创建日期"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="325">>
                    <template slot-scope="scope">

                        <el-button
                                v-if="permissions['api/op.userGroup'] && permissions['api/op.userGroup']['PUT']"
                                size="mini"
                                @click="openDialog('编辑用户组', 'edit', scope.row)">编辑
                        </el-button>

                        <el-button
                                v-if="permissions['api/op.userGroup'] && permissions['api/op.userGroup']['PUT'] && permissions['api/op.permissions'] && permissions['api/op.permissions']['GET']"
                                size="mini"
                                type="primary"
                                @click="openSetUpPermissionsDialog(scope.row)"><span class="el-icon-s-tools"></span>
                            分配权限
                        </el-button>


                        <el-popconfirm
                                v-if="permissions['api/op.userGroup'] && permissions['api/op.userGroup']['PUT']"
                                :title="scope.row.status_value == '0' ? '确定要启用吗？' : '确定要禁用吗？'"
                                @confirm="handleChargeStatus(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="warning"
                                       slot="reference">{{scope.row.status_value == '0' ? '启用' : '禁用'}}
                            </el-button>
                        </el-popconfirm>


                        <el-popconfirm
                                v-if="permissions['api/op.userGroup'] && permissions['api/op.userGroup']['DELETE']"
                                title="确定要删除吗？"
                                @confirm="handleDelete(scope.$index, scope.row)"
                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="danger"
                                       slot="reference">删除
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getUserListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    export default {
        name: "UserGroup",
        data() {
            return {
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                tabLoading: true,
                form: {
                    action: 'insert',
                    user_group_name: '',
                    user_group_description: '',
                    status: true,
                    id: 0,
                },
                permissionsList: [],
                currentSelectedUserGroupId: 0,
                setUpPermissionsDialogTitle: '分配权限',
                setUpPermissionsDialogVisible: false,
                dialogTitle: '',
                dialogVisible: false,
                isShow: true,
                tableData: [],
                tableTotal: 0,
                currentPage: 1,
                pageSize: 10,
                //验证规则
                validateRules: {
                    user_group_name: [
                        {required: true, message: '请输入用户组名称', trigger: 'blur'},
                        {min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur'}
                    ],
                    user_group_description: [
                        {required: true, message: '请输入用户组描述', trigger: 'blur'},
                        {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                    ],
                },
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted: function () {

            this.getUserListData();

        },
        methods: {
            //加载表格数据
            getUserListData(page) {


                const _this = this;
                _this.tabLoading = true;
                _this.tableData = [];
                if (page != undefined) {
                    _this.currentPage = page;
                }

                _this.axios.get('api/op/userGroup', {
                    params: {},
                    headers: {'page': _this.currentPage, "page-size": _this.pageSize},
                }).then(function (response) {

                        _this.tabLoading = false;

                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableTotal = response.data.data.total;
                            for (let i in response.data.data.list) {
                                let item = response.data.data.list[i];
                                _this.tableData.push({
                                    id: item.id,
                                    user_group_name: item.user_group_name,
                                    user_group_description: item.user_group_description,
                                    permissions:item.permissions,
                                    balance: 99.99,
                                    deposit: 99.99,
                                    create_time: item.create_time,
                                    account: item.account,
                                    password_hash: item.password,
                                    status: item.status,
                                    status_value: item.status_value,
                                    address: '上海市普陀区金沙江路 1518 弄'
                                })
                            }

                        }


                    }
                ).catch(function (error) {

                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 打开权限设置弹出层
            openSetUpPermissionsDialog(rowData) {

                const _this = this;


                _this.permissionsList = [];
                _this.currentSelectedUserGroupId = rowData.id;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.get('api/op/permissions').then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.setUpPermissionsDialogVisible = true;
                        _this.permissionsList = response.data.data;
                        let tempIds = [];




                        rowData.permissions.forEach(function (e) {

                            let isInsert = true;
                            response.data.data.forEach(function (ee) {


                                if (ee.id == e.id) {
                                    isInsert = false;
                                }

                            });


                            if (isInsert){
                                tempIds.push(e.permissions_id);
                            }

                        })

                        setTimeout(function () {
                            _this.$refs.tree.setCheckedKeys(tempIds);
                        }, 10)


                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error);

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })


            },
            //选择所有的权限
            checkedAll() {
                const tree = this.$refs.tree;
                let tempArray = []
                this.permissionsList.forEach(function (e) {
                    tempArray.push(e.id)
                    tree.setCheckedKeys(tempArray);
                })
            },
            //清空已选的权限
            resetChecked() {
                this.$refs.tree.setCheckedKeys([]);
            },
            //提交设置权限
            submitSetUpPermissions() {

                const _this = this;
                _this.setUpPermissionsDialogVisible = false;
                _this.tabLoading = true;
                const currentSelectedUserGroupId = _this.currentSelectedUserGroupId;

                const ids = _this.$refs.tree.getCheckedKeys();

                _this.axios.put('api/op/userGroup/' + currentSelectedUserGroupId, {
                    type: 'permissions',
                    ids
                }).then(function (response) {

                    _this.tabLoading = false;

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.setUpPermissionsDialogVisible = false;
                        _this.permissionsList = response.data.data;

                        _this.$notify.success({
                            title: '成功',
                            message: message
                        });

                        _this.getUserListData();

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    _this.tabLoading = false;

                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })


            },
            openDialog(title, action, data = {}) {

                if (action != 'insert' && action != 'edit') {
                    return false;
                }

                let form = this.$refs['form'];
                if (form != undefined) {
                    setTimeout(function () {
                        form.clearValidate();
                    }, 1)
                }

                if (action == 'edit') {

                    this.form.user_group_name = data.user_group_name;
                    this.form.user_group_description = data.user_group_description;
                    this.form.status = Boolean(data.status_value);
                    this.form.id = data.id;

                } else if (action == 'insert') {

                    this.form.user_group_name = '';
                    this.form.user_group_description = '';
                    this.form.status = true;


                }

                this.form.action = action;
                this.dialogTitle = title;
                this.dialogVisible = true;

            },
            //关闭弹出层
            closeLayer() {
                this.dialogVisible = false;
                this.form.account = '';
                this.form.password = '';
                this.form.status = true;
            },
            //弹出层表单提交事件
            submitForm(formName) {
                const _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (_this.form.action == 'insert') {
                            _this.handleInsertUser();
                        } else {
                            _this.handeleEditUser();
                        }
                    } else {
                        return false;
                    }
                });
            },
            //新增用户
            handleInsertUser() {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                const requestData = {
                    user_group_name: _this.form.user_group_name,
                    user_group_description: _this.form.user_group_description,
                    status: _this.form.status,
                }

                _this.axios.post('/api/op/userGroup', requestData).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.closeLayer();
                        _this.getUserListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            //编辑用户
            handeleEditUser() {

                const _this = this;

                let requestData = {
                    type: 'info',
                    user_group_name: _this.form.user_group_name,
                    user_group_description: _this.form.user_group_description,
                    status: this.form.status
                };

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.put('/api/op/userGroup/' + _this.form.id, requestData).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.closeLayer();
                        _this.getUserListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
            },
            //改变用户组
            handleEditUserGroup(index, row) {
                console.log(index, row);
            },
            // 变更用户组状态
            handleChargeStatus(index, row) {

                let _this = this;
                let status = row.status_value == 1 ? 0 : 1;


                _this.tabLoading = true;

                _this.axios.put('/api/op/userGroup/' + row.id, {status, type: 'status'}).then(function (response) {

                    _this.tabLoading = false;

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.getUserListData();

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    _this.tabLoading = false;

                    const message = error.response.data.message == undefined ? "操作失败,请重试" : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: message
                    });

                });

            },
            //删除用户组
            handleDelete(index, row) {

                let _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/userGroup/' + row.id).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.getUserListData();

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error);

                    loading.close();

                    const message = error.response.data.message == undefined ? "操作失败,请重试" : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: message
                    });

                });

            }
        }
    }
</script>

<style scoped>

</style>