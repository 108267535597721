<template>
  <div>

<!--    <el-select v-model="batchId" size="small" style="position: relative;top:-25px;" placeholder="请选择">-->
<!--      <el-option-->
<!--          v-for="item in batchList"-->
<!--          :key="item.type"-->
<!--          :label="item.batch_name"-->
<!--          :value="item.id">-->
<!--      </el-option>-->
<!--    </el-select>-->
    <el-button type="primary" style="position: relative;top:-25px;margin-left: 10px;" size="mini" icon="el-icon-search"
               @click="handleSearch">刷新
    </el-button>

    <el-popover
        n v-if="permissions['api/op.couponsToken'] && permissions['api/op.couponsToken']['POST']"
        placement="right"
        ref="popover"
        trigger="click">
        <el-form ref="build" :rules="vaildateRules" :validate-on-rule-change="false" :model="form"
                 label-width="80px"
                 style="padding-right: 20px;padding-top: 30px;">
          <el-form-item  label="批次名称" prop="batch_name" >
            <el-input v-model="form.batch_name"></el-input>
          </el-form-item>
          <el-form-item label="生成数量" prop="number" >
            <el-input-number v-model="form.number"  :min="1" :max="999" ></el-input-number>
          </el-form-item>
          <el-form-item label="过期时间"  prop="expire">
            <el-date-picker
                v-model="form.expire"
                type="datetime"
                placeholder="请选择">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="" prop="expire_time">
            <el-button type="primary" icon="el-icon-edit" @click="buildToken()">确认生成兑换码</el-button>
          </el-form-item>

        </el-form>

      <el-button  slot="reference"  type="success" style="position: relative;top:-25px;margin-left: 10px;" size="mini" icon="el-icon-folder-add"
                >生成兑换码
      </el-button>
    </el-popover>



    <el-scrollbar style="height:50vh">
      <el-table
          size="small"
          v-loading="tabLoading"
          :data="tableData"
          style="width: 100%"
      >

        <el-table-column
            label="兑换码ID"
        >
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>

        <el-table-column
            label="批次名称"
        >
          <template slot-scope="scope">
            {{ scope.row.batch_name }}
          </template>
        </el-table-column>

        <el-table-column
            label="兑换码"
        >
          <template slot-scope="scope">
            {{ scope.row.token }}
          </template>
        </el-table-column>

        <el-table-column
            label="状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">未使用</span>
            <span v-else-if="scope.row.status == 1">已使用</span>
            <span v-else-if="scope.row.status == 2">过期</span>
            <span v-else-if="scope.row.status == 3">管理员设为失效</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>

        <el-table-column
            label="时间信息"
            width="250"
        >
          <template slot-scope="scope">
            <div>
              <i class="el-icon-timer"/>
              <span>生产时间：{{ scope.row.create_time }}</span>
            </div>
            <div>
              <i class="el-icon-timer"/>
              <span>过期时间：{{ scope.row.expire_time|dateFormat }}</span>
            </div>
            <div>
              <i class="el-icon-timer"/>
              <span>使用时间：<span v-if="scope.row.use_time">{{ scope.row.use_time|dateFormat }}</span><span v-else></span></span>
            </div>
          </template>
        </el-table-column>

      </el-table>
    </el-scrollbar>


    <div style="margin-top:20px;">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getListData"
          :page-size="pageSize"
          :total="tableTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "CouponsToken",
  props: [
    'couponsId'
  ],
  data() {
    return {
      pageSize: 20,
      currentPage: 1,
      tableTotal: 0,
      tabLoading: false,
      tableData: [],
      userCouponList: {
        dialogVisible: false,
        pageSize: 20,
        currentPage: 1,
        tableTotal: 0,
        tabLoading: false,
        tableData: [],
      },
      dialogVisible: false,
      form:{
        batch_name:'',
        number:'',
        expire:'',
        id:0,
      },
      vaildateRules: {
        batch_name: [
          {required: true, message: '请填写批次名称', trigger: 'blur'},
        ],
        number: [
          {required: true, message: '请填写生成数量', trigger: 'blur'},
        ],
        expire: [
          {required: true, message: '请填写过期时间', trigger: 'blur'},
        ],
      },
      batchList:[
        {
          batch_name:'全部',
          id:0,
        }
      ],
      batchId:0,
    }
  },
  computed: {
    permissions() {
      return this.$store.state.user.allPermissions;
    }
  },
  mounted: function () {
    this.getListData();
  },
  methods: {
    // 获取数据列表
    getListData(page) {

      const _this = this;
      _this.tabLoading = true;
      if (page != undefined) {
        _this.currentPage = page;
      }

      //let batchName = _this.batchId == 0 ? '' : _this.batchId;

      _this.axios.get('api/op/couponsToken/' + _this.couponsId, {
        params: {},
        headers: {
          'page': _this.currentPage,
          "page-size": _this.pageSize,
          //'batch_name':batchName,
        },
      }).then(function (response) {


            if (response.status != 200 || response.data.status == undefined) {
              _this.$message.error('加载数据失败，正在重试');
            } else if (response.data.status == 0) {
              _this.$message.error(response.data.message);
            } else {
              _this.tableData = [];
              _this.tableTotal = response.data.data.total;
              _this.tableData = response.data.data.list;

              response.data.data.batch_list.forEach(function (e) {
                _this.batchList.push(e);
              })

            }
            _this.tabLoading = false;
          }
      ).catch(function (error) {
        console.log(error);
        if (error.response.status != 200 || error.response.data.status == undefined) {
          _this.$message.error('加载数据失败，正在重试');
        } else if (error.response.data.status == 0) {
          _this.$message.error(error.response.data.message);
        }

        _this.tabLoading = false;

      });
    },
    // 搜索
    handleSearch() {
      const _this = this;
      _this.getListData();
    },
    buildToken(){
      const _this = this;

      this.$refs['build'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          _this.form.id = _this.couponsId;
          const requestData = JSON.parse(JSON.stringify(_this.form));
          _this.axios.post('/api/op/couponsToken', requestData).then(function (response) {

            loading.close();

            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

            if (response.data.status != undefined && response.data.status == 1) {

              _this.$notify({
                title: '成功',
                message: message,
                type: 'success'
              });
              _this.$refs[`popover`].doClose();
              _this.getListData();
            } else {
              _this.$notify.error({
                title: '错误',
                message: message
              });
            }

          }).catch(function (error) {

            loading.close();
            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

            _this.$notify.error({
              title: '错误',
              message: msg
            });

          })


        } else {
          _this.$notify.error({
            title: '错误',
            message: '请按照要求填写信息'
          });
        }
      });


    }
  }
}
</script>

<style scoped>


</style>