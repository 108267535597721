<template>
  <div id="app" style="height: 100%;width: 100%;">
    <transition name="el-fade-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>

import Vue from 'vue';
import VueRouter from 'vue-router'
import HelloWorld from './components/HelloWorld.vue'
import Login from './page/login/Login'
import Home from './page/home/Home'
import HomePageStatistics from './page/home/dashboard/HomePageStatistics'
//import UserList from './page/home/user/UserList'
import User from './page/home/user/User'
import Commission from './page/home/financial/Commission'
import Withdrawal from './page/home/financial/Withdrawal'
import Cooperation from './page/home/help/Cooperation'
import ProblemReport from './page/home/help/ProblemReport'
import Helparticles from './page/home/help/Helparticles'
import Advertising from './page/home/marketing/Advertising'
import Refund from './page/home/financial/Refund'
import Topup from './page/home/financial/Topup'
import OpUserList from './page/home/authority/User'
import UserGroup from './page/home/authority/UserGroup'
import Logs from './page/home/logs/Logs'
import Test from './page/home/dashboard/Test'
import Merchants from './page/home/merchants/Merchants'
import Agent from './page/home/agent/Agent'
import Salesman from './page/home/salesman/Salesman'
import Busines from './page/home/statistical/Busines'
import UserStatistics from './page/home/statistical/UserStatistical'
import RentOrder from './page/home/order/Order'
import Chargingtank from './page/home/equipment/Chargingtank'
import Monitoring from './page/home/equipment/Monitoring'
import Power from './page/home/equipment/Power'
import Instruction from './page/home/equipment/Instruction'
import Coupons from './page/home/coupons/Coupons'
import RefundApp from './page/home/financial/RefundApp'


import VueProgressBar from 'vue-progressbar'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';



Vue.use(ElementUI);
Vue.use(VueRouter);

const options = {
  color: '#bffaf3',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'left',
  inverse: false
}

Vue.use(VueProgressBar, options)


const routes = [
  {path: '/', component: Home},
  {path: '/login', component: Login},
  {path: '/bar', component: HelloWorld},
  {
    path: '/home', component: Home,
    meta: {
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#ffb000'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
    },
    children: [
      {
        path: '/user',
        component: User
      },
      {
        path: '/op-user',
        component: OpUserList
      },
      {
        path: '/user-group',
        component: UserGroup
      },
      {
        path: '/Home',
        component: HomePageStatistics
      },
      {
        path: '/logs',
        component: Logs
      },
      {
        path: 'Test',
        component: Test
      },
      {
        path: '/merchants',
        component: Merchants
      },
      {
        path: '/agent',
        component: Agent
      },
      {
        path: '/salesman',
        component: Salesman
      },
      {
        path: '/commission',
        component: Commission
      },
      {
        path: '/topup',
        component: Topup
      },
      {
        path: '/refund',
        component: Refund
      },
      {
        path: '/withdrawal',
        component: Withdrawal
      },
      {
        path: '/cooperation',
        component: Cooperation
      },
      {
        path: '/problemReport',
        component: ProblemReport
      },
      {
        path: '/helparticles',
        component: Helparticles
      },
      {
        path: '/advertising',
        component: Advertising
      },
      {
        path: '/Busines',
        component: Busines
      },
      {
        path: '/userStatistics',
        component: UserStatistics
      },
      {
        path: '/rentOrder',
        component: RentOrder
      },
      {
        path: '/chargingtank',
        component: Chargingtank
      },
      {
        path: '/monitoring',
        component: Monitoring
      },
      {
        path: '/power',
        component: Power
      },
      {
        path: '/instruction',
        component: Instruction
      },
      {
        path: '/coupons',
        component: Coupons
      },
      {
        path: '/refundApp',
        component: RefundApp
      },



    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes // (缩写) 相当于 routes: routes
})


export default {
  name: 'App',
  components: {
    // HelloWorld

  },
  data() {
    return {
      editableTabsValue: '1',
      editableTabs: [{
        title: '首页',
        name: '1',
        content: '<div>This Home</div>'
      }],
      tabIndex: 1
    }
  },
  beforeCreate() {

    if (!localStorage.getItem('user')) {


      if (this.$route.path != '/login') {
        this.$router.push('/login');
      }

    }

    let userInfo = JSON.parse(localStorage.getItem('user'));


    if (!userInfo) {

      if (this.$route.path != '/login') {
        this.$router.push('/login');
      }

    }


  },
  created () {


    //  [App.vue specific] 当App.vue创建时，启动进度条
    // this.$Progress.start()
    // //  当路由变化时，挂起进度条
    // this.$router.beforeEach((to, from, next) => {
    //   //  判断页面是否存在 meta.progress
    //   if (to.meta.progress !== undefined) {
    //     let meta = to.meta.progress
    //     // parse meta tags
    //     this.$Progress.parseMeta(meta)
    //   }
    //   // 启动进度条
    //   this.$Progress.start()
    //   //  continue to next page
    //   next()
    // })
    // //  路由完成时，完成进度条
    // this.$router.afterEach(() => {
    //   //  finish the progress bar
    //   this.$Progress.finish()
    // })
  },
  mounted() {
    this.$Progress.finish()
    window.vm = this;
  },
  methods: {
    handleTabsEdit(targetName, action) {
      if (action === 'add') {
        let newTabName = ++this.tabIndex + '';
        this.editableTabs.push({
          title: 'New Tab',
          name: newTabName,
          content: 'New Tab content'
        });
        this.editableTabsValue = newTabName;
      }
      if (action === 'remove') {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
      }
    }
  },
  router
}


</script>

<style>
* {
  padding: 0;
  margin: 0;
}

html, body {
  width: 100%;
  height: 100%;
  min-width: 1280px;
  overflow: hidden;
}

#app {
  /*height: 100vh;*/
  background-color: #ececec;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}

.main {
  margin: 0;
  padding: 0;
}

.header {
  margin: 0;
  text-align: right;
  background-color: #fff;
  line-height: 60px;
}

.main .tab {
  height: 100%;
  background-color: #fff;
}

.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-top: none;
}

.el-scrollbar__wrap{overflow-x:hidden;}

.el-select-dropdown__wrap.el-scrollbar__wrap{
  /*margin: 20px 0px 20px 0px;*/
  overflow: scroll;
}


</style>
