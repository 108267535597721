<template>

    <div>

        <el-dialog
                top="1vh"
                title="添加备注"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="onlineEquipment.dialogVisible"
                width="35%"
        >

            <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请输入备注"
                    v-model="onlineEquipment.form.note">
            </el-input>


            <span slot="footer" class="dialog-footer">

                <el-button
                        size="medium"
                        type="primary"
                        @click="note"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type="" @click="onlineEquipment.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <el-card style="font-size: 14px;" class="box-card">
            <div slot="header" class="clearfix">
                <span>基础指标</span>
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <div class="text item">
                <el-row :gutter="10" v-loading="loading">

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">设备</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item" v-loading="loading">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总设备
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.devicesCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            在线设备
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.onlineDevicesCount}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">商家</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总商家数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.merchantsTotalCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总佣金
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.merchantsTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">代理商</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总代理商数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.agentTotalCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总佣金
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.agentTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">用户</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总用户数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.membersTotalCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总余额
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.membersTotalBalance}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总押金
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.membersTotalDeposit}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">充值</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总充值数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.topupOrderCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总充值金额
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.topupOrderTotalAmount}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">退款</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总退款订单数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.refundOrderCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总退款金额
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.refundOrderTotalAmount}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">订单 - 超时订单</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总订单数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.timeOutrentOrderCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总成本价
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.timeOutRentOrderTotalCostAmount}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">订单 - 租借中</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            租借中
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.ongoingRentOrderCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">

                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            异常
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.abnormalRentOrderCount}}
                                        </el-col>
                                    </el-row>


                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">今日收益 - 订单</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总订单数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.toDayRentOrderCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总租金
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.toDayRentOrderTotalAmount}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">本周收益 - 订单</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总订单数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.weeksRentOrderCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总租金
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.weeksRentOrderTotalAmount}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">本月收益 - 订单</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总订单数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.monthRentOrderCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总租金
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.monthRentOrderTotalAmount}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">总收益 - 订单</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总订单数
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.rentOrderCount}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            总租金
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.rentOrderTotalAmount}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">今日收益 - 佣金</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            平台
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.toDayPlatformTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            代理商
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.toDayAgentTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            商户
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.toDayMerchantsTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">本周收益 - 佣金</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            平台
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.weeksPlatformTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            代理商
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.weeksAgentTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            商户
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.weeksMerchantsTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">本月收益 - 佣金</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            平台
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.monthPlatformTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            代理商
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.monthAgentTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            商户
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.monthsMerchantsTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">

                        <div style="margin-bottom: 5px;">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span><el-tag type="success">总收益 - 佣金</el-tag></span>
                                </div>
                                <div style="font-size: 14px;" class="text item">
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            平台
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.allPlatformTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            代理商
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.allAgentTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="12">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            商户
                                        </el-col>
                                        <el-col style="text-align: right;" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                            {{data1.allMerchantsTotalBrokerage}}
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </div>

                    </el-col>

                </el-row>
            </div>
        </el-card>


        <el-row style="font-size: 14px;padding-top: 10px;" :gutter="20">

            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">


                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>租金日历</span>
                        <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                    </div>
                    <div v-loading="loading" class="text item">
                        <el-calendar>
                            <template
                                    slot="dateCell"
                                    slot-scope="{date, data}">
                                <p style="text-align: center; margin-top: 20px;">
                                    {{ data.day.split('-').slice(1).join('-') }}<br/>

                                    <span style="display:inline-block;margin-top: 5px;"
                                          v-if="data3[data.day.split('-').slice(1).join('-')]">
                                        <span style="color: red;"
                                              v-show="data3[data.day.split('-').slice(1).join('-')].totalAmount > data2.lastMonthRentOrderAmountAvg">{{data3[data.day.split('-').slice(1).join('-')].totalAmount}}</span>
                                        <span style="color: #000;"
                                              v-show="data3[data.day.split('-').slice(1).join('-')].totalAmount == data2.lastMonthRentOrderAmountAvg">{{data3[data.day.split('-').slice(1).join('-')].totalAmount}}</span>
                                        <span style="color: #0BB20C;"
                                              v-show="data3[data.day.split('-').slice(1).join('-')].totalAmount < data2.lastMonthRentOrderAmountAvg">{{data3[data.day.split('-').slice(1).join('-')].totalAmount}}</span>
                                    </span>
                                    <span v-else style="display:inline-block;margin-top: 5px;">
                                        <span style="color: red;"
                                              v-show="0 > data2.lastMonthRentOrderAmountAvg">0</span>
                                        <span style="color: #000;"
                                              v-show="0 == data2.lastMonthRentOrderAmountAvg">0</span>
                                        <span style="color: #0BB20C;"
                                              v-show="0 < data2.lastMonthRentOrderAmountAvg">0</span>
                                    </span>

                                </p>
                            </template>
                        </el-calendar>

                        <div style="text-align: center;">
                            <span style="display: inline-block;width: 50px;height: 30px;background-color: red;color: #fff;line-height: 30px;margin-right: 0px;">增长</span>
                            <span style="display: inline-block;width: 50px;height: 30px;background-color: #000;color: #fff;line-height: 30px;margin-right: 0px;">持平</span>
                            <span style="display: inline-block;width: 50px;height: 30px;background-color: #0BB20C;color: #fff;line-height: 30px;margin-right: 0px;">下降</span>
                        </div>

                    </div>


                </el-card>

            </el-col>

            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">

                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>运营数据</span>
                        <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                    </div>
                    <div v-loading="loading" style="text-align: center;" class="text item">
                        <el-row :gutter="10">

                            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">

                                <div style="margin-bottom: 5px;">

                                    <div>上月新增用户</div>
                                    <div style="margin-top: 25px;">{{data2.lastMonthNewAddUserTotalCount}}人</div>

                                    <div style="margin-top: 25px;">上月新增商户</div>
                                    <div style="margin-top: 25px;">{{data2.lastMonthNewAddMerchantsTotalCount}} 户</div>

                                    <div style="margin-top: 25px;">上月无交易商户</div>
                                    <div style="margin-top: 25px;">{{data2.lastMonthMerchantsTradingtotalCount}} 户</div>

                                    <div style="margin-top: 25px;">上月订单收益</div>
                                    <div style="margin-top: 25px;">{{data2.lastMonthRentOrderTotalAmount}} 铢</div>

                                    <div style="margin-top: 25px;">上月订单日均</div>
                                    <div style="margin-top: 25px;">{{data2.lastMonthRentOrderAmountAvg}} 铢</div>

                                    <div style="margin-top: 25px;">上月订单数量</div>
                                    <div style="margin-top: 25px;">{{data2.lastMonthRentOrderCount}} 单</div>

                                </div>

                            </el-col>

                            <el-col :xs="8" :sm="6" :md="4" :lg="6" :xl="12">

                                <div style="margin-bottom: 5px;">

                                    <div>本月新增用户</div>
                                    <div v-if="data2.monthNewAddUserTotalCount > data2.lastMonthNewAddUserTotalCount"
                                         style="margin-top: 25px;color: red;">{{data2.monthNewAddUserTotalCount}} 人
                                    </div>
                                    <div v-if="data2.monthNewAddUserTotalCount == data2.lastMonthNewAddUserTotalCount"
                                         style="margin-top: 25px;color:#000;">{{data2.monthNewAddUserTotalCount}} 人
                                    </div>
                                    <div v-if="data2.monthNewAddUserTotalCount < data2.lastMonthNewAddUserTotalCount"
                                         style="margin-top: 25px;color:#0BB20C;">{{data2.monthNewAddUserTotalCount}} 人
                                    </div>

                                    <div style="margin-top: 25px;">本月新增商户</div>
                                    <div v-if="data2.monthNewAddMerchantsTotalCount > data2.lastMonthNewAddMerchantsTotalCount"
                                         style="margin-top: 25px;color: red;">{{data2.monthNewAddMerchantsTotalCount}} 户
                                    </div>
                                    <div v-if="data2.monthNewAddMerchantsTotalCount == data2.lastMonthNewAddMerchantsTotalCount"
                                         style="margin-top: 25px;color:#000;">{{data2.monthNewAddMerchantsTotalCount}} 户
                                    </div>
                                    <div v-if="data2.monthNewAddMerchantsTotalCount < data2.lastMonthNewAddMerchantsTotalCount"
                                         style="margin-top: 25px;color:#0BB20C;">
                                        {{data2.monthNewAddMerchantsTotalCount}} 户
                                    </div>

                                    <div style="margin-top: 25px;">本月无交易商户</div>
<!--                                    <div  v-if="data2.monthMerchantsTradingtotalCount > data2.LastMonthMerchantsTradingtotalCount" style="margin-top: 25px;color: red;">{{data2.monthMerchantsTradingtotalCount}} 户</div>-->
<!---->
                                    <div   style="margin-top: 25px;color: #0BB20C;">{{data2.monthMerchantsTradingtotalCount}} 户</div>

                                    <div style="margin-top: 25px;">本月订单收益</div>
                                    <div v-if="data2.monthRentOrderTotalAmount > data2.lastMonthRentOrderTotalAmount"
                                         style="margin-top: 25px;color: red;">{{data2.monthRentOrderTotalAmount}} 铢
                                    </div>
                                    <div v-if="data2.monthRentOrderTotalAmount == data2.mlastMonthRentOrderTotalAmount"
                                         style="margin-top: 25px;color:#000;">{{data2.monthRentOrderTotalAmount}} 铢
                                    </div>
                                    <div v-if="data2.monthRentOrderTotalAmount < data2.lastMonthRentOrderTotalAmount"
                                         style="margin-top: 25px;color:#0BB20C;">{{data2.monthRentOrderTotalAmount}} 铢
                                    </div>

                                    <div style="margin-top: 25px;">本月订单日均</div>
                                    <div v-if="data2.monthRentOrderAmountAvg > data2.lastMonthRentOrderAmountAvg"
                                         style="margin-top: 25px;color: red;">{{data2.monthRentOrderAmountAvg}} 铢
                                    </div>
                                    <div v-if="data2.monthRentOrderAmountAvg == data2.lastMonthRentOrderAmountAvg"
                                         style="margin-top: 25px;color: #000;">{{data2.monthRentOrderAmountAvg}} 铢
                                    </div>
                                    <div v-if="data2.monthRentOrderAmountAvg < data2.lastMonthRentOrderAmountAvg"
                                         style="margin-top: 25px;color: #0BB20C;">{{data2.monthRentOrderAmountAvg}} 铢
                                    </div>

                                    <div style="margin-top: 25px;">本月订单数量</div>
                                    <div v-if="data2.monthRentOrderCount > data2.lastMonthRentOrderCount"
                                         style="margin-top: 25px;color: red;">{{data2.monthRentOrderCount}} 单
                                    </div>
                                    <div v-if="data2.monthRentOrderCount == data2.lastMonthRentOrderCount"
                                         style="margin-top: 25px;color: #000;">{{data2.monthRentOrderCount}} 单
                                    </div>
                                    <div v-if="data2.monthRentOrderCount < data2.lastMonthRentOrderCount"
                                         style="margin-top: 25px;color: #0BB20C;">{{data2.monthRentOrderCount}} 单
                                    </div>

                                </div>

                            </el-col>

                            <el-row :gutter="10">
                                <el-col style="margin-top: 25px;" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                    <span style="display: inline-block;width: 50px;height: 30px;background-color: red;color: #fff;line-height: 30px;margin-right: 0px;">增长</span>
                                    <span style="display: inline-block;width: 50px;height: 30px;background-color: #000;color: #fff;line-height: 30px;margin-right: 0px;">持平</span>
                                    <span style="display: inline-block;width: 50px;height: 30px;background-color: #0BB20C;color: #fff;line-height: 30px;margin-right: 0px;">下降</span>
                                </el-col>
                            </el-row>


                        </el-row>
                    </div>
                </el-card>


            </el-col>


        </el-row>

        <el-row v-loading="ranking.loading" style="font-size: 14px;padding-top: 10px;text-align: left;" :gutter="20">

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">

                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>商户排名</span>
                        <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                    </div>
                    <div style="text-align: center;" class="text item">
                        <el-table
                                :data="ranking.list"
                                stripe>
                            <el-table-column
                                    label="排名"
                            >
                                <template slot-scope="scope">
                                    <span>{{(ranking.currentPage - 1) * ranking.pageSize + scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="商家名称"
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.merchants">{{ scope.row.merchants.name }}</span>
                                    <span v-else>未知</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="所属代理"
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.agent">{{ scope.row.agent.agent_name }}</span>
                                    <span v-else>未知</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="所属业务员"
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.salesman">{{ scope.row.salesman.real_name }}</span>
                                    <span v-else-if="scope.row.saler_id == 0"></span>
                                    <span v-else>未知</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="营业额"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.total_amount}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="margin-top: 10px;">
                            <el-pagination
                                    background
                                    layout="prev, pager, next"
                                    @current-change="getMerchantsRankingData"
                                    :page-size="ranking.pageSize"
                                    :total="ranking.total">
                            </el-pagination>
                        </div>
                    </div>
                </el-card>


            </el-col>

        </el-row>

        <el-row  style="font-size: 14px;padding-top: 10px;text-align: left;" :gutter="20">

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">

                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>无交易商户</span>
                        <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                    </div>
                    <div v-loading="notTrading.loading" style="text-align: center;" class="text item">
                        <el-table
                                :data="notTrading.list"
                                stripe>
                            <el-table-column
                                    label="排名"
                            >
                                <template slot-scope="scope">
                                    <span>{{(notTrading.currentPage - 1) * notTrading.pageSize + scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="商家名称"
                            >
                                <template slot-scope="scope">
                                    <span >{{scope.row.name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="所属代理"
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.agent_details">{{ scope.row.agent_details.agent_name }}</span>
                                    <span v-else>未知</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="所属业务员"
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.salesman_details">{{ scope.row.salesman_details.real_name }}</span>
                                    <span v-else-if="scope.row.salesman == 0"></span>
                                    <span v-else>未知</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="入住时间"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.create_time}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="margin-top: 10px;">
                            <el-pagination
                                    background
                                    layout="prev, pager, next"
                                    @current-change="getNotTradingMerchants"
                                    :page-size="notTrading.pageSize"
                                    :total="notTrading.total">
                            </el-pagination>
                        </div>
                    </div>
                </el-card>


            </el-col>

        </el-row>

        <el-row  style="font-size: 14px;padding-top: 10px;" :gutter="20">

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">

                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>离线机柜列表</span>
                        <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                    </div>
                    <div style="text-align: center;" class="text item">

                        <el-tabs  v-model="onlineEquipment.activeName">
                            <el-tab-pane label="列表" name="1">
                                <el-table

                                        v-loading="onlineEquipment.loading"
                                        :data="onlineEquipment.list"
                                        stripe>
                                    <el-table-column
                                            label="排名"
                                            width="50"
                                    >
                                        <template slot-scope="scope">
                                            <span>{{(onlineEquipment.currentPage - 1) * onlineEquipment.pageSize + scope.$index + 1 }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="机柜编号"
                                    >
                                        <template slot-scope="scope">
                                            <span >{{scope.row.cabinet_id}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="所属商家"
                                    >
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.merchants">{{ scope.row.merchants.name }}</span>
                                            <span v-else>未知</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                            label="所属代理"
                                    >
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.agent">{{ scope.row.agent.agent_name }}</span>
                                            <span v-else>未知</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                            label="开机时间"
                                    >
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.registertime | dateFormat}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="最后链接时间"
                                    >
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.heartbeattime | dateFormat}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="离线时间"
                                    >
                                        <template slot-scope="scope">
                                            <span>{{scope.row.offline_time}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="备注"
                                    >
                                        <template slot-scope="scope">
                                            <span >{{scope.row.describe}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="300">
                                        <template slot-scope="scope">
                                            <el-button
                                                    size="mini"
                                                    type="danger"
                                                    @click="openDialog(scope.$index, scope.row)">添加备注
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div style="margin-top: 10px;">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="getOnlineEquipment"
                                            :page-size="onlineEquipment.pageSize"
                                            :total="onlineEquipment.total">
                                    </el-pagination>
                                </div>

                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-card>


            </el-col>

        </el-row>


        <div>



        </div>


    </div>

</template>

<script>

    import Vue from "vue";

    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2, '0')

        //小时
        const hh = (dt.getHours() + '').padStart(2, '0')
        //分钟
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        //秒数
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })

    export default {
        name: "Global",
        data() {
            return {
                data1: [],
                data2: [],
                data3: [],
                loading: true,
                ranking: {
                    list: [],
                    currentPage: 1,
                    total: 0,
                    pageSize: 20,
                    loading: true,
                },
                notTrading: {
                    list: [],
                    currentPage: 1,
                    total: 0,
                    pageSize: 20,
                    loading: true,
                },
                onlineEquipment: {
                    list: [],
                    currentPage: 1,
                    total: 0,
                    pageSize: 20,
                    loading: true,
                    activeName:'1',
                    details:{},
                    form:{
                        note:''
                    },
                    dialogVisible:false,
                }
            }
        },
        mounted() {
            this.getBaseData();
            this.getMerchantsRankingData();
            this.getNotTradingMerchants();
            this.getOnlineEquipment();
        },
        methods: {
            // 获取基础数据数据
            getBaseData() {
                const _this = this;
                _this.loading = true;
                _this.axios.get('api/op/homePageStatistics').then(function (response) {
                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.data1 = response.data.data.data1;
                            _this.data2 = response.data.data.data2;
                            _this.data3 = response.data.data.dailyrentOrderList;
                            _this.loading = false;
                        }
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }
                });
            },
            getMerchantsRankingData(page) {
                const _this = this;

                if (page != undefined) {
                    _this.ranking.currentPage = page;
                }

                _this.ranking.loading = true;
                _this.axios.get('api/op/homePageStatistics', {
                    headers: {
                        action: 'merchants-ranking', 'page': _this.ranking.currentPage,
                        "page-size": _this.ranking.pageSize,
                    }
                }).then(function (response) {
                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.ranking.list = response.data.data.list;
                            _this.ranking.total = response.data.data.total;
                            _this.ranking.loading = false;
                        }
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }
                });
            },
            getNotTradingMerchants(page) {
                const _this = this;

                if (page != undefined) {
                    _this.notTrading.currentPage = page;
                }

                _this.notTrading.loading = true;
                _this.axios.get('api/op/homePageStatistics', {
                    headers: {
                        action: 'nottrading-merchants', 'page': _this.notTrading.currentPage,
                        "page-size": _this.notTrading.pageSize,
                    }
                }).then(function (response) {
                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.notTrading.list = response.data.data.list;
                            _this.notTrading.total = response.data.data.total;
                            _this.notTrading.loading = false;
                        }
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }
                });
            },
            getOnlineEquipment(page) {
                const _this = this;

                if (page != undefined) {
                    _this.onlineEquipment.currentPage = page;
                }

                _this.onlineEquipment.loading = true;
                _this.axios.get('api/op/homePageStatistics', {
                    headers: {
                        action: 'offline-equipment', 'page': _this.onlineEquipment.currentPage,
                        "page-size": _this.onlineEquipment.pageSize,
                    }
                }).then(function (response) {
                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.onlineEquipment.list = response.data.data.list;
                            _this.onlineEquipment.total = response.data.data.total;
                            _this.onlineEquipment.loading = false;
                        }
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }
                });
            },
            openDialog(index, row) {
                const _this = this;
                _this.onlineEquipment.dialogVisible = true;
                _this.onlineEquipment.details = JSON.parse(JSON.stringify(row));
                _this.onlineEquipment.form.note = row.describe;
            },
            // 添加备注
            note() {

                const _this = this;

                if (_this.onlineEquipment.form.note == ''){

                    _this.$notify.error({
                        title: '错误',
                        message: '请填写备注'
                    });
                    return false;

                }

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                let rquestOption = {
                    params: {
                        'describe': _this.onlineEquipment.form.note
                    }
                };

                _this.axios.put('/api/op/charecabinet/' + _this.onlineEquipment.details.id, '', rquestOption).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.onlineEquipment.dialogVisible = false;
                        _this.getOnlineEquipment();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
        }
    }
</script>

<style scoped>

</style>