<template>
    <div>

        <template>
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="用户"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.userid <= 0" style="color: red;">系统控制台</span>
                        <span v-else >
                            {{scope.row.user.username}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="描述"
                        prop="querystring"
                >
                </el-table-column>

                <el-table-column
                        label="客户端IP地址"
                        prop="ip"
                >
                </el-table-column>

                <el-table-column
                        label="操作系统"
                        prop="os"
                >
                </el-table-column>

                <el-table-column
                        label="时间"
                        prop="create_time"
                >
                </el-table-column>


            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>


    </div>
</template>

<script>


    export default {
        name: "Logs",
        data() {
            return {
                currentPage: 1,
                pageSize: 20,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
            }
        },
        mounted: function () {
            this.getListData();
        },
        created() {
        },
        methods: {
            getListData() {
                const _this = this;
                _this.tabLoading = true;
                _this.axios.get('api/op/logs', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                    },
                }).then(function (response) {
                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {

                            _this.tableTotal = response.data.data.total;
                            _this.tableData = [];
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;
                });
            }
        }
    }
</script>

<style scoped>


    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a1a3a9;
        border-radius: 3px;
    }

</style>