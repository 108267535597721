<template>

    <div>Hi My is Test</div>

</template>

<script>
    export default {
        name: "Test"
    }
</script>

<style scoped>

</style>