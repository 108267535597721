<template>

    <div>

        <el-dialog
                title="执行结果"
                :visible.sync="dialogVisible"
                width="50%"
        >

            <vue-json-editor v-model="result"></vue-json-editor>

            <span slot="footer" class="dialog-footer">
        <el-button @click="getResults(cabinet_id)">刷新</el-button>
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </span>
        </el-dialog>

        <el-form v-model="form1" :inline="true" ref="form1" label-width="150px" class="demo-ruleForm">
            <el-form-item label="查询充电宝库存" prop="name">
                <el-input v-model="form1.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit(form1)">发送</el-button>
                <el-button click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form2" :inline="true" ref="form2" label-width="150px" class="demo-ruleForm">
            <el-form-item label="查询机柜APN" prop="name">
                <el-input v-model="form2.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>

            <el-form-item>
                <el-input v-model="form2.index" prefix-icon="el-icon-suitcase" show-word-limit clearable maxlength="1"
                          minlength="1" placeholder="入索引(0-4)"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button @click="submit(form2)" type="primary">发送</el-button>
                <el-button>重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form3" ref="form3" label-width="150px" class="demo-ruleForm">
            <el-form-item label="设置机柜APN" prop="name">
                <el-input v-model="form3.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form3.index" prefix-icon="el-icon-suitcase" show-word-limit clearable maxlength="1"
                          minlength="1" placeholder="索引(0-4)"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form3.valid" prefix-icon="el-icon-question" show-word-limit clearable maxlength="1"
                          minlength="1" placeholder="是否有效 0 = 无效 1 = 有效"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form3.mcc" prefix-icon="el-icon-upload" show-word-limit clearable maxlength="6"
                          minlength="6" placeholder="MCCMNC"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form3.username" prefix-icon="el-icon-user-solid" show-word-limit clearable
                          maxlength="32"
                          minlength="32" placeholder="username"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form3.password" prefix-icon="el-icon-unlock" show-word-limit clearable maxlength="32"
                          minlength="32" placeholder="password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="submit(form3)" type="primary">发送</el-button>
                <el-button>重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form4" :inline="true" ref="form4" label-width="150px" class="demo-ruleForm">
            <el-form-item label="查询ICCID" prop="name">
                <el-input v-model="form4.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="submit(form4)" type="primary">发送</el-button>
                <el-button>重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form5" :inline="true" ref="form5" label-width="150px" class="demo-ruleForm">
            <el-form-item label="打开电子锁" prop="name">
                <el-input v-model="form5.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form5.lock_id" prefix-icon="el-icon-lock" show-word-limit clearable maxlength="2"
                          minlength="1" placeholder="锁ID"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button @click="submit(form5)" type="primary">发送</el-button>
                <el-button>重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form6" :inline="true" ref="form6" label-width="150px" class="demo-ruleForm">
            <el-form-item label="查询机柜固件版本" prop="name">
                <el-input v-model="form6.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="submit(form6)" type="primary">发送</el-button>
                <el-button>重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form7" :inline="true" ref="form7" label-width="150px" class="demo-ruleForm">
            <el-form-item label="查询机柜配置文件" prop="name">
                <el-input v-model="form7.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="submit(form7)" type="primary">发送</el-button>
                <el-button>重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form8" ref="form8" label-width="150px" class="demo-ruleForm">
            <el-form-item label="设置机柜配置文件" prop="name">
                <el-input v-model="form8.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form8.addr" prefix-icon="el-icon-link" show-word-limit clearable
                          maxlength="20"
                          placeholder="服务器地址"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form8.port" prefix-icon="el-icon-position" show-word-limit clearable maxlength="6"
                          placeholder="端口"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form8.heartbeat" prefix-icon="el-icon-time" show-word-limit clearable maxlength="3"
                          placeholder="心跳时间"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="submit(form8)" type="primary">发送</el-button>
                <el-button>重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form9" ref="form9" label-width="150px" class="demo-ruleForm">
            <el-form-item label="OTA升级" prop="name">
                <el-input v-model="form9.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form9.addr" prefix-icon="el-icon-link" show-word-limit clearable
                          maxlength="20"
                          placeholder="服务器地址"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form9.port" prefix-icon="el-icon-position" show-word-limit clearable maxlength="6"
                          placeholder="端口"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form9.filename" prefix-icon="el-icon-files" show-word-limit clearable maxlength="32"
                          placeholder="文件名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form9.username" prefix-icon="el-icon-user-solid" show-word-limit clearable
                          maxlength="32"
                          minlength="32" placeholder="username"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form9.password" prefix-icon="el-icon-unlock" show-word-limit clearable maxlength="32"
                          minlength="32" placeholder="password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="submit(form9)" type="primary">发送</el-button>
                <el-button click="resetForm('form9')">重置</el-button>
            </el-form-item>
        </el-form>

        <el-form v-model="form10" :inline="true" ref="form10" label-width="150px" class="demo-ruleForm">
            <el-form-item label="重启机柜" prop="name">
                <el-input v-model="form10.cabinet_id" prefix-icon="el-icon-s-platform" show-word-limit clearable
                          maxlength="16"
                          minlength="16" placeholder="机柜ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="submit(form10)" type="primary">发送</el-button>
                <el-button >重置</el-button>
            </el-form-item>
        </el-form>

    </div>

</template>

<script>

    import vueJsonEditor from 'vue-json-editor'

    export default {
        name: "Instruction",
        data() {
            return {
                dialogVisible: false,
                form1: {
                    cabinet_id: '',
                    instruction_code: '110',
                },
                form2: {
                    cabinet_id: '',
                    index: '',
                    instruction_code: '260',
                },
                form3: {
                    cabinet_id: '',
                    index: 4,
                    valid: 1,
                    mcc: 52003,
                    apn: 'internet',
                    username: '',
                    password: '',
                    instruction_code: '257',
                },
                form4: {
                    cabinet_id: '',
                    instruction_code: '105',
                },
                form5: {
                    cabinet_id: '',
                    lock_id: '',
                    instruction_code: '250',
                },
                form6: {
                    cabinet_id: '',
                    instruction_code: '114',
                },
                form7: {
                    cabinet_id: '',
                    instruction_code: '115',
                },
                form8: {
                    cabinet_id: '',
                    addr: '',
                    port: '',
                    heartbeat: '',
                    instruction_code: '202',
                },
                form9: {
                    cabinet_id: '',
                    addr: '',
                    port: '',
                    filename: '',
                    username: '',
                    password: '',
                    instruction_code: '204',
                },
                form10: {
                    cabinet_id: '',
                    instruction_code: '203',
                },
                result: {},
                cabinet_id: '',
                labelPosition: 'top',
            }
        },
        mounted() {
        },
        methods: {
            // 提交指令
            submit(data) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.post('/api/op/instruction', data).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        setTimeout(function () {
                            _this.getResults(data.cabinet_id, 1000);
                        }, 200)

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 获取执行结果
            getResults(cabinet_id, delay = 0) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                setTimeout(function () {
                    _this.axios.get('api/op/instruction/' + cabinet_id, {}).then(function (response) {

                            loading.close();

                            if (response.status != 200 || response.data.status == undefined) {
                                _this.$message.error('加载数据失败，正在重试');
                            } else if (response.data.status == 0) {
                                _this.$message.error(response.data.message);
                            } else {
                                _this.dialogVisible = true;
                                _this.cabinet_id = cabinet_id;
                                _this.result = response.data.data;
                            }

                        }
                    ).catch(function (error) {

                        loading.close();

                        console.log(error);
                        if (error.response.status != 200 || error.response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (error.response.data.status == 0) {
                            _this.$message.error(error.response.data.message);
                        }

                    });
                }, delay)

            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        },
        components: {
            vueJsonEditor,
        }
    }
</script>

<style scoped>

</style>