<template>
    <div>


        <div style="margin-bottom: 10px;">
            <template>

                <el-select v-model="search.locationType" placeholder="请选择语言类型">
                    <el-option
                            v-for="item in locationTypeList"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                    </el-option>
                </el-select>

                <el-input style="margin-left:10px;width: 150px;"
                          placeholder="请输入关键字"
                          v-model="search.keyWord"
                          clearable>
                </el-input>

                <el-date-picker style="margin-left: 10px;"
                                v-model="search.time"
                                :picker-options="pickerOptions"
                                value-format="timestamp"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right">
                </el-date-picker>

            </template>


            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch">搜索
            </el-button>

            <el-button v-if="permissions['api/op.advertising'] && permissions['api/op.advertising']['POST']" style="margin-left:10px;" type="success" icon="el-icon-add-location" @click="openDialog">
                新增
            </el-button>


        </div>

        <el-dialog
                top="1vh"
                title="编辑广告位"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="handleDialogClose"
                :visible.sync="dialogVisible"
                width="35%"
        >

            <el-form ref="form" :rules="vaildateRules" :validate-on-rule-change="false" :model="form"
                     label-width="130px"
                     style="padding-right: 20px;">

                <template>

                    <el-tabs tab-position="left">

                        <el-tab-pane label="基本设置">

                            <el-form-item label="广告名称" prop="name">
                                <el-input show-word-limit maxlength="32" v-model="form.name"></el-input>
                            </el-form-item>

                            <el-form-item label="图片" prop="images">
                                <el-upload
                                        ref="uploadFile1"
                                        class="upload-demo"
                                        action="api/op/merchantsImages"
                                        :headers="uploadHeader"
                                        :on-success="handeUploadFile1"
                                        :on-remove="handleRemoveFile1"
                                        :file-list="form.images"
                                        :limit="1"
                                        list-type="picture">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png文件，且不超过5MB</div>
                                </el-upload>
                            </el-form-item>

                            <el-form-item label="位置" prop="location">
                                <el-select v-model="form.location" placeholder="请选择位置">
                                    <el-option
                                            v-for="item in locationList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="跳转链接" prop="url">
                                <el-input show-word-limit maxlength="255" v-model="form.url"></el-input>
                            </el-form-item>

                            <el-form-item label="过期时间" prop="expire_time">
                                <el-date-picker
                                        v-model="form.expire_time"
                                        value-format="timestamp"
                                        type="datetime"
                                        placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="状态" prop="status">
                                <el-switch v-model="form.status"></el-switch>
                            </el-form-item>

                        </el-tab-pane>

                    </el-tabs>
                </template>

            </el-form>

            <span slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="submit()">确 定</el-button>
                  <el-button style="margin-left: 10px" size="medium" type=""
                             @click="dialogVisible = false">关闭</el-button>

        </span>

        </el-dialog>


        <template>
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="广告名称"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="位置"
                >
                    <template slot-scope="scope">
                        <div class="demo-image__preview">
                            <span v-if="scope.row.location == 1">首页上方</span>
                            <span v-else-if="scope.row.location == 2">发现页上方</span>
                            <span v-else>未知</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                        label="图片"
                >
                    <template slot-scope="scope">
                        <div class="demo-image__preview">
                            <el-image key="1" :preview-src-list="[scope.row.images]" :src="scope.row.images"></el-image>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                        label="跳转链接"
                        width="300"
                >
                    <template slot-scope="scope">
                        <div class="demo-image__preview">
                            <span><a target="_blank" :href="scope.row.url">{{ scope.row.url }}</a></span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                        label="状态"
                >
                    <template slot-scope="scope">
                        <div class="demo-image__preview">
                            <span v-if="scope.row.status == 0">启用</span>
                            <span v-else-if="scope.row.status == 1">禁用</span>
                            <span v-else-if="scope.row.status == 2">删除</span>
                            <span v-else>未知</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                        label="过期时间"
                        width="200"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-timer"/>
                        <span>{{ scope.row.expire_time | dateFormat }}</span>
                    </template>
                </el-table-column>


                <el-table-column label="" width="350">
                    <template slot-scope="scope">

                        <el-button
                                size="mini"
                                type="primary"
                                v-if="permissions['api/op.advertising'] && permissions['api/op.advertising']['PUT']"
                                @click="openDialog(scope.$index, scope.row)"><i class="el-icon-info"
                                                                                style="margin-right: 5px;"/>编辑
                        </el-button>

                        <el-popconfirm
                                title="确定要启用吗？"
                                v-if="scope.row.status == 1 && permissions['api/op.advertising'] && permissions['api/op.advertising']['PUT']"
                                @confirm="openHandle(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       slot="reference"><i style="margin-right: 5px;" class="el-icon-open"/>启用
                            </el-button>
                        </el-popconfirm>

                        <el-popconfirm
                                title="确定要禁用吗"
                                v-if="scope.row.status == 0 && permissions['api/op.advertising'] && permissions['api/op.advertising']['PUT']"
                                @confirm="BanHandle(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       slot="reference"><i style="margin-right: 5px;" class="el-icon-open"/>禁用
                            </el-button>
                        </el-popconfirm>

                        <el-popconfirm
                                title="确定要删除吗？"
                                v-if="permissions['api/op.advertising'] && permissions['api/op.advertising']['DELETE']"
                                @confirm="deleteCooperation(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="danger"
                                       slot="reference"><i style="margin-right: 5px;" class="el-icon-error"/>删除
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import Vue from "vue";

    let Base64 = require('js-base64').Base64


    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2, '0')

        //小时
        const hh = (dt.getHours() + '').padStart(2, '0')
        //分钟
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        //秒数
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })

    export default {
        name: "Marketing",
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '7天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '30天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '90天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '180天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '3650天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3650);
                            picker.$emit('pick', [start, end]);
                        },
                    }]
                },
                search: {
                    status: 0,
                    locationType: 0,
                    time: null,
                    keyWord: '',
                },
                locationTypeList: [
                    {name: '全部', type: 0},
                    {name: '首页', type: 1},
                    {name: '发现页', type: 2},
                ],
                locationList: [
                    {name: '首页上方', id: 1},
                    {name: '发现页上方', id: 2},
                ],
                details: {},
                form: {
                    name: '',
                    url: '',
                    images: [],
                    expire_time: '',
                    location: '',
                    status: true,
                },
                uploadHeader: {
                    token: ''
                },
                vaildateRules: {
                    name: [
                        {required: true, message: '请填写广告名称', trigger: 'blur'},
                    ],
                    images: [
                        {required: true, message: '请上传图片', trigger: 'blur'},
                    ],
                    location: [
                        {required: true, message: '请选择展示位置', trigger: 'blur'},
                    ],
                    url: [
                        {required: true, message: '请填写跳转链接', trigger: 'blur'},
                    ],
                    expire_time: [
                        {required: true, message: '请填写过期时间', trigger: 'blur'},
                    ]
                },
                dialogVisible: false,
                dialogVisible2: false,
                dialogVisible2Action: 'Add',
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted: function () {
            this.setUploadHeader();
            this.getListData();

        },
        methods: {
            // 设置上传组件header
            setUploadHeader() {
                let user = JSON.parse(localStorage.getItem('user'));
                this.uploadHeader.token = user != undefined ? user.token : '';
            },
            // 处理图片上传
            handeUploadFile1(response, file, list) {
                const _this = this;
                if (response.status != undefined && response.status == 1) {
                    _this.form.images.push({name: file.name, url: response.data.url});
                } else {
                    let message = response.message == undefined ? '上传失败' : response.message;
                    _this.$message.error(message);
                    list.splice(list.length - 1, 1);
                }
            },
            // 处理图片移除
            handleRemoveFile1(file) {
                const _this = this;
                _this.form.images.forEach(function (e, i) {
                    if (e.url == file.url) {
                        _this.form.images.splice(i, 1);
                    }
                })
            },
            // 获取广告列表
            getListData(page) {

                const _this = this;
                _this.tabLoading = true;
                if (page != undefined) {
                    _this.currentPage = page;
                }

                _this.axios.get('api/op/advertising', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'location-type': _this.search.locationType,
                        'key-word': Base64.toBase64(_this.search.keyWord),
                        'start-time': _this.search.time != null ? _this.search.time[0] / 1000 : '',
                        'end-time': _this.search.time != null ? _this.search.time[1] / 1000 : '',
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 打开表单弹层
            openDialog(index, row) {
                const _this = this;

                _this.form = {
                    name: '',
                    url: '',
                    images: [],
                    expire_time: '',
                    location: '',
                    status: true,
                };

                if (_this.$refs['uploadFile1'] != undefined) {
                    _this.$refs['uploadFile1'].clearFiles();
                }

                if (_this.$refs['form'] != undefined) {
                    _this.$refs['form'].clearValidate();
                }

                if (row) {
                    _this.form.name = row.name;
                    _this.form.images = [{name: row.images, url: row.images}];
                    _this.form.location = row.location;
                    _this.form.url = row.url;
                    _this.form.status = row.status == 0 ? true : false;
                    _this.form.expire_time = row.expire_time * 1000
                    _this.details = row;
                } else {
                    _this.details = [];
                }

                _this.dialogVisible = true;
            },
            // 表单提交
            submit() {

                const _this = this;

                this.$refs['form'].validate((valid) => {
                    if (valid) {

                        const loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });

                        const requestData = JSON.parse(JSON.stringify(_this.form));

                        requestData.status = requestData.status ? 0 : 1;
                        requestData.images = requestData.images[0].url;

                        if (_this.details.id != undefined) {
                            _this.axios.put('/api/op/advertising/' + _this.details.id, requestData).then(function (response) {

                                loading.close();

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {

                                    _this.$notify({
                                        title: '成功',
                                        message: message,
                                        type: 'success'
                                    });
                                    _this.dialogVisible = false;
                                    _this.getListData();
                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                loading.close();
                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })
                        } else {

                            _this.axios.post('/api/op/advertising', requestData).then(function (response) {

                                loading.close();

                                const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                                if (response.data.status != undefined && response.data.status == 1) {

                                    _this.$notify({
                                        title: '成功',
                                        message: message,
                                        type: 'success'
                                    });
                                    _this.dialogVisible = false;
                                    _this.getListData();
                                } else {
                                    _this.$notify.error({
                                        title: '错误',
                                        message: message
                                    });
                                }

                            }).catch(function (error) {

                                loading.close();
                                let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                                _this.$notify.error({
                                    title: '错误',
                                    message: msg
                                });

                            })

                        }

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: '请按照要求填写信息'
                        });
                    }
                });

            },
            // 开启处理
            openHandle(index, row) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                let rquestData = {
                    status: 0
                };

                _this.axios.put('/api/op/advertising/' + row.id, rquestData).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'succe-ss'
                        });
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 禁用
            BanHandle(index, row) {


                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                let rquestData = {
                    status: 1
                };

                _this.axios.put('/api/op/advertising/' + row.id, rquestData).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'succe-ss'
                        });
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 删除
            deleteCooperation(index, row) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/advertising/' + row.id, '').then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                        _this.getListData();

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 搜索
            handleSearch() {
                const _this = this;
                _this.getListData();
            },

        },
    }
</script>

<style scoped>

    .html {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

</style>