<template>

    <div>

        <div style="margin-bottom: 10px;">

            <el-input style="margin-left:10px;width: 150px;"
                      placeholder="请输入充电宝ID"
                      v-model="search.deviceId"
                      clearable>
            </el-input>
            <el-input style="margin-left:10px;width: 150px;"
                      placeholder="请输入所在机柜"
                      v-model="search.charecabinetId"
                      clearable>
            </el-input>

            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch()">搜索
            </el-button>

            <el-button type="success"
                       v-if="permissions['api/op.mobilePower'] && permissions['api/op.mobilePower']['POST']"
                       @click="dialog.dialog2.dialogVisible = true;dialog.dialog2.form.agent_id = 0;dialog.dialog2.form.device_id = '';">
                新增充电宝
            </el-button>
            <el-button v-if="permissions['api/op.mobilePower'] && permissions['api/op.mobilePower']['POST'] && permissions['api/op.excelFile'] && permissions['api/op.excelFile']['POST']"  type="success" @click="openDialog3">批量导入充电宝</el-button>

        </div>

        <el-dialog
                top="1vh"
                title="修改充电宝"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog1.dialogVisible"
                width="35%"
        >

            <el-form label-position="left" label-width="100px">

                <el-form-item label="归属代理商">
                    <el-select style="width: 100%;" v-model="dialog.dialog1.form.agent_id"
                               placeholder="请选择代理商">
                        <el-option
                                key="0"
                                label="请选择代理商"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in agentList"
                                :key="item.id"
                                :label="item.agent_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-button
                        size="medium"
                        type="primary"
                        @click="update"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialog.dialog1.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <el-dialog
                top="1vh"
                title="新增充电宝"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog2.dialogVisible"
                width="35%"
        >

            <el-form label-position="left" label-width="100px">

                <el-form-item label="充电宝ID">
                    <el-input placeholder="请输入充电宝ID" show-word-limit clearable maxlength="20"
                              v-model="dialog.dialog2.form.device_id"></el-input>
                </el-form-item>
                <el-form-item label="归属代理商">
                    <el-select style="width: 100%;" v-model="dialog.dialog2.form.agent_id"
                               placeholder="请选择代理商">
                        <el-option
                                key="0"
                                label="请选择代理商"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in agentList"
                                :key="item.id"
                                :label="item.agent_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-button
                        size="medium"
                        type="primary"
                        @click="add('one')"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialog.dialog2.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <el-dialog
                top="1vh"
                title="批量导入充电宝"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog3.dialogVisible"
                width="35%"
        >

            <el-form label-position="left" label-width="100px">

                <el-form-item label="归属代理商">
                    <el-select style="width: 100%;" v-model="dialog.dialog3.form.agent_id"
                               placeholder="请选择代理商">
                        <el-option
                                key="0"
                                label="请选择代理商"
                                :value="0">
                        </el-option>
                        <el-option
                                v-for="item in agentList"
                                :key="item.id"
                                :label="item.agent_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Excel文件">
                    <el-upload
                            ref="uploadFile1"
                            style="width: 100%"
                            limit="1"
                            :headers="uploadHeader"
                            :file-list="dialog.dialog3.fileList"
                            :on-success="handeUploadFile1"
                            :on-remove="handleRemoveFile1"
                            drag
                            action="/api/op/excelFile"
                            multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip" style="color: red;">PS：只能上传.xsl/.excel文件，且不超过2mb</div>
                        <div class="el-upload__tip" slot="tip" style="color: red;">文件模板，<a style="color: #409EFF;"
                                                                                           target="_blank"
                                                                                           href="https://hicharge.net/uploads/Charging%20treasure%20import%20template.xls">点此下载</a>
                        </div>
                    </el-upload>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-button
                        size="medium"
                        type="primary"
                        @click="batchAdd"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialog.dialog3.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <el-dialog
                class="box"

                title="使用详情"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialog.dialog4.dialogVisible"
                width="95%"
        >


            <el-scrollbar style="height:100%" v-if="dialog.dialog4.dialogVisible">

                <rent-order :device_id="dialog.dialog4.device_id"></rent-order>

            </el-scrollbar>


            <span slot="footer" class="dialog-footer">

                <el-button style="margin-left: 10px" size="medium" type=""
                           @click="dialog.dialog4.dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>


        <el-table
                size="small"
                v-loading="table.loading"
                :data="table.list"
                stripe>
            <el-table-column
                    label=""
                    type="expand"

            >
                <template slot-scope="scope">
                    <el-form size="mini" label-position="left" class="demo-table-expand">
                        <el-form-item label="所在代理商">
                            <span v-if="scope.row.charecabinet && scope.row.charecabinet.agent">：{{scope.row.charecabinet.agent.agent_name}}</span>
                            <span v-else>未知</span>
                        </el-form-item>
                        <el-form-item label="所在商家">
                            <span v-if="scope.row.charecabinet && scope.row.charecabinet.merchants">{{scope.row.charecabinet.merchants.name}} </span>
                            <span v-else>未知</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>

            <el-table-column
                    label="充电宝ID"
                    prop="device_id"
            >
            </el-table-column>

            <el-table-column
                    label="所属代理"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.agent">{{ scope.row.agent.agent_name }}</span>
                    <span v-else>未知</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="现在所在机柜"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.now_cabinet_id">{{scope.row.now_cabinet_id}}</span>
                    <span v-else>未知</span>
                </template>
            </el-table-column>

            <!--            <el-table-column-->
            <!--                    label="所在机柜商家"-->
            <!--            >-->
            <!--                <template slot-scope="scope">-->
            <!--                    <div v-if="scope.row.charecabinet">-->

            <!--                        <el-popover trigger="hover" placement="top">-->
            <!--                            所属代理商-->
            <!--                        </el-popover>-->

            <!--                        <div v-if="scope.row.charecabinet.merchants">{{scope.row.charecabinet.merchants.name}}</div>-->
            <!--                    </div>-->
            <!--                    <div v-else></div>-->
            <!--                </template>-->
            <!--            </el-table-column>-->

            <el-table-column
                    label="创建时间"
            >
                <template slot-scope="scope">
                    <i class="el-icon-time"></i><span>{{scope.row.create_time}}</span>
                </template>
            </el-table-column>


            <el-table-column
                    label="状态"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 0">损坏</span>
                    <span v-else-if="scope.row.status == 1">正常</span>
                    <span v-else-if="scope.row.status == 2">非所有者机柜</span>
                    <span v-else>未知</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="使用次数"
            >
                <template slot-scope="scope">
                    <span>{{scope.row.use_total_count}}</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="弹出失败次数"
            >
                <template slot-scope="scope">
                    <span>{{scope.row.failNum}}</span>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="320">
                <template slot-scope="scope">

                    <el-button
                            v-if="permissions['api/op.orders'] && permissions['api/op.orders']['GET']"
                            size="mini"
                            @click="dialog.dialog4.dialogVisible = true;dialog.dialog4.device_id = scope.row.device_id">
                        <i style="margin-right: 2px;" class="el-icon-view"></i>查看使用详情
                    </el-button>

                    <el-button
                            v-if="permissions['api/op.mobilePower'] && permissions['api/op.mobilePower']['PUT']"
                            size="mini"
                            type="primary"
                            @click="dialog.dialog1.dialogVisible = true;dialog.dialog1.form.agent_id = scope.row.aid;dialog.dialog1.device_id_int = scope.row.id;">
                        <i style="margin-right: 2px;"
                           class="el-icon-edit"></i>修改
                    </el-button>

                    <el-popconfirm
                            v-if="permissions['api/op.mobilePower'] && permissions['api/op.mobilePower']['DELETE']"
                            title="确定要删除吗？"
                            @confirm="deletes(scope.$index, scope.row)"

                    >
                        <el-button style="margin-left: 10px" size="mini"
                                   type="danger"
                                   slot="reference"><i style="margin-right: 5px;" class="el-icon-error"/>删除
                        </el-button>
                    </el-popconfirm>

                </template>
            </el-table-column>
        </el-table>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getData"
                    :current-page="table.currentPage"
                    :page-size="table.pageSize"
                    :total="table.total">
            </el-pagination>
        </div>


    </div>

</template>

<script>

    import RentOrder from '../order/Order'

    export default {
        name: "Power",
        data() {
            return {
                search: {
                    deviceId: '',
                    charecabinetId: '',
                },
                uploadHeader: {
                    token: ''
                },
                dialog: {
                    dialog1: {
                        dialogVisible: false,
                        device_id_int: 0,
                        form: {
                            agent_id: 0,
                        },
                    },
                    dialog2: {
                        dialogVisible: false,
                        form: {
                            mode: 'one',
                            device_id: '',
                            agent_id: 0,
                        },
                    },
                    dialog3: {
                        dialogVisible: false,
                        fileList: [],
                        form: {
                            mode: 'multiple',
                            file_name: '',
                            agent_id: ''
                        },
                    },
                    dialog4: {
                        dialogVisible: false,
                        device_id: '',
                    }
                },
                table: {
                    list: [],
                    currentPage: 1,
                    total: 0,
                    pageSize: 20,
                    loading: true,
                    activeName: '1',
                    details: {},
                    form: {
                        note: ''
                    },
                },
                agentList: [],
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted() {
            this.setUploadHeader();
            this.getData();
            this.getAgentData();
        },
        methods: {
            //加载代理商数据
            getAgentData() {

                const _this = this;

                _this.axios.get('api/op/agent', {
                    params: {},
                    headers: {'page': 1, "page-size": _this.pageSize},
                }).then(function (response) {
                        if (response.status == 200 && response.data.status != undefined && response.data.status == 1) {
                            _this.allAgentList = response.data.data.list;
                            response.data.data.list.forEach(function (e) {
                                if (e.type == 1) {
                                    _this.agentList.push(e);
                                }
                            })
                        }
                    }
                ).catch(function () {

                });

            },
            setUploadHeader() {
                let user = JSON.parse(localStorage.getItem('user'));
                this.uploadHeader.token = user != undefined ? user.token : '';
            },
            getData(page) {
                const _this = this;

                if (page != undefined) {
                    _this.table.currentPage = page;
                }

                _this.table.loading = true;
                _this.axios.get('api/op/mobilePower', {
                    headers: {
                        'page': _this.table.currentPage,
                        "page-size": _this.table.pageSize,
                        "device-id": _this.search.deviceId,
                        "charecabinet-id": _this.search.charecabinetId,
                    }
                }).then(function (response) {
                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.table.list = response.data.data.list;
                            _this.table.total = response.data.data.total;
                            _this.table.loading = false;
                        }
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }
                });
            },
            // 搜索
            handleSearch() {

                const _this = this;
                _this.getData(1);

            },
            // 删除
            deletes(index, row) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/mobilePower/' + row.id, '').then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                        _this.getData();

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 编辑
            update() {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.put('/api/op/mobilePower/' + _this.dialog.dialog1.device_id_int, {agent_id: _this.dialog.dialog1.form.agent_id}).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                        _this.dialog.dialog1.dialogVisible = false;
                        _this.getData();

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 新增
            add() {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.post('/api/op/mobilePower', _this.dialog.dialog2.form).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                        _this.dialog.dialog2.dialogVisible = false;
                        _this.getData();

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 新增-批量
            batchAdd() {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.post('/api/op/mobilePower', _this.dialog.dialog3.form).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                        _this.dialog.dialog3.dialogVisible = false;
                        _this.getData();

                    } else {

                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });


                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 上传文件成功钩子
            handeUploadFile1(response, file, list) {
                const _this = this;
                if (response.status != undefined && response.status == 1) {
                    //啥也不干 ：P 是不可能的
                    _this.dialog.dialog3.form.file_name = response.data.file_name;
                } else {
                    let message = response.message == undefined ? '上传失败' : response.message;
                    _this.$message.error(message);
                    list.splice(list.length - 1, 1);
                }
            },
            // 移除文件钩子
            handleRemoveFile1(file) {
                const _this = this;
                _this.dialog.dialog3.form.file_name = '';
                console.log(file);
            },
            openDialog3() {
                const _this = this;
                _this.dialog.dialog3.form.agent_id = 0;
                _this.dialog.dialog3.form.file_name = '';
                if (_this.$refs['uploadFile1'] != undefined) {
                    _this.$refs['uploadFile1'].clearFiles();
                }
                _this.dialog.dialog3.dialogVisible = true;
            },

        },
        components: {
            RentOrder
        }
    }
</script>

<style scoped>

    /deep/ .el-upload--text .el-upload-dragger {
        width: 100%;
    }

    /deep/ .el-upload--text {
        width: 100%;
    }

    .box /deep/ .el-dialog {
        margin: 5vh auto !important;
    }

    .box /deep/ .el-dialog__body {
        height: 60vh;
        overflow: auto;
    }

</style>