<template>
    <div>


        <div style="margin-bottom: 10px;">

<!--            <template>-->
<!--                <el-select v-model="search.payType" placeholder="请选择支付类型">-->
<!--                    <el-option-->
<!--                            v-for="item in payTypeList"-->
<!--                            :key="item.type"-->
<!--                            :label="item.name"-->
<!--                            :value="item.type">-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--            </template>-->

            <el-input style="margin-left:10px;width: 250px;"
                      placeholder="请输入单号信息"
                      v-model="search.orderInfo"
                      clearable>
            </el-input>

            <el-input style="margin-left:10px;width: 200px;"
                      placeholder="请输入用户信息"
                      v-model="search.userInfo"
                      clearable>
            </el-input>

            <el-input style="margin-left:10px;width: 168px;"
                      placeholder="请输入用户ID"
                      v-model="search.uid"
                      clearable>
            </el-input>

            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch()">搜索
            </el-button>

        </div>

        <template>
            <el-table
                    size="mini"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="用户类型"
                        >
                    <template slot-scope="scope">

                        <div  v-if="scope.row.user">
                            <span  v-if="scope.row.user.user_type == 1">微信/小程序</span>
                            <span  v-else-if="scope.row.user.user_type == 3">手机号</span>
                            <span  v-else-if="scope.row.user.user_type == 11">微信/APP</span>
                            <span  v-else-if="scope.row.user.user_type == 12">支付宝/APP</span>
                            <span  v-else-if="scope.row.user.user_type == 13">Google</span>
                            <span  v-else-if="scope.row.user.user_type == 14">Apple</span>
                            <span  v-else-if="scope.row.user.user_type == 15">Line</span>
                            <span  v-else-if="scope.row.user.user_type == 16">Facebook</span>
                            <span  v-else> 未知</span>
                        </div>
                        <div v-else>未知</div>

                    </template>
                </el-table-column>

                <el-table-column style="line-height: 35px;"
                                 label="用户信息"
                                 width="300"
                                 >
                    <template  slot-scope="scope">
                        <div v-if="scope.row.user">
                            <el-popover trigger="hover" placement="top">

                                <p v-if="scope.row.user.picture && scope.row.user.user_type != 1 && scope.row.user.user_type != 3 &&  scope.row.user.user_type != 14">

                                    <img v-if="scope.row.user.picture" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>

                                </p>

                                <p v-if="scope.row.user.name && scope.row.user.user_type != 1 &&  scope.row.user.user_type != 3 ">APP昵称:{{
                                    scope.row.user.name}}</p>
                                <p>性别:
                                    <span v-if="scope.row.user.user_type == 1">
                                    <span v-if="scope.row.user.gender == 1">男</span>
                                    <span v-else-if="scope.row.user.gender == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                                    <span v-else-if="scope.row.user.user_type == 11">
                                    <span v-if="scope.row.user.app_sex == 1">男</span>
                                    <span v-else-if="scope.row.user.app_sex == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                                    <span v-else>
                                    <span>未知</span>
                                </span>
                                </p>
                                <p v-if="scope.row.user.mobile">手机号: +{{scope.row.user.country_code}}{{scope.row.user.mobile}}</p>
                                <p>语言:{{scope.row.user.language ? scope.row.user.language : 'zh-cn'}}</p>
                                <p>地区:
                                    <span v-if="scope.row.user.user_type == 1">
                                    {{scope.row.user.province}} {{scope.row.user.province}} {{scope.row.user.city}}
                                </span>
                                    <span v-else-if="scope.row.user.user_type == 11">
                                    {{scope.row.user.app_country}} {{scope.row.user.app_province}} {{scope.row.user.app_city}}
                                </span>
                                    <span v-else>
                                    <span></span>
                                </span>
                                </p>
                                <div slot="reference" class="name-wrapper" style="line-height: 40px;">


                                    <img v-if="scope.row.user.user_type == 1" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 3" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 11" :src="scope.row.user.app_headimgurl"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 12" :src="scope.row.user.ali_avatar"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 13" :src="scope.row.user.google_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 14" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 15" :src="scope.row.user.line_pictureUrl"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 16" :src="scope.row.user.facebook_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 16" :src="scope.row.user.facebook_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else style="width:40px;border-radius: 2px;"
                                         src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7cxCzyScSH79WkL5fqaGCm73xbS4tsWAp18BzvfPCp1kAK5NtWUt5KDCp6LQHr5ylR5jsshuSSKQ/132"/>

                                    <span style="position: relative;top:-12px;left:5px;" size="medium">
                                    <span v-if="scope.row.user.user_type == 1">{{ scope.row.user.name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 3">{{ scope.row.user.name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 11">{{ scope.row.user.app_nickname}}</span>
                                    <span v-else-if="scope.row.user.user_type == 12">{{ scope.row.user.ali_nick_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 13">{{ scope.row.user.google_display_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 14">{{ scope.row.user.apple_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 15">{{ scope.row.user.line_displayname}}</span>
                                    <span v-else-if="scope.row.user.user_type == 16">{{ scope.row.user.facebook_name}}</span>
                                    <span v-else>暂无</span>
                                </span>
                                </div>
                            </el-popover>
                        </div>
                        <div v-else>未知</div>
                    </template>


                </el-table-column>

                <el-table-column
                        label="充值信息"
                        width="400"
                        >
                    <template slot-scope="scope">

                        <div v-if="scope.row.topup">
                            <div><span >商家订单号：{{scope.row.topup.order_no}}</span></div>
                            <div><span >系统订单号：{{scope.row.topup.trade_no}}</span></div>
                            <div><span >充值金额：{{scope.row.topup.balance}}</span></div>
                            <div><span >已退金额：{{scope.row.topup.refundbalance}}</span></div>
                            <!--<div><span >剩余可退金额：{{scope.row.topup.balance - scope.row.topup.refundbalance}}</span></div>!-->
                        </div>
                        <div v-else>
                            <div><span >未知</span></div>
                        </div>

                    </template>
                </el-table-column>

                <el-table-column
                        label="退款信息"
                        width="300"
                        >
                    <template slot-scope="scope">
                        <div><span >退款序列号：{{scope.row.request_no}}</span></div>
                        <div><span >商家退款单号：{{scope.row.refund_no}}</span></div>
                        <div><span >申请时间：{{scope.row.create_time}}</span></div>
                        <div><span >退款金额：{{scope.row.refund_balance}}</span></div>
                    </template>
                </el-table-column>

                <el-table-column
                        label="退款状态"

                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">申请中</span>
                        <span v-else-if="scope.row.status == 2">已提交至支付平台</span>
                        <span v-else-if="scope.row.status == 3">退款中</span>
                        <span v-else-if="scope.row.status == 4">退款成功</span>
                        <span v-else-if="scope.row.status == 99">退款失败</span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="退款渠道"
                       >
                    <template slot-scope="scope">
                        <div v-if="scope.row.topup">
                            <span  v-if="scope.row.topup.pay_mode == 11">微信</span>
                            <span  v-else-if="scope.topup.row.pay_mode == 6">微信</span>
                            <span  v-else-if="scope.topup.row.pay_mode == 2">支付宝</span>
                            <span  v-else-if="scope.topup.row.pay_mode == 9">Paypal</span>
                            <span  v-else-if="scope.topup.row.pay_mode == 7">2c2p Credit card</span>
                            <span  v-else-if="scope.topup.row.pay_mode == 8">2c2p Cash card</span>
                            <span  v-else>未知</span>
                        </div>
                        <div v-else>
                            <div><span >未知</span></div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                        label="退款时间"
                       >
                    <template slot-scope="scope">
                        <div v-if="scope.row.refund_time"><i class="el-icon-time"></i><span >{{ scope.row.refund_time | dateFormat }}</span></div>
                    </template>
                </el-table-column>


            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    let Base64 = require('js-base64').Base64
    import Vue from 'vue'

    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2,'0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2,'0')

        //小时
        const hh = (dt.getHours()+'').padStart(2,'0')
        //分钟
        const mm = (dt.getMinutes()+'').padStart(2,'0')
        //秒数
        const ss = (dt.getSeconds()+'').padStart(2,'0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })


    export default {
        name: "Refund",
        props: [
            'uid'
        ],
        data() {
            return {
                search: {
                    payType: 0,
                    userInfo: '',
                    orderInfo: '',
                    uid: '',
                },
                row: {
                    balance: 0.00,
                    deposit: 0.00,
                },
                payTypeList: [
                    {name: '全部', type: 0},
                    {name: '微信', type: 11},
                    {name: '支付宝', type: 2},
                    {name: '信用卡', type: 7}, //App
                    {name: '储蓄卡', type: 8},
                    {name: 'Paypal', type: 9},
                ],
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],

            }
        },
        mounted: function () {
            const _this = this;
            if (_this.uid) {
                _this.search.uid = _this.uid;
            }
            _this.getListData();
        },
        methods: {
            //加载列表数据
            getListData(page) {

                const _this = this;
                _this.tabLoading = true;

                if (page != undefined) {
                    _this.currentPage = page;
                }


                _this.axios.get('api/op/refund', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'user-info': Base64.toBase64(_this.search.userInfo),
                        'order-info': _this.search.orderInfo,
                        'pay-type': _this.search.payType,
                        'uid': _this.search.uid,
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {

                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 搜索
            handleSearch() {

                const _this = this;


                _this.currentPage = 1;


                _this.getListData();


            },

        }
    }
</script>

<style scoped>

</style>