import { render, staticRenderFns } from "./UserStatistical.vue?vue&type=template&id=6fe69160&scoped=true&"
import script from "./UserStatistical.vue?vue&type=script&lang=js&"
export * from "./UserStatistical.vue?vue&type=script&lang=js&"
import style0 from "./UserStatistical.vue?vue&type=style&index=0&id=6fe69160&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe69160",
  null
  
)

export default component.exports