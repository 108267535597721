import axios from 'axios';

axios.interceptors.request.use(config => {

    if (config.url != 'api/op/token') {
        let user = JSON.parse(localStorage.getItem('user'));
        if(user == undefined){
            localStorage.removeItem('user');
            window.vm.$notify.error({
                title: '错误',
                message: '登录已过期，请重新登录'
            });
            setTimeout(function () {
                window.location.reload(true);
            }, 100)
            return false;
        }
        config.headers.token = user.token;
    }
    return config;

}, error => {

    return Promise.reject(error);

});

axios.interceptors.response.use(response => {

    if (response.data.data != null && response.data.data.error_code != undefined) {
        switch (response.data.data.error_code) {
            // request未携带TOKEN
            case 1001:
                setTimeout(function () {
                    window.location.reload(true);
                },100)
                break;
            // TOKEN失效
            case 1002:
                localStorage.removeItem('user');
                setTimeout(function () {
                    window.location.reload(true);
                },100)
                break;
            // 用户被删除或禁用
            case 1003:
                localStorage.removeItem('user');
                setTimeout(function () {
                    window.location.reload(true);
                },100)
                break;
            // 无操作权限
            case 1004:
                break;
        }

    }

    return response;
}, error => {
    if (error.response) {
        // 返回接口返回的错误信息
        return Promise.reject(error.response.data);
    }
});

export default axios;