<template>
    <div class="menu">
        <el-row class="tac">
            <el-col :span="12">
                <el-menu
                        :collapse="collapse"
                        :collapse-transition="false"
                        unique-opened="true"
                        :default-active="menuIndex"
                        class="el-menu-vertical-demo"
                        @open="handleOpen"
                        @close="handleClose">

                    <el-scrollbar style="height: 100%;">

                        <!--                    <el-row style="margin-left:1.5rem;margin-top: 1rem;margin-bottom: 1rem;font-size: 1.5rem;">-->
                        <!--                        <i class="el-icon-cpu"></i> Hi Charge 管理系统-->
                        <!--                    </el-row>-->
                        <el-submenu index="1">
                            <template slot="title">
                                <i class="el-icon-setting"></i>
                                <span v-if="!collapse">系统设置</span>
                            </template>
                            <el-menu-item index="1-1" @click="openTabs('/home', '首页')"><i class="el-icon-s-home"> </i>
                                <span >首页</span>
                            </el-menu-item>
                            <el-menu-item index="1-2"
                                          v-if="permissions['api/op.user'] && permissions['api/op.user']['GET']"
                                          @click="openTabs('/op-user', '员工管理')"><i
                                    class="el-icon-s-custom"></i>员工管理
                            </el-menu-item>
                            <el-menu-item index="1-3"
                                          v-if="permissions['api/op.userGroup'] && permissions['api/op.userGroup']['GET']"
                                          @click="openTabs('/user-group', '用户组管理')"><i
                                    class="el-icon-user-solid"></i>用户组管理
                            </el-menu-item>
<!--                            <el-menu-item index="1-4"-->
<!--                                          v-if="permissions['api/op.logs'] && permissions['api/op.logs']['GET']"-->
<!--                                          @click="openTabs('/logs', '操作日志')"><i-->
<!--                                    class="el-icon-message"></i>操作日志-->
<!--                            </el-menu-item>-->
                        </el-submenu>

                        <el-submenu index="3"
                                    v-if="permissions['api/op.members'] && permissions['api/op.members']['GET'] || permissions['api/op.merchants'] && permissions['api/op.merchants']['GET'] || permissions['api/op.agent'] && permissions['api/op.agent']['GET'] || permissions['api/op.salesman'] && permissions['api/op.salesman']['GET'] || permissions['api/op.orders'] && permissions['api/op.orders']['GET'] || permissions['api/op.coupons'] && permissions['api/op.coupons']['GET'] || permissions['api/op.advertising'] && permissions['api/op.advertising']['GET'] || permissions['api/op.helpArticles'] && permissions['api/op.helpArticles']['GET'] || permissions['api/op.cooperation'] && permissions['api/op.cooperation']['GET'] || permissions['api/op.problemReport'] && permissions['api/op.problemReport']['GET']">
                            <template slot="title">
                                <i class="el-icon-s-opportunity"></i>
                                <span v-if="!collapse">运营管理</span>
                            </template>


                            <el-menu-item-group
                                    v-if="permissions['api/op.members'] && permissions['api/op.members']['GET']">
                                <template slot="title">用户</template>
                                <el-menu-item index="3-1" @click="openTabs('/user', '用户列表')"><i
                                        class="el-icon-user-solid"></i>用户列表
                                </el-menu-item>
                            </el-menu-item-group>

                            <el-menu-item-group
                                    v-if="permissions['api/op.merchants'] && permissions['api/op.merchants']['GET']">
                                <template slot="title">商家</template>
                                <el-menu-item index="3-2" @click="openTabs('/merchants', '商家列表')"><i
                                        class="el-icon-s-shop"></i>商家列表
                                </el-menu-item>
                            </el-menu-item-group>

                            <el-menu-item-group>
                                <template slot="title"
                                          v-if="permissions['api/op.agent'] && permissions['api/op.agent']['GET'] || permissions['api/op.salesman'] && permissions['api/op.salesman']['GET']">
                                    代理
                                </template>
                                <el-menu-item index="3-3"
                                              v-if="permissions['api/op.agent'] && permissions['api/op.agent']['GET']"
                                              @click="openTabs('/agent', '代理商列表')"><i
                                        class="el-icon-s-custom"></i>代理商列表
                                </el-menu-item>
                                <el-menu-item index="3-4"
                                              v-if="permissions['api/op.salesman'] && permissions['api/op.salesman']['GET']"
                                              @click="openTabs('/salesman', '业务员列表')"><i
                                        class="el-icon-user"></i>业务员列表
                                </el-menu-item>
                            </el-menu-item-group>

                            <el-menu-item-group
                                    v-if="permissions['api/op.orders'] && permissions['api/op.orders']['GET']">
                                <template slot="title">订单</template>
                                <el-menu-item index="3-5" @click="openTabs('/rentOrder', '订单列表')"><i
                                        class="el-icon-s-order"></i>订单列表
                                </el-menu-item>
                            </el-menu-item-group>

                            <el-menu-item-group
                                    v-if="permissions['api/op.coupons'] && permissions['api/op.coupons']['GET'] || permissions['api/op.advertising'] && permissions['api/op.advertising']['GET']">
                                <template slot="title">营销</template>
                                <el-menu-item
                                        v-if="permissions['api/op.coupons'] && permissions['api/op.coupons']['GET']"
                                        index="3-6" @click="openTabs('/coupons', '优惠券')" e><i
                                        class="el-icon-present"></i>优惠券
                                </el-menu-item>
                                <el-menu-item
                                        v-if="permissions['api/op.advertising'] && permissions['api/op.advertising']['GET']"
                                        index="3-7" @click="openTabs('/advertising', '广告营销')"><i
                                        class="el-icon-shopping-cart-2"></i>广告营销
                                </el-menu-item>
                            </el-menu-item-group>

                            <el-menu-item-group
                                    v-if="permissions['api/op.helpArticles'] && permissions['api/op.helpArticles']['GET'] || permissions['api/op.cooperation'] && permissions['api/op.cooperation']['GET'] || permissions['api/op.problemReport'] && permissions['api/op.problemReport']['GET']">
                                <template slot="title">帮助中心</template>
                                <el-menu-item index="3-8"
                                              v-if="permissions['api/op.helpArticles'] && permissions['api/op.helpArticles']['GET']"
                                              @click="openTabs('/Helparticles', '常见问题')"><i
                                        class="el-icon-chat-dot-round"></i>常见问题
                                </el-menu-item>
                                <el-menu-item index="3-9"
                                              v-if="permissions['api/op.cooperation'] && permissions['api/op.cooperation']['GET']"
                                              @click="openTabs('/cooperation', '合作申请')"><i
                                        class="el-icon-bangzhu"></i>合作申请
                                </el-menu-item>
                                <el-menu-item index="3-10"
                                              v-if="permissions['api/op.problemReport'] && permissions['api/op.problemReport']['GET']"
                                              @click="openTabs('/problemReport', '投诉与意见')"><i
                                        class="el-icon-ice-cream-round"></i>投诉与意见
                                </el-menu-item>
                            </el-menu-item-group>

                        </el-submenu>

                        <el-submenu index="4"
                                    v-if="permissions['api/op.commission'] && permissions['api/op.commission']['GET'] || permissions['api/op.topup'] && permissions['api/op.topup']['GET'] || permissions['api/op.refund'] && permissions['api/op.refund']['GET'] || permissions['api/op.withdrawal'] && permissions['api/op.problemReport']['GET']">
                            <template slot="title">
                                <i class="el-icon-s-finance"></i>
                                <span v-if="!collapse">财务管理</span>
                            </template>
                            <el-menu-item
                                    v-if="permissions['api/op.commission'] && permissions['api/op.commission']['GET']"
                                    index="4-1" @click="openTabs('/commission', '佣金列表')"><i
                                    class="el-icon-s-marketing"></i>佣金列表
                            </el-menu-item>
                            <el-menu-item v-if="permissions['api/op.topup'] && permissions['api/op.topup']['GET']"
                                          index="4-2" @click="openTabs('/topup', '充值列表')"><i
                                    class="el-icon-s-order"></i>充值列表
                            </el-menu-item>
                            <el-menu-item v-if="permissions['api/op.refund'] && permissions['api/op.refund']['GET']"
                                          index="4-3" @click="openTabs('/refund', '退款列表')"><i
                                    class="el-icon-s-finance"></i>退款列表
                            </el-menu-item>
                          <el-menu-item v-if="permissions['api/op.refundApp'] && permissions['api/op.refundApp']['GET']"
                                        index="4-5" @click="openTabs('/refundApp', '退款列表(APP)')"><i
                              class="el-icon-s-finance"></i>退款列表(APP)
                          </el-menu-item>
                            <el-menu-item
                                    v-if="permissions['api/op.withdrawal'] && permissions['api/op.withdrawal']['GET']"
                                    index="4-4" @click="openTabs('/withdrawal', '提现列表')"><i
                                    class="el-icon-s-cooperation"></i>提现列表
                            </el-menu-item>
                        </el-submenu>

                        <el-submenu index="5"
                                    v-if="permissions['api/op.charecabinet'] && permissions['api/op.charecabinet']['GET'] || permissions['api/op.mobilePower'] && permissions['api/op.mobilePower']['GET'] || permissions['api/op.monitoring'] && permissions['api/op.monitoring']['GET'] || permissions['api/op.instruction'] && permissions['api/op.instruction']['GET']">
                            <template slot="title">
                                <i class="el-icon-s-shop"></i>
                                <span v-if="!collapse">设备管理</span>
                            </template>
                            <el-menu-item
                                    v-if="permissions['api/op.charecabinet'] && permissions['api/op.charecabinet']['GET']"
                                    index="5-1" @click="openTabs('/chargingtank', '机柜列表')"><i
                                    class="el-icon-s-platform"></i>机柜列表
                            </el-menu-item>
                            <el-menu-item
                                    v-if="permissions['api/op.mobilePower'] && permissions['api/op.mobilePower']['GET']"
                                    index="5-3" @click="openTabs('/power', '充电宝列表')"><i
                                    class="el-icon-s-cooperation"></i>充电宝列表
                            </el-menu-item>
                            <el-menu-item
                                    v-if="permissions['api/op.monitoring'] && permissions['api/op.monitoring']['GET']"
                                    index="5-2" @click="openTabs('/monitoring', '设备监控')"><i
                                    class="el-icon-printer"></i>设备监控
                            </el-menu-item>
                            <el-menu-item
                                    v-if="permissions['api/op.instruction'] && permissions['api/op.instruction']['GET']"
                                    index="5-4" @click="openTabs('/instruction', '发送指令')"><i
                                    class="el-icon-upload2"></i>发送指令
                            </el-menu-item>
                        </el-submenu>

                        <el-submenu index="6"
                                    v-if="permissions['api/op.businessStatistics'] && permissions['api/op.businessStatistics']['GET'] || permissions['api/op.userStatistics'] && permissions['api/op.userStatistics']['GET']">
                            <template slot="title">
                                <i class="el-icon-location"></i>
                                <span v-if="!collapse">数据统计</span>
                            </template>
                            <el-menu-item
                                    v-if="permissions['api/op.businessStatistics'] && permissions['api/op.businessStatistics']['GET']"
                                    index="6-1" @click="openTabs('/busines', '经营报表')"><i
                                    class="el-icon-s-marketing"></i>经营报表
                            </el-menu-item>
                            <el-menu-item
                                    v-if="permissions['api/op.userStatistics'] && permissions['api/op.userStatistics']['GET']"
                                    index="6-2" @click="openTabs('/userStatistics', '用户报表')"><i
                                    class="el-icon-s-order"></i>用户报表
                            </el-menu-item>
                        </el-submenu>

                        <div style="text-align: center; margin-top: 10px;cursor:pointer;">
                            <i v-if="!collapse" @click="collapse = true;" class="el-icon-s-fold" style="font-size: 20px;"></i>
                            <i v-else @click="collapse = false;" class="el-icon-s-unfold" style="font-size: 20px;"></i>
                        </div>

                    </el-scrollbar>


                </el-menu>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "Menu",
        props: [
            'permissions'
        ],
        data() {
            return {
                collapse: false,
                menuIndex:'1-1',
            }
        },
        methods: {
            openTabs(path, title) {
                this.$emit('menuClick', path, title)
            },
            openMenu(index) {
              // if (index == undefined){
              //   index = '1-1';
              // }
              console.log('ttttttttttt');
              console.log(index)
              this.menuIndex = index;
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>

<style scoped>

    .menu {

        height: 100%;
        background-color: #fff;
    }

    .menu .tac {
        height: 100%;
    }

    .menu .tac .el-col-12 {
        text-align: left;
        width: 100%;
        height: 100%;
    }

    .menu .tac .el-col-12 .el-menu {
        height: 100%;
    }


</style>