<template>
    <div >


        <el-button type="primary" style="position: relative;top:-25px;" size="mini" icon="el-icon-search" @click="handleSearch">刷新</el-button>
        <el-scrollbar style="height:50vh" >
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%"
            >

                <el-table-column
                        label="优惠券ID"
                >
                    <template slot-scope="scope">
                        {{scope.row.id}}
                    </template>
                </el-table-column>

                <el-table-column style="line-height: 35px;"
                                 label="用户信息"
                                 width="200"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.user">
                            <el-popover trigger="hover" placement="right">

                                <p v-if="scope.row.user.picture && scope.row.user.user_type != 1 && scope.row.user.user_type != 3 &&  scope.row.user.user_type != 14">

                                    <img v-if="scope.row.user.picture" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>

                                </p>

                                <p >
                                    UID:{{scope.row.user.id}}
                                </p>
                                <p v-if="scope.row.user.name && scope.row.user.user_type != 1 &&  scope.row.user.user_type != 3 ">
                                    APP昵称:{{
                                    scope.row.user.name}}</p>
                                <p>性别:
                                    <span v-if="scope.row.user.user_type == 1">
                                    <span v-if="scope.row.user.gender == 1">男</span>
                                    <span v-else-if="scope.row.user.gender == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                                    <span v-else-if="scope.row.user.user_type == 11">
                                    <span v-if="scope.row.user.app_sex == 1">男</span>
                                    <span v-else-if="scope.row.user.app_sex == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                                    <span v-else>
                                    <span>未知</span>
                                </span>
                                </p>
                                <p v-if="scope.row.user.mobile">手机号:
                                    +{{scope.row.user.country_code}}{{scope.row.user.mobile}}</p>
                                <p>语言:{{scope.row.user.language ? scope.row.user.language : 'zh-cn'}}</p>
                                <p>地区:
                                    <span v-if="scope.row.user.user_type == 1">
                                    {{scope.row.user.province}} {{scope.row.user.province}} {{scope.row.user.city}}
                                </span>
                                    <span v-else-if="scope.row.user.user_type == 11">
                                    {{scope.row.user.app_country}} {{scope.row.user.app_province}} {{scope.row.user.app_city}}
                                </span>
                                    <span v-else>
                                    <span></span>
                                </span>
                                </p>
                                <div slot="reference" class="name-wrapper" style="line-height: 40px;">


                                    <img v-if="scope.row.user.user_type == 1" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 3" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 11" :src="scope.row.user.app_headimgurl"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 12" :src="scope.row.user.ali_avatar"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 13" :src="scope.row.user.google_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 14" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 15"
                                         :src="scope.row.user.line_pictureUrl"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 16"
                                         :src="scope.row.user.facebook_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 16"
                                         :src="scope.row.user.facebook_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else style="width:40px;border-radius: 2px;"
                                         src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7cxCzyScSH79WkL5fqaGCm73xbS4tsWAp18BzvfPCp1kAK5NtWUt5KDCp6LQHr5ylR5jsshuSSKQ/132"/>

                                    <span style="position: relative;top:-12px;left:5px;" size="medium">
                                    <span v-if="scope.row.user.user_type == 1">{{ scope.row.user.name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 3">{{ scope.row.user.name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 11">{{ scope.row.user.app_nickname}}</span>
                                    <span v-else-if="scope.row.user.user_type == 12">{{ scope.row.user.ali_nick_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 13">{{ scope.row.user.google_display_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 14">{{ scope.row.user.apple_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 15">{{ scope.row.user.line_displayname}}</span>
                                    <span v-else-if="scope.row.user.user_type == 16">{{ scope.row.user.facebook_name}}</span>
                                    <span v-else>暂无</span>
                                </span>
                                </div>
                            </el-popover>
                        </div>
                        <div v-else>未知</div>
                    </template>


                </el-table-column>


                <el-table-column
                        label="优惠券名称"

                >
                    <template slot-scope="scope">
                        <span>{{scope.row.name}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="优惠券类型"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">抵扣券</span>
                        <span v-else-if="scope.row.type == 2">折扣券</span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="折扣"
                >
                    <template slot-scope="scope">
                        <span  v-if="scope.row.type == 1">{{scope.row.amount}}铢</span>
                        <span v-else-if="scope.row.type == 2">{{scope.row.rate}}%</span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="状态"
                >
                    <template slot-scope="scope">
                        <span  v-if="scope.row.status == 0">未使用</span>
                        <span v-else-if="scope.row.status == 1">已使用</span>
                        <span v-else-if="scope.row.status == 2">过期</span>
                        <span v-else-if="scope.row.status == 3">管理员设为失效</span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="获取渠道"

                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.channel == 1">系统赠送({{scope.row.remark}})</span>
                        <span v-else-if="scope.row.channel == 2">兑换码兑换（{{scope.row.token}}）</span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="时间信息"
                        width="250"
                >
                    <template slot-scope="scope">
                        <div>
                            <i class="el-icon-timer"/>
                            <span>获得时间：{{scope.row.expire_time|dateFormat}}</span>
                        </div>
                        <div>
                            <i class="el-icon-timer"/>
                            <span>过期时间：{{scope.row.expire_time|dateFormat}}</span>
                        </div>
                        <div>
                            <i class="el-icon-timer"/>
                            <span>使用时间：<span v-if="scope.row.use_time">{{scope.row.use_time|dateFormat}}</span><span v-else></span></span>
                        </div>
                    </template>
                </el-table-column>

            </el-table>
        </el-scrollbar>


        <div style="margin-top:10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import Vue from 'vue'

    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2, '0')

        //小时
        const hh = (dt.getHours() + '').padStart(2, '0')
        //分钟
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        //秒数
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })

    export default {
        name: "UserCoupons",
        props: [
            'couponsId'
        ],
        data() {
            return {
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
                userCouponList:{
                    dialogVisible: false,
                    pageSize: 20,
                    currentPage: 1,
                    tableTotal: 0,
                    tabLoading: false,
                    tableData: [],
                }
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        mounted: function () {
            this.getListData();
        },
        methods: {
            // 获取数据列表
            getListData(page) {

                const _this = this;
                _this.tabLoading = true;
                if (page != undefined) {
                    _this.currentPage = page;
                }

                _this.axios.get('api/op/coupons', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'coupons-type-id': _this.couponsId,
                        'action':'coupons-all'
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 搜索
            handleSearch() {
                const _this = this;
                _this.getListData();
            },

        }
    }
</script>

<style scoped>


</style>