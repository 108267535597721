<template>
  <div>


    <div style="margin-bottom: 10px;">
      <template>

        <el-select v-model="search.couponsType" placeholder="请选择优惠券类型">
          <el-option
              v-for="item in typeList"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>

      </template>


      <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch">搜索
      </el-button>
      <el-button v-if="permissions['api/op.coupons'] && permissions['api/op.coupons']['POST']" type="success"
                 icon="el-icon-search" @click="openDialog3">添加
      </el-button>

    </div>


    <el-dialog
        class="box"
        :title="'已发放优惠券：' + userCouponList.name"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="userCouponList.dialogVisible"
        width="80%"

    >

      <user-coupons :couponsId="userCouponList.couponsId" v-if="userCouponList.dialogVisible"></user-coupons>

      <span slot="footer" class="dialog-footer">
                <el-button @click="userCouponList.dialogVisible = false">关闭</el-button>
            </span>
    </el-dialog>

    <el-dialog
        class="box"
        :title="'兑换码管理：' + couponTokenList.name"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="couponTokenList.dialogVisible"
        width="80%"

    >

      <coupons-token :couponsId="couponTokenList.couponsId" :name="couponTokenList.name"
                     v-if="couponTokenList.dialogVisible"></coupons-token>

      <span slot="footer" class="dialog-footer">
                <el-button @click="couponTokenList.dialogVisible = false">关闭</el-button>
            </span>
    </el-dialog>

    <el-dialog
        class="box"
        title="新增优惠券"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="addCoupons.dialogVisible"
        width="50%"

    >

      <el-form ref="add_form" :rules="vaildateRules" :validate-on-rule-change="false" :model="addCouponsForm"
               label-width="130px"
               style="padding-right: 20px;">

        <template>

          <el-tabs tab-position="left">

            <el-tab-pane label="基本设置">

              <el-form-item label="优惠券名称" prop="name">
                <el-input show-word-limit maxlength="32" v-model="addCouponsForm.name" ></el-input>
              </el-form-item>
              <el-form-item label="领取后有效期(天)" prop="expire_time">
                <el-input-number v-model="addCouponsForm.expire_time"  :min="1" :max="365" label="领取后有效期（天）"></el-input-number>
              </el-form-item>
              <el-form-item label="优惠券类型" prop="type">
                <el-select v-model="addCouponsForm.type" placeholder="请选择">
                  <el-option
                      v-for="item in couponsTypeList"
                      :key="item.type"
                      :label="item.name"
                      :value="item.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="addCouponsForm.type == 1" label="抵扣金额" >
                <el-input-number v-model="addCouponsForm.amount"  :min="1" :max="1000" label="抵扣金额"></el-input-number>
              </el-form-item>
              <el-form-item v-if="addCouponsForm.type == 2" label="折扣率" >
                <el-slider v-model="addCouponsForm.rate"></el-slider>
              </el-form-item>

            </el-tab-pane>

          </el-tabs>
        </template>

      </el-form>


      <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit()">确 定</el-button>
                <el-button @click="addCoupons.dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
        class="box"
        title="编辑优惠券"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="editCoupons.dialogVisible"
        width="50%"

    >

      <el-form ref="edit_form" :rules="vaildateRules" :validate-on-rule-change="false" :model="editCouponsForm"
               label-width="130px"
               style="padding-right: 20px;">

        <template>

          <el-tabs tab-position="left">

            <el-tab-pane label="基本设置">

              <el-form-item label="优惠券名称" prop="name">
                <el-input show-word-limit maxlength="32" v-model="editCouponsForm.name" ></el-input>
                <el-input type="hidden"  v-model="editCouponsForm.id" ></el-input>
              </el-form-item>
              <el-form-item label="领取后有效期(天)" prop="expire_time">
                <el-input-number v-model="editCouponsForm.expire_time"  :min="1" :max="365" label="领取后有效期（天）"></el-input-number>
              </el-form-item>
              <el-form-item label="优惠券类型" prop="type">
                <el-select v-model="editCouponsForm.type" placeholder="请选择">
                  <el-option
                      v-for="item in couponsTypeList"
                      :key="item.type"
                      :label="item.name"
                      :value="item.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="editCouponsForm.type == 1" label="抵扣金额" >
                <el-input-number v-model="editCouponsForm.amount"  :min="1" :max="1000" label="抵扣金额"></el-input-number>
              </el-form-item>
              <el-form-item v-if="editCouponsForm.type == 2" label="折扣率" >
                <el-slider v-model="editCouponsForm.rate"></el-slider>
              </el-form-item>

            </el-tab-pane>

          </el-tabs>
        </template>

      </el-form>


      <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit2()">确 定</el-button>
                <el-button @click="editCoupons.dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>


    <template>
      <el-table
          size="small"
          v-loading="tabLoading"
          :data="tableData"
          style="width: 100%">

        <el-table-column
            label="优惠券ID"
        >
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>


        <el-table-column
            label="优惠券名称"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>

        <el-table-column
            label="优惠券类型"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">抵扣券</span>
            <span v-else-if="scope.row.type == 2">折扣券</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>

        <el-table-column
            label="折扣"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">{{ scope.row.amount }}铢</span>
            <span v-else-if="scope.row.type == 2">{{ scope.row.rate }}%</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>

        <el-table-column
            label="有效期"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.expire_time }}</span>天
            <i class="el-icon-timer"/>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="400">
          <template slot-scope="scope">
            <el-button
                v-if="permissions['api/op.coupons'] && permissions['api/op.coupons']['GET']"
                size="mini"
                type="primary"
                @click="openDialog(scope.$index, scope.row)"><i class="el-icon-tickets"
                                                                style="margin-right: 5px;"/>已发放列表
            </el-button>
            <el-button
                v-if="permissions['api/op.couponsToken'] && permissions['api/op.couponsToken']['GET']"
                size="mini"
                type="success"
                @click="openDialog2(scope.$index, scope.row)"><i class="el-icon-present"
                                                                 style="margin-right: 5px;"/>兑换码管理
            </el-button>
            <el-button
                v-if="permissions['api/op.coupons'] && permissions['api/op.coupons']['PUT']"
                size="mini"
                type="danger"
                @click="openDialog4(scope.$index, scope.row)"><i class="el-icon-edit"
                                                                style="margin-right: 5px;"/>修改
            </el-button>

          </template>
        </el-table-column>
      </el-table>
    </template>

    <div style="margin-top: 10px;">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getListData"
          :page-size="pageSize"
          :total="tableTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>

import UserCoupons from '@/page/home/coupons/UserCoupons'
import CouponsToken from '@/page/home/coupons/CouponsToken'

var validateType = (rule, value, callback) => {
  if (value == '0') {
    callback(new Error('请选择优惠券类型'));
  }
  callback();
};




export default {
  name: "Coupons",
  components: {
    UserCoupons,
    CouponsToken
  },
  data() {
    return {
      search: {
        status: 0,
        couponsType: 0,
        time: null,
      },
      typeList: [
        {name: '全部', type: 0},
        {name: '抵扣', type: 1},
        {name: '打折', type: 2},
      ],
      pageSize: 20,
      currentPage: 1,
      tableTotal: 0,
      tabLoading: false,
      tableData: [],
      //已经派发的优惠券列表
      userCouponList: {
        dialogVisible: false,
        couponsId: 0,
        name: ''
      },
      //优惠券兑换码列表
      couponTokenList: {
        dialogVisible: false,
        couponsId: 0,
        name: '',
      },
      //新增优惠券
      addCoupons: {
        dialogVisible: false,
      },
      //新增优惠券表单
      addCouponsForm: {
        name:'',
        type:0,
        rate:90,
        amount:5,
        expire_time:31
      },
      //编辑优惠券
      editCoupons:{
        dialogVisible: false,
      },
      //编辑优惠券表单
      editCouponsForm: {

      },
      //优惠券类型列表
      couponsTypeList:[
        {
          name:'未选择',
          type:0
        },
        {
          name:'抵扣券',
          type:1
        },
        {
          name:'折扣券',
          type:2
        }
      ],
      vaildateRules: {
        name: [
          {required: true, message: '请填写优惠券名称', trigger: 'blur'},
        ],
        type: [
          {validator: validateType, trigger: 'blur'},
          {required: true, message: '请选择优惠券类型', trigger: 'blur'},
        ],
        expire_time: [
          {required: true, message: '请填写有效期', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    permissions() {
      return this.$store.state.user.allPermissions;
    }
  },
  mounted: function () {
    this.getListData();
  },
  methods: {
    // 获取列表数据
    getListData(page) {

      const _this = this;
      _this.tabLoading = true;
      if (page != undefined) {
        _this.currentPage = page;
      }

      _this.axios.get('api/op/coupons', {
        params: {},
        headers: {
          'page': _this.currentPage,
          "page-size": _this.pageSize,
          'coupons-type': _this.search.couponsType,
          'action': 'coupons'
        },
      }).then(function (response) {


            if (response.status != 200 || response.data.status == undefined) {
              _this.$message.error('加载数据失败，正在重试');
            } else if (response.data.status == 0) {
              _this.$message.error(response.data.message);
            } else {
              _this.tableData = [];
              _this.tableTotal = response.data.data.total;
              _this.tableData = response.data.data.list;

            }
            _this.tabLoading = false;
          }
      ).catch(function (error) {
        console.log(error);
        if (error.response.status != 200 || error.response.data.status == undefined) {
          _this.$message.error('加载数据失败，正在重试');
        } else if (error.response.data.status == 0) {
          _this.$message.error(error.response.data.message);
        }

        _this.tabLoading = false;

      });
    },
    // 打开已发放优惠券谈层
    openDialog(index, row) {
      const _this = this;
      _this.userCouponList.dialogVisible = true;
      _this.userCouponList.couponsId = row.id;
      _this.userCouponList.name = row.name;

    },
    // 打开优惠券兑换码管理谈层
    openDialog2(index, row) {
      const _this = this;
      _this.couponTokenList.dialogVisible = true;
      _this.couponTokenList.couponsId = row.id;
      _this.couponTokenList.name = row.name;

    },
    // 打开新增优惠券弹层
    openDialog3() {
      const _this = this;
      if (_this.$refs['add_form'] != undefined) {
        _this.$refs['add_form'].clearValidate();
      }
      _this.addCoupons.dialogVisible = true;
      _this.addCouponsForm = {
        name:'',
        type:0,
        rate:90,
        amount:5,
        expire_time:31
      };
    },
    // 打开编辑优惠券弹层
    openDialog4(index, rows) {
      const _this = this;
      if (_this.$refs['edit_form'] != undefined) {
        _this.$refs['edit_form'].clearValidate();
      }
      _this.editCoupons.dialogVisible = true;
      _this.editCouponsForm = JSON.parse(JSON.stringify(rows))

    },
    // 搜索
    handleSearch() {
      const _this = this;
      _this.getListData();
    },
    //新增优惠券表单提交
    submit() {
      const _this = this;

      this.$refs['add_form'].validate((valid) => {
        if (valid) {

          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          const requestData = JSON.parse(JSON.stringify(_this.addCouponsForm));
          _this.axios.post('/api/op/coupons', requestData).then(function (response) {

            loading.close();

            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

            if (response.data.status != undefined && response.data.status == 1) {

              _this.$notify({
                title: '成功',
                message: message,
                type: 'success'
              });
              _this.addCoupons.dialogVisible = false;
              _this.getListData();
            } else {
              _this.$notify.error({
                title: '错误',
                message: message
              });
            }

          }).catch(function (error) {

            loading.close();
            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

            _this.$notify.error({
              title: '错误',
              message: msg
            });

          })


        } else {
          _this.$notify.error({
            title: '错误',
            message: '请按照要求填写信息'
          });
        }
      });

    },
    //编辑优惠券表单提交
    submit2() {
      const _this = this;

      this.$refs['edit_form'].validate((valid) => {
        if (valid) {

          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          const requestData = JSON.parse(JSON.stringify(_this.editCouponsForm));
          _this.axios.put('/api/op/coupons/' + requestData.id, requestData).then(function (response) {

            loading.close();

            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

            if (response.data.status != undefined && response.data.status == 1) {

              _this.$notify({
                title: '成功',
                message: message,
                type: 'success'
              });
              _this.editCoupons.dialogVisible = false;
              _this.getListData();
            } else {
              _this.$notify.error({
                title: '错误',
                message: message
              });
            }

          }).catch(function (error) {

            loading.close();
            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

            _this.$notify.error({
              title: '错误',
              message: msg
            });

          })


        } else {
          _this.$notify.error({
            title: '错误',
            message: '请按照要求填写信息'
          });
        }
      });

    },
  }
}
</script>

<style scoped>


</style>