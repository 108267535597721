<template>
    <div>


        <div style="margin-bottom: 10px;">
            <template>

                <el-select v-model="search.cooperationType" placeholder="请选择用户类型">
                    <el-option
                            v-for="item in cooperationTypeList"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                    </el-option>
                </el-select>


                <el-date-picker style="margin-left: 10px;"
                                v-model="search.time"
                                :picker-options="pickerOptions"
                                value-format="timestamp"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right">
                </el-date-picker>

            </template>


            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch">搜索
            </el-button>

        </div>

        <el-dialog
                top="1vh"
                :title="dialogTitle"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="handleDialogClose"
                :visible.sync="dialogVisible"
                width="35%"
        >

            <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请输入备注"
                    v-model="form.note">
            </el-input>


            <span slot="footer" class="dialog-footer">

                <el-button
                        size="medium"
                        type="primary"
                        @click="note"
                >
                    确定
                </el-button>
                <el-button style="margin-left: 10px" size="medium" type="" @click="dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <template>
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="合作类型"
                        width="250">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">装机合作</span>
                        <span v-else-if="scope.row.type == 2">广告合作</span>
                        <span v-else-if="scope.row.type == 3">其他合作</span>
                    </template>
                </el-table-column>


                <el-table-column
                        label="商户名称"
                        width="300"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.company}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="联系人姓名"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.name}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="联系人手机号"
                >
                    <template slot-scope="scope">
                        <span>+{{scope.row.mobile_phone_number}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="处理状态"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">待处理</span>
                        <span v-else-if="scope.row.status == 2" style="color: red;">已处理</span>
                        <span v-else-if="scope.row.status == 3" style="color: red;">删除</span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="备注"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.remark}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="申请时间"
                        width="200"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-timer"/>
                        <span>{{scope.row.create_time}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="" width="350">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="primary"
                                @click="openDialog(scope.$index, scope.row)"><i class="el-icon-info"
                                                                                style="margin-right: 5px;"/>添加备注
                        </el-button>

                        <el-popconfirm
                                title="确定要进行设为已处理吗？"
                                v-if="scope.row.status == 1"
                                @confirm="confirmHandle(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="success"
                                       slot="reference"><i style="margin-right: 5px;" class="el-icon-success"/>设为已处理
                            </el-button>
                        </el-popconfirm>

                        <el-popconfirm
                                title="确定要删除吗？"
                                @confirm="deleteCooperation(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="danger"
                                       slot="reference"><i style="margin-right: 5px;" class="el-icon-error"/>删除
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>


    export default {
        name: "Cooperation",
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '7天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '30天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '90天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '180天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '3650天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3650);
                            picker.$emit('pick', [start, end]);
                        },
                    }]
                },
                search: {
                    status: 0,
                    cooperationType: 0,
                    time: null,
                },
                cooperationTypeList: [
                    {name: '全部', type: 0},
                    {name: '装机合作', type: 1},
                    {name: '广告合作', type: 2},
                    {name: '其他合作', type: 3},
                ],
                details: {
                    order_num: '',
                    merchants: {},
                    status_notes: '',
                },
                form: {
                    note: ''
                },
                dialogTitle: '添加备注',
                dialogVisible: false,
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
            }
        },
        mounted: function () {
            this.getListData();

        },
        methods: {
            // 获取合作列表
            getListData(page) {

                const _this = this;
                _this.tabLoading = true;
                if (page != undefined) {
                    _this.currentPage = page;
                }

                _this.axios.get('api/op/cooperation', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'cooperation-type': _this.search.cooperationType,
                        'start-time': _this.search.time != null ? _this.search.time[0] / 1000 : '',
                        'end-time': _this.search.time != null ? _this.search.time[1] / 1000 : '',
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 打开订单详情弹层
            openDialog(index, row) {
                const _this = this;
                _this.dialogVisible = true;
                _this.details = JSON.parse(JSON.stringify(row));
                _this.form.note = row.remark;
            },
            // 添加备注
            note() {

                const _this = this;

                if (_this.form.note == ''){

                    _this.$notify.error({
                        title: '错误',
                        message: '请填写备注'
                    });
                    return false;

                }

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                let rquestOption = {
                    params: {
                        'note': _this.form.note
                    },
                    headers: {
                        action: 'note'
                    }
                };

                _this.axios.put('/api/op/cooperation/' + _this.details.id, '', rquestOption).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.dialogVisible = false;
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 确认处理
            confirmHandle(index, row) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                let rquestOption = {
                    params: {},
                    headers: {
                        action: 'handle'
                    }
                };

                _this.axios.put('/api/op/cooperation/' + row.id, '', rquestOption).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.dialogVisible = false;
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 删除
            deleteCooperation(index, row) {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/cooperation/' + row.id, '').then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.dialogVisible = false;
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 搜索
            handleSearch() {
                const _this = this;
                _this.getListData();
            },

        }
    }
</script>

<style scoped>


</style>