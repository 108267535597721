<template>
  <div>


    <div style="margin-left: -10px;">
      <el-input style="margin-left:10px;width: 150px;"
                placeholder="请输入用户信息"
                v-model="search.userInfo"
                clearable>
      </el-input>
      <el-input style="margin-left:10px;width: 150px;"
                placeholder="请输入订单号"
                v-model="search.orderInfo"
                clearable>
      </el-input>
      <el-input style="margin-left:10px;width: 150px;"
                placeholder="请输入UID"
                v-model="search.uid"
                clearable>
      </el-input>
      <el-select style="margin-left: 10px;" v-model="search.status" placeholder="请选择状态">
        <el-option
            v-for="item in statusList"
            :key="item.type"
            :label="item.name"
            :value="item.type">
        </el-option>
      </el-select>

      <el-button style="margin-left:10px;" type="primary" icon="el-icon-search"   @click="handleSearch">刷新
      </el-button>
    </div>







    <template>
      <el-table
          size="small"
          v-loading="tabLoading"
          :data="tableData"
          style="width: 100%">

        <el-table-column
            label="提现单号"
            width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.order_no }}
          </template>
        </el-table-column>

        <el-table-column style="line-height: 35px;"
                         label="用户信息"
                         width="200"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.user">
              <el-popover trigger="hover" placement="right">

                <p v-if="scope.row.user.picture && scope.row.user.user_type != 1 && scope.row.user.user_type != 3 &&  scope.row.user.user_type != 14">

                  <img v-if="scope.row.user.picture" :src="scope.row.user.picture"
                       style="width:40px;border-radius: 2px;"/>

                </p>

                <p >
                  UID:{{scope.row.user.id}}
                </p>
                <p v-if="scope.row.user.name && scope.row.user.user_type != 1 &&  scope.row.user.user_type != 3 ">
                  APP昵称:{{
                    scope.row.user.name}}</p>
                <p>性别:
                  <span v-if="scope.row.user.user_type == 1">
                                    <span v-if="scope.row.user.gender == 1">男</span>
                                    <span v-else-if="scope.row.user.gender == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                  <span v-else-if="scope.row.user.user_type == 11">
                                    <span v-if="scope.row.user.app_sex == 1">男</span>
                                    <span v-else-if="scope.row.user.app_sex == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                  <span v-else>
                                    <span>未知</span>
                                </span>
                </p>
                <p v-if="scope.row.user.mobile">手机号:
                  +{{scope.row.user.country_code}}{{scope.row.user.mobile}}</p>
                <p>语言:{{scope.row.user.language ? scope.row.user.language : 'zh-cn'}}</p>
                <p>地区:
                  <span v-if="scope.row.user.user_type == 1">
                                    {{scope.row.user.province}} {{scope.row.user.province}} {{scope.row.user.city}}
                                </span>
                  <span v-else-if="scope.row.user.user_type == 11">
                                    {{scope.row.user.app_country}} {{scope.row.user.app_province}} {{scope.row.user.app_city}}
                                </span>
                  <span v-else>
                                    <span></span>
                                </span>
                </p>
                <div slot="reference" class="name-wrapper" style="line-height: 40px;">


                  <img v-if="scope.row.user.user_type == 1" :src="scope.row.user.picture"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else-if="scope.row.user.user_type == 3" :src="scope.row.user.picture"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else-if="scope.row.user.user_type == 11" :src="scope.row.user.app_headimgurl"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else-if="scope.row.user.user_type == 12" :src="scope.row.user.ali_avatar"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else-if="scope.row.user.user_type == 13" :src="scope.row.user.google_picture"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else-if="scope.row.user.user_type == 14" :src="scope.row.user.picture"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else-if="scope.row.user.user_type == 15"
                       :src="scope.row.user.line_pictureUrl"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else-if="scope.row.user.user_type == 16"
                       :src="scope.row.user.facebook_picture"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else-if="scope.row.user.user_type == 16"
                       :src="scope.row.user.facebook_picture"
                       style="width:40px;border-radius: 2px;"/>
                  <img v-else style="width:40px;border-radius: 2px;"
                       src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7cxCzyScSH79WkL5fqaGCm73xbS4tsWAp18BzvfPCp1kAK5NtWUt5KDCp6LQHr5ylR5jsshuSSKQ/132"/>

                  <span style="position: relative;top:-12px;left:5px;" size="medium">
                                    <span v-if="scope.row.user.user_type == 1">{{ scope.row.user.name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 3">{{ scope.row.user.name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 11">{{ scope.row.user.app_nickname}}</span>
                                    <span v-else-if="scope.row.user.user_type == 12">{{ scope.row.user.ali_nick_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 13">{{ scope.row.user.google_display_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 14">{{ scope.row.user.apple_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 15">{{ scope.row.user.line_displayname}}</span>
                                    <span v-else-if="scope.row.user.user_type == 16">{{ scope.row.user.facebook_name}}</span>
                                    <span v-else>暂无</span>
                                </span>
                </div>
              </el-popover>
            </div>
            <div v-else>未知</div>
          </template>


        </el-table-column>


        <el-table-column
            label="姓名"
        >
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>

        <el-table-column
            label="银行卡号"
        >
          <template slot-scope="scope">
            {{ scope.row.card_no }}
          </template>
        </el-table-column>

        <el-table-column
            label="开户银行"
        >
          <template slot-scope="scope">
            {{ scope.row.bank_name }}
          </template>
        </el-table-column>

        <el-table-column
            label="充值总金额"
        >
          <template slot-scope="scope">
            {{ scope.row.total_amount }}
          </template>
        </el-table-column>

        <el-table-column
            label="用户余额"
        >
          <template slot-scope="scope">
            {{ scope.row.user ? scope.row.user.balance : '未知' }}
          </template>
        </el-table-column>

        <el-table-column
            label="已提现金额"
        >
          <template slot-scope="scope">
            {{ scope.row.refund_amount }}
          </template>
        </el-table-column>

        <el-table-column
            label="本次提现金额"
        >
          <template slot-scope="scope">
            {{ scope.row.amount }}
          </template>
        </el-table-column>



        <el-table-column
            label="状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">提现完成</span>
            <span v-else-if="scope.row.status == 0">提现</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>

        <el-table-column
            label="时间"
            width="250"
        >
          <template slot-scope="scope">
            申请时间：
            <span>{{ scope.row.create_time}}</span>
            <i class="el-icon-timer"/>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">


            <template  v-if="permissions['api/op.couponsToken'] && permissions['api/op.couponsToken']['GET']">
              <el-popconfirm

                  cancel-button-text='取消'
                  confirm-button-text='确定'
                  icon="el-icon-info"
                  icon-color="red"
                  @confirm="submit(scope.row)"
                  title="确定要进行打款操作吗？"
              >
                <el-button  :disabled="scope.row.status != 0 ? true : false" type="success" slot="reference" style="margin-right: 5px;" size="mini"><i class="el-icon-present"></i> 打款</el-button>
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <div style="margin-top: 10px;">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getListData"
          :page-size="pageSize"
          :total="tableTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>


let Base64 = require('js-base64').Base64

export default {
  name: "RefundApp",
  data() {
    return {
      search: {
        status: -1,
        time: null,
        userInfo: '',
        orderInfo: '',
        uid: '',
      },
      statusList: [
        {name: '全部', type: -1},
        {name: '提现中', type: 0},
        {name: '提现已完成', type: 1},
      ],
      pageSize: 20,
      currentPage: 1,
      tableTotal: 0,
      tabLoading: false,
      tableData: [],
    }
  },
  computed: {
    permissions() {
      return this.$store.state.user.allPermissions;
    }
  },
  mounted: function () {
    this.getListData();
  },
  methods: {
    // 获取列表数据
    getListData(page) {

      const _this = this;
      _this.tabLoading = true;
      if (page != undefined) {
        _this.currentPage = page;
      }

      _this.axios.get('api/op/RefundApp', {
        params: {},
        headers: {
          'page': _this.currentPage,
          "page-size": _this.pageSize,
          "user-info": Base64.toBase64(_this.search.userInfo),
          "order-info": Base64.toBase64(_this.search.orderInfo),
          'status':_this.search.status,
          'uid':_this.search.uid,
        },
      }).then(function (response) {


            if (response.status != 200 || response.data.status == undefined) {
              _this.$message.error('加载数据失败，正在重试');
            } else if (response.data.status == 0) {
              _this.$message.error(response.data.message);
            } else {
              _this.tableData = [];
              _this.tableTotal = response.data.data.total;
              _this.tableData = response.data.data.list;

            }
            _this.tabLoading = false;
          }
      ).catch(function (error) {
        console.log(error);
        if (error.response.status != 200 || error.response.data.status == undefined) {
          _this.$message.error('加载数据失败，正在重试');
        } else if (error.response.data.status == 0) {
          _this.$message.error(error.response.data.message);
        }

        _this.tabLoading = false;

      });
    },
    // 搜索
    handleSearch() {
      const _this = this;
      _this.getListData();
    },
    //打款操作
    submit(rows) {
      const _this = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _this.axios.put('/api/op/RefundApp/' + rows.order_no, {}).then(function (response) {
        loading.close();
        const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;
        if (response.data.status != undefined && response.data.status == 1) {
          _this.$notify({
            title: '成功',
            message: message,
            type: 'success'
          });
          _this.getListData();
        } else {
          _this.$notify.error({
            title: '错误',
            message: message
          });
        }
      }).catch(function (error) {
        loading.close();
        let msg = error.response.data.message == undefined ? error.message : error.response.data.message;
        _this.$notify.error({
          title: '错误',
          message: msg
        });

      })
    },
  }
}
</script>

<style scoped>


</style>