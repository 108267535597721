<template>
    <div>statistical</div>
</template>

<script>
    export default {
        name: "statistical"
    }
</script>

<style scoped>

</style>