import Vue from 'vue'
import App from './App.vue'
import axios from './common/axios';
import store from './common/store/user.js';

Vue.prototype.axios = axios;
Vue.config.productionTip = false

Vue.prototype.$store = store

new Vue({
    render: h => h(App),
    store: store,
}).$mount('#app')
