<template>
    <div>

        <template>

            <el-button v-if="permissions['api/op.user'] && permissions['api/op.user']['POST']"  style="margin-bottom: 10px;" type="primary" @click="openDialog('新增用户', 'insert')">添加
            </el-button>

            <el-dialog

                    :title="dialogTitle"
                    :visible.sync="dialogVisible"
                    width="25%"
            >


                <el-form :rules="rules" ref="form" :model="form" label-width="50px">
                    <el-form-item label="账号" ref="form" prop="account">
                        <el-input v-model="form.account" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input type="password" clearable show-password v-model="form.password"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch v-model="form.status"></el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                                <el-button @click="closeLayer">取 消</el-button>
                                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
                            </span>
            </el-dialog>

            <el-dialog

                    title="分配用户组"
                    :visible.sync="dialogVisible1"

            >

                <div style="width:600px;margin: auto;">
                    <el-transfer
                            filterable
                            filter-placeholder="请输入用户组名称"
                            v-model="transferValue"
                            :titles="['可分配的用户组', '已拥有的用户组']"
                            :data="userGroupList">
                    </el-transfer>
                </div>


                <span slot="footer" class="dialog-footer">
                                <el-button @click="closeSetUserGroupDialog()">关闭</el-button>
                                <el-button type="primary" @click="handeChargeUserGroup">确定</el-button>
                </span>

            </el-dialog>


            <el-table v-loading="tabLoading"

                      :data="tableData"
                      style="width: 100%">
                <el-table-column style="line-height: 35px;"
                                 label="用户信息"
                >
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <el-tag size="medium">{{ scope.row.account }}</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        label="用户组"
                >
                    <template slot-scope="scope">
                        <el-tag type="info" v-for="(item, index) in scope.row.group" :key="index"
                                style="margin-right: 5px; margin-bottom: 5px;">
                            <span>{{ item.details.user_group_name }}</span></el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        label="状态"
                >
                    <template slot-scope="scope">
                        <el-tag type="danger"
                                style="margin-right: 5px; margin-bottom: 5px;"><span>{{ scope.row.status }}</span>
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        label="创建日期"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="325">>
                    <template slot-scope="scope">

                        <el-button
                                v-if="permissions['api/op.user'] && permissions['api/op.user']['PUT']"
                                size="mini"
                                @click="openDialog('编辑用户', 'edit', scope.row)">编辑
                        </el-button>


                        <el-button style="margin-left: 10px"
                                   v-if="permissions['api/op.user'] && permissions['api/op.user']['PUT']"
                                   slot="reference"
                                   size="mini"
                                   type="primary"
                                   @click="openSetUserGroupDialog(scope.index, scope.row)">分配用户组
                        </el-button>


                        <el-popconfirm
                                :title="scope.row.status_value == '0' ? '确定要启用吗？' : '确定要禁用吗？'"
                                @confirm="handleChargeStatus(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       v-if="permissions['api/op.user'] && permissions['api/op.user']['PUT']"
                                       type="warning"
                                       slot="reference">{{ scope.row.status_value == '0' ? '启用' : '禁用' }}
                            </el-button>
                        </el-popconfirm>


                        <el-popconfirm
                                v-if="permissions['api/op.user'] && permissions['api/op.user']['DELETE']"
                                title="确定要删除吗？"
                                @confirm="handleDelete(scope.$index, scope.row)"
                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="danger"
                                       slot="reference">删除
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getUserListData"
                    :page-size="pageSize"
                    :total="tableTotal">

            </el-pagination>
        </div>

    </div>
</template>

<script>


    export default {
        name: "UserOp",
        data() {
            return {
                tabLoading: true,
                form: {
                    action: 'insert',
                    account: '',
                    password: '',
                    status: true
                },
                dialogTitle: '',
                dialogVisible: false,
                dialogVisible1: false,
                isShow: true,
                tableData: [],
                tableTotal: 0,
                currentPage: 1,
                pageSize: 10,
                details: [],
                //验证规则
                rules: {},
                userGroupList: [],
                transferValue: [],
                validateRules: {
                    insert: {
                        account: [
                            {required: true, message: '请输入账号', trigger: 'blur'},
                            {min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur'}
                        ],
                        password: [
                            {required: true, message: '请输入密码', trigger: 'blur'},
                            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                        ],
                    },
                    edit: {
                        account: [
                            {min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur'}
                        ],
                        password: [
                            {required: false, message: '请输入密码', trigger: 'blur'},
                            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                        ],
                    }
                },
            }
        },
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        watch: {
            rules: function () {
                let form = this.$refs['form'];
                if (form != undefined) {
                    setTimeout(function () {
                        form.clearValidate();
                    }, 1)
                }
            }
        },
        mounted: function () {

            console.log(this.permissions);
            this.getUserListData();

        },
        methods: {
            //加载表格数据
            getUserListData(page) {


                const _this = this;
                _this.tabLoading = true;
                _this.tableData = [];
                if (page != undefined) {
                    _this.currentPage = page;
                }



                _this.axios.get('api/op/user', {
                    params: {},
                    headers: {'page': _this.currentPage, "page-size": _this.pageSize},
                }).then(function (response) {

                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {

                    console.log(error);

                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            openSetUserGroupDialog(index, rowData) {

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                this.transferValue = [];
                this.userGroupList = [];
                this.details = rowData;

                const _this = this;


                _this.axios.get('api/op/userGroup', {
                    params: {},
                    headers: {'page': 1, "page-size": 999999},
                }).then(function (response) {

                        loading.close();
                        _this.dialogVisible1 = true;

                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.msg);
                        } else {

                            for (let i in response.data.data.list) {
                                let item = response.data.data.list[i];
                                _this.userGroupList.push(
                                    {
                                        key: item.id,
                                        label: item.user_group_name,
                                    }
                                )
                            }

                            rowData.group.forEach(function (e) {
                                _this.transferValue.push(e.user_group_id)
                            });

                        }

                    }
                ).catch(function (error) {

                    loading.close();

                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.msg);
                    }

                });
            },
            closeSetUserGroupDialog() {
                this.dialogVisible1 = false;
            },
            handeChargeUserGroup() {

                const _this = this;

                _this.dialogVisible1 = false;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                const requestData = {
                    type: 'userGroup',
                    group_ids: _this.transferValue
                };

                _this.axios.put('/api/op/user/' + _this.details.id, requestData).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.closeLayer();
                        _this.getUserListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            // 打开弹出层
            openDialog(title, action, data = {}) {

                if (action != 'insert' && action != 'edit') {
                    return false;
                }
                if (action == 'edit') {
                    this.form.account = data.account;
                    this.form.password = '';
                    this.form.status = Boolean(data.status_value);
                    this.rules = this.validateRules.edit;
                } else if (action == 'insert') {

                    this.form.account = '';
                    this.form.password = '';
                    this.form.status = true;
                    this.rules = this.validateRules.insert;
                }

                this.form.action = action;
                this.dialogTitle = title;
                this.dialogVisible = true;
                this.currentSelectedUserId = data.id;

            },
            //关闭弹出层
            closeLayer() {
                this.dialogVisible = false;
                this.form.account = '';
                this.form.password = '';
                this.form.status = true;
            },
            //弹出层表单提交事件
            submitForm(formName) {
                const _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (_this.form.action == 'insert') {
                            _this.handleInsertUser();
                        } else {
                            _this.handeleEditUser();
                        }
                    } else {
                        return false;
                    }
                });
            },
            //新增用户
            handleInsertUser() {

                const _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.post('/api/op/user', {
                    params: {
                        account: _this.form.account,
                        password: _this.form.password,
                        status: _this.form.status,
                    }
                }).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.closeLayer();
                        _this.getUserListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },
            //编辑用户
            handeleEditUser() {

                const _this = this;

                let requestData = {
                    type: 'info',
                    status: this.form.status
                };

                if (_this.form.password != '') {
                    requestData.password = _this.form.password
                }

                if (_this.form.account != '') {
                    requestData.account = _this.form.account
                }

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.put('/api/op/user/' + _this.currentSelectedUserId, requestData).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.closeLayer();
                        _this.getUserListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
            },
            //改变用户组
            handleEditUserGroup(index, row) {
                console.log(index, row);
            },
            // 变更账号状态
            handleChargeStatus(index, row) {

                let _this = this;
                let status = row.status_value == 1 ? 0 : 1;


                _this.tabLoading = true;

                _this.axios.put('/api/op/user/' + row.id, {status}).then(function (response) {

                    _this.tabLoading = false;

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.getUserListData();

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    _this.tabLoading = false;

                    const message = error.response.data.message == undefined ? "操作失败,请重试" : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: message
                    });

                });

            },
            //删除用户
            handleDelete(index, row) {

                let _this = this;

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.delete('/api/op/user/' + row.id).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.getUserListData();

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });

                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();

                    const message = error.response.data.message == undefined ? "操作失败,请重试" : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: message
                    });

                });

            }
        }
    }
</script>

<style scoped>

</style>