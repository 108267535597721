<template>
    <div>


        <div style="margin-bottom: 10px;">
            <template>

                <el-select v-model="search.userType" placeholder="请选择用户类型">
                    <el-option
                            v-for="item in userTypeList"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                    </el-option>
                </el-select>

                <el-select style="margin-left: 10px;" v-model="search.status" placeholder="请选择状态类型">
                    <el-option
                            v-for="item in statusList"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                    </el-option>
                </el-select>


                <el-date-picker style="margin-left: 10px;"
                                v-model="search.time"
                                :picker-options="pickerOptions"
                                value-format="timestamp"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right">
                </el-date-picker>

            </template>


            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch">搜索
            </el-button>

        </div>

        <el-dialog
                top="1vh"
                :title="dialogTitle"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="handleDialogClose"
                :visible.sync="dialogVisible"
                width="35%"
        >

            <el-form size="mini" label-width="100px">
                <el-form-item label="提现单号">
                    <el-input disabled v-model="details.order_num"></el-input>
                </el-form-item>
                <el-form-item label="用户名称">
                    <el-input v-if="details.user_type == 1" disabled :value="details.agent.agent_name"></el-input>
                    <el-input v-else disabled :value="details.merchants.name ? details.merchants.name : ''"></el-input>
                </el-form-item>
                <el-form-item label="用户类型">
                    <el-input disabled v-model="details.user_type_string"></el-input>
                </el-form-item>
                <el-form-item label="提现金额">
                    <el-input disabled v-model="details.amount"></el-input>
                </el-form-item>
                <el-form-item label="手续费率">
                    <el-input disabled v-model="details.rate"></el-input>
                </el-form-item>
                <el-form-item label="手续费">
                    <el-input disabled v-model="details.rate_amount"></el-input>
                </el-form-item>
                <el-form-item label="收款类型">
                    <el-input disabled v-model="details.receipt_type_string"></el-input>
                </el-form-item>
                <div v-if="details.receipt_type == 1">
                    <el-form-item label="收款银行">
                        <el-input disabled v-model="details.bank_name"></el-input>
                    </el-form-item>
                    <el-form-item label="开户行">
                        <el-input disabled v-model="details.bank_belong"></el-input>
                    </el-form-item>
                    <el-form-item label="银行卡号">
                        <el-input disabled v-model="details.receipt_account"></el-input>
                    </el-form-item>
                    <el-form-item label="收款户名">
                        <el-input disabled v-model="details.receipt_real_name"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="状态">
                    <el-input disabled v-model="details.status_string"></el-input>
                </el-form-item>
                <el-form-item label="提现时间">
                    <el-input disabled :value="details.addtime| dateFormat"></el-input>
                </el-form-item>
                <el-form-item label="打款时间">
                    <el-input v-if="details.remittance_time" disabled
                              :value="details.remittance_time | dateFormat"></el-input>
                    <el-input v-else disabled></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-if="details.status == 1" v-model="details.status_notes"></el-input>
                    <el-input v-else disabled v-model="details.status_notes"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-popconfirm
                        v-if="details.status == 1  && permissions['api/op.withdrawal'] && permissions['api/op.withdrawal']['PUT']"
                        title="确定要进行打款吗？"
                        @confirm="transfer(999999, details)"
                >
                    <el-button size="medium" style="margin-left: 10px"
                               type="success"
                               slot="reference"><i style="margin-right: 5px;" class="el-icon-success"/>打款
                    </el-button>
                </el-popconfirm>

                <el-popconfirm
                        v-if="details.status == 1  && permissions['api/op.withdrawal'] && permissions['api/op.withdrawal']['PUT']"
                        title="确定要拒绝打款吗？"
                        @confirm="rejected(999999, details)"
                >
                    <el-button size="medium" style="margin-left: 10px"
                               type="danger"
                               slot="reference"><i style="margin-right: 5px;" class="el-icon-error"/>拒绝打款
                    </el-button>
                </el-popconfirm>

                <el-button style="margin-left: 10px" size="medium" type="" @click="dialogVisible = false">关闭</el-button>

            </span>

        </el-dialog>

        <template>
            <el-table
                    size="small"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="提现单号"
                        width="150">
                    <template slot-scope="scope">
                        <span>{{scope.row.order_num}}</span>
                    </template>
                </el-table-column>


                <el-table-column
                        label="提现用户"
                        width="300"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.user_type == 1 ? scope.row.agent.agent_name : scope.row.merchants.name}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="提现金额"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.amount}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="手续费率"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.rate}}%</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="手续费"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.rate_amount}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="实际打款"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.amount - scope.row.rate_amount}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="提现时间"
                        width="200"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-timer"/>
                        <span>{{scope.row.addtime | dateFormat}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="状态"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">待打款</span>
                        <span v-if="scope.row.status == 2" style="color: red;">已打款</span>
                        <span v-if="scope.row.status == 3" style="color: red;">拒绝打款</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="备注"
                        width="200"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.status_notes}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="" width="350">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="primary"
                                @click="openDialog(scope.$index, scope.row)"><i class="el-icon-info"
                                                                                style="margin-right: 5px;"/>提现详情
                        </el-button>

                        <el-popconfirm
                                v-if="permissions['api/op.withdrawal'] && permissions['api/op.withdrawal']['PUT']"
                                title="确定要进行打款吗？"
                                @confirm="transfer(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="success"
                                       :disabled="scope.row.status == 1 ? false : true"
                                       slot="reference"><i style="margin-right: 5px;" class="el-icon-success"/>打款
                            </el-button>
                        </el-popconfirm>

                        <el-popconfirm
                                v-if="permissions['api/op.withdrawal'] && permissions['api/op.withdrawal']['PUT']"
                                title="确定要拒绝打款吗？"
                                @confirm="rejected(scope.$index, scope.row)"

                        >
                            <el-button style="margin-left: 10px" size="mini"
                                       type="danger"
                                       :disabled="scope.row.status == 1 ? false : true"
                                       slot="reference"><i style="margin-right: 5px;" class="el-icon-error"/>拒绝打款
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import Vue from 'vue'

    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2, '0')

        //小时
        const hh = (dt.getHours() + '').padStart(2, '0')
        //分钟
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        //秒数
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })


    export default {
        name: "Withdrawal",
        computed: {
            permissions() {
                return this.$store.state.user.allPermissions;
            }
        },
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '7天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '30天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '90天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '180天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        },
                    }, {
                        text: '3650天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3650);
                            picker.$emit('pick', [start, end]);
                        },
                    }]
                },
                search: {
                    status: 0,
                    userType: 1,
                    time: null,
                },
                userTypeList: [
                    {name: '代理商', type: 1},
                    {name: '商户', type: 3},
                ],
                statusList: [
                    {name: '全部', type: 0},
                    {name: '已打款', type: 2},
                    {name: '待打款', type: 1},
                    {name: '拒绝打款', type: 3},
                ],
                details: {
                    order_num: '',
                    merchants: {},
                    status_notes: '',
                },
                dialogTitle: '提现详情',
                dialogVisible: false,
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                orderDetails: {
                    userInfo: [],
                    pageSize: 10,
                    currentPage: 1,
                    tableTotal: 0,
                    tabLoading: false,
                    tableData: [],
                },
                tableData: [],
            }
        },
        mounted: function () {
            this.getListData();

        },
        methods: {
            // 获取提现列表
            getListData(page) {


                const _this = this;
                _this.tabLoading = true;
                if (page != undefined) {
                    _this.currentPage = page;
                }

                _this.axios.get('api/op/withdrawal', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'user-type': _this.search.userType,
                        'status': _this.search.status,
                        'start-time': _this.search.time != null ? _this.search.time[0] / 1000 : '',
                        'end-time': _this.search.time != null ? _this.search.time[1] / 1000 : '',
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {
                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 打开订单详情弹层
            openDialog(index, row) {
                const _this = this;
                _this.dialogVisible = true;

                if (row.user_type == 1) {
                    row.user_type_string = '代理商'
                } else if (row.user_type == 3) {
                    row.user_type_string = '商户'
                } else {
                    row.user_type_string = '未知'
                }

                if (row.receipt_type == 1) {
                    row.receipt_type_string = '银行卡'
                } else if (row.receipt_type == 2) {
                    row.receipt_type_string = '微信'
                } else if (row.receipt_type == 3) {
                    row.receipt_type_string = '支付宝'
                } else {
                    row.receipt_type_string = '未知'
                }

                if (row.status == 1) {
                    row.status_string = '待打款'
                } else if (row.status == 2) {
                    row.status_string = '已打款'
                } else if (row.status == 3) {
                    row.status_string = '拒绝打款'
                } else {
                    row.status_string = '未知'
                }

                _this.details = JSON.parse(JSON.stringify(row));

            },
            // 确认打款
            transfer(index, row) {

                const _this = this;

                let rquestOption = {
                    params: {},
                    headers: {
                        action: 'transfer'
                    }
                };

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.put('/api/op/withdrawal/' + row.id, '', rquestOption).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.dialogVisible = false;
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 驳回打款
            rejected(index, row) {


                const _this = this;

                let rquestOption = {
                    params: {
                        note: _this.details.status_notes
                    },
                    headers: {
                        action: 'rejected'
                    }
                };

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                _this.axios.put('/api/op/withdrawal/' + row.id, '', rquestOption).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.dialogVisible = false;
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    console.log(error)

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })
                console.log(index);

            },
            // 搜索
            handleSearch() {
                const _this = this;
                _this.getListData();
            },

        }
    }
</script>

<style scoped>


</style>