<template>
    <div id="main" v-loading.fullscreen.lock="fullscreenLoading">

        <div class="login-box">
            <div class="box-card">
                <el-row>
                    <el-col :span="24" class="title">
                        <h3>Hi Charge </h3><h4>共享充电宝后台管理系统</h4>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form ref="loginForm" :model="form" :rules="validateRules" label-width="0"
                                 class="demo-ruleForm">
                            <el-form-item prop="account">
                                <el-input maxlength="16" clearable prefix-icon="el-icon-user-solid"
                                          v-model="form.account" placeholder="请输入用户名" type="text"></el-input>
                            </el-form-item>

                            <el-form-item prop="password">
                                <el-input maxlength="16" clearable show-password prefix-icon="el-icon-key"
                                          v-model="form.password" placeholder="请输入密码" type="password"></el-input>
                            </el-form-item>




                            <el-button class="login-button" style="width:80%;" type="primary" round @click="login">
                                进入系统
                            </el-button>
                        </el-form>
                    </el-col>
                </el-row>
            </div>
        </div>

    </div>
</template>

<script>

    import axios from 'axios';

    export default {
        name: "Login",
        data() {
            return {
                validateRules: {
                    account: [
                        {
                            required: true,
                            message: '请输入账号',
                            trigger: 'blur'
                        },
                        {
                            min: 3,
                            max: 16,
                            message: '长度在3到16个字符',
                            trigger: 'blur'
                        },
                    ],
                    password: [
                        {
                            required: true,
                            message: '请输入密码',
                            trigger: 'blur'
                        },
                        {
                            min: 6,
                            max: 16,
                            message: '长度在6到16个字符',
                            trigger: 'blur'
                        },
                    ]
                },
                fullscreenLoading: false,
                form: {
                    account: null,
                    password: null,
                }
            }
        },
        beforeCreate(){
            let user = JSON.parse(localStorage.getItem('user'));
            if (user){
                this.$router.replace('/home');
                return false;
            }
        },
        methods: {
            //进行登录业务逻辑
            login() {

                const _this = this;

                const account = this.form.account;
                const password = this.form.password;

                this.$refs['loginForm'].validate((volid) => {

                    if (volid) {

                        this.fullscreenLoading = true;

                        axios.post('api/op/token', {account, password}).then(function (response) {

                            _this.fullscreenLoading = false;

                            const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                            if (response.data.status != undefined && response.data.status == 1) {

                                _this.fullscreenLoading = false;

                                _this.$notify({
                                    title: 'Hi Charge',
                                    message: '欢迎回来' + response.data.data.account,
                                    type: 'success',
                                    duration: 1000,
                                    onClose() {

                                    }
                                });


                                 localStorage.user = JSON.stringify(response.data.data);

                                 _this.$router.replace('home');

                            } else {

                                _this.fullscreenLoading = false;
                                _this.$notify.error({
                                    title: '错误',
                                    message: message
                                });

                            }

                        }).catch(function (error) {

                            console.log(error);

                            _this.fullscreenLoading = false;

                            let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                            _this.$notify.error({
                                title: '错误',
                                message: msg
                            });

                        })

                    }

                    return false;

                });
            }
        }
    }
</script>

<style scoped>

    #main {
        height: 100%;
        width: 100%;
        background-color: #fff;
        background-image: url("https://hicharge.net/statics/images/login/1.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: -160px;

    }

    #main .login-box {
        position: relative;
        border-radius: 24px;
        top: 220px;
        width: 500px;
        height: 320px;
        margin: auto;
        padding: 30px;
        background-color: rgba(255, 255, 255, 0.9);
    }

    #main .login-box .title {
        margin-bottom: 20px;
    }

    #main .login-box .login-button {
        margin-top: 10px;
    }

    #main .login-box >>> .el-form-item {
        margin-bottom: 20px;
    }


</style>