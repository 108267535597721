<template>
    <div>


        <div style="margin-bottom: 10px;">
            <template>
                <el-select v-model="search.userType" placeholder="请选择用户类型">
                    <el-option
                            v-for="item in userTypeList"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                    </el-option>
                </el-select>
            </template>
            <template>
                <el-select style="margin-left:10px;width: 100px;" v-model="search.status" placeholder="请选择订单状态">
                    <el-option
                            v-for="item in orderStatusList"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                    </el-option>
                </el-select>
            </template>

            <el-date-picker style="margin-left: 10px;"
                            v-model="search.time"
                            :picker-options="pickerOptions"
                            value-format="timestamp"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            align="right">
            </el-date-picker>

            <el-input style="margin-left:10px;width: 200px;"
                      placeholder="请输入用户信息"
                      v-model="search.userInfo"
                      clearable>
            </el-input>


            <div style="margin-top: 10px;"></div>

            <el-input style="margin-right:10px;width: 168px;"
                      placeholder="请输入用户ID"
                      v-model="search.uid"
                      clearable>
            </el-input>

            <el-input style="width: 250px;"
                      placeholder="请输入单号信息"
                      v-model="search.orderInfo"
                      clearable>
            </el-input>
            <el-input style="margin-left:10px;width: 250px;"
                      placeholder="请输入商家信息"
                      v-model="search.merchantsInfo"
                      clearable>
            </el-input>
            <el-input style="margin-left:10px;width: 250px;"
                      placeholder="请输入设备信息"
                      v-model="search.equipmentInfo"
                      clearable>
            </el-input>

            <el-button style="margin-left:10px;" type="primary" icon="el-icon-search" @click="handleSearch()">搜索
            </el-button>

        </div>

        <el-dialog
                top="1vh"
                title="结束订单"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialogVisible"
                :before-close="handleClose"
                width="30%"
        >

            <el-form label-width="100px" class="demo-ruleForm" style="padding-right: 50px;">

                <el-form-item label="租借时间" prop="starttime">
                    <el-date-picker
                            v-model="details.starttime"
                            value-format="timestamp"
                            style="width: 100%"
                            disabled=""
                            type="datetime"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="归还时间">
                    <el-date-picker
                            style="width: 100%"
                            type="datetime"
                            v-model="form.endTime"
                            value-format="timestamp"
                            placeholder="请选择归还时间">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="备注" prop="remarks">
                    <el-input v-model="form.remarks" type="textarea"></el-input>
                </el-form-item>


            </el-form>


            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleSubmit()">确 定</el-button>
            </span>
        </el-dialog>

        <template>
            <el-table
                    size="mini"
                    v-loading="tabLoading"
                    :data="tableData"
                    style="width: 100%">

                <el-table-column
                        label="用户类型"
                >
                    <template slot-scope="scope">

                        <div v-if="scope.row.user">
                            <span v-if="scope.row.user.user_type == 1">微信/小程序</span>
                            <span v-else-if="scope.row.user.user_type == 3">手机号</span>
                            <span v-else-if="scope.row.user.user_type == 11">微信/APP</span>
                            <span v-else-if="scope.row.user.user_type == 12">支付宝/APP</span>
                            <span v-else-if="scope.row.user.user_type == 13">Google</span>
                            <span v-else-if="scope.row.user.user_type == 14">Apple</span>
                            <span v-else-if="scope.row.user.user_type == 15">Line</span>
                            <span v-else-if="scope.row.user.user_type == 16">Facebook</span>
                            <span v-else> 未知</span>
                        </div>
                        <div v-else>未知</div>

                    </template>
                </el-table-column>

                <el-table-column style="line-height: 35px;"
                                 label="用户信息"
                                 width="200"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.user">
                            <el-popover trigger="hover" placement="top">

                                <p v-if="scope.row.user.picture && scope.row.user.user_type != 1 && scope.row.user.user_type != 3 &&  scope.row.user.user_type != 14">

                                    <img v-if="scope.row.user.picture" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>

                                </p>

                                <p v-if="scope.row.user.name && scope.row.user.user_type != 1 &&  scope.row.user.user_type != 3 ">
                                    APP昵称:{{
                                    scope.row.user.name}}</p>
                                <p>性别:
                                    <span v-if="scope.row.user.user_type == 1">
                                    <span v-if="scope.row.user.gender == 1">男</span>
                                    <span v-else-if="scope.row.user.gender == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                                    <span v-else-if="scope.row.user.user_type == 11">
                                    <span v-if="scope.row.user.app_sex == 1">男</span>
                                    <span v-else-if="scope.row.user.app_sex == 2">女</span>
                                    <span v-else>未知</span>
                                </span>
                                    <span v-else>
                                    <span>未知</span>
                                </span>
                                </p>
                                <p v-if="scope.row.user.mobile">手机号:
                                    +{{scope.row.user.country_code}}{{scope.row.user.mobile}}</p>
                                <p>语言:{{scope.row.user.language ? scope.row.user.language : 'zh-cn'}}</p>
                                <p>地区:
                                    <span v-if="scope.row.user.user_type == 1">
                                    {{scope.row.user.province}} {{scope.row.user.province}} {{scope.row.user.city}}
                                </span>
                                    <span v-else-if="scope.row.user.user_type == 11">
                                    {{scope.row.user.app_country}} {{scope.row.user.app_province}} {{scope.row.user.app_city}}
                                </span>
                                    <span v-else>
                                    <span></span>
                                </span>
                                </p>
                                <div slot="reference" class="name-wrapper" style="line-height: 40px;">


                                    <img v-if="scope.row.user.user_type == 1" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 3" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 11" :src="scope.row.user.app_headimgurl"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 12" :src="scope.row.user.ali_avatar"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 13" :src="scope.row.user.google_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 14" :src="scope.row.user.picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 15"
                                         :src="scope.row.user.line_pictureUrl"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 16"
                                         :src="scope.row.user.facebook_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else-if="scope.row.user.user_type == 16"
                                         :src="scope.row.user.facebook_picture"
                                         style="width:40px;border-radius: 2px;"/>
                                    <img v-else style="width:40px;border-radius: 2px;"
                                         src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7cxCzyScSH79WkL5fqaGCm73xbS4tsWAp18BzvfPCp1kAK5NtWUt5KDCp6LQHr5ylR5jsshuSSKQ/132"/>

                                    <span style="position: relative;top:-12px;left:5px;" size="medium">
                                    <span v-if="scope.row.user.user_type == 1">{{ scope.row.user.name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 3">{{ scope.row.user.name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 11">{{ scope.row.user.app_nickname}}</span>
                                    <span v-else-if="scope.row.user.user_type == 12">{{ scope.row.user.ali_nick_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 13">{{ scope.row.user.google_display_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 14">{{ scope.row.user.apple_name}}</span>
                                    <span v-else-if="scope.row.user.user_type == 15">{{ scope.row.user.line_displayname}}</span>
                                    <span v-else-if="scope.row.user.user_type == 16">{{ scope.row.user.facebook_name}}</span>
                                    <span v-else>暂无</span>
                                </span>
                                </div>
                            </el-popover>
                        </div>
                        <div v-else>未知</div>
                    </template>


                </el-table-column>

                <el-table-column
                        label="商家"
                        width="200"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.merchants">{{scope.row.merchants.name}}</span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="设备信息"
                        width="250"
                >
                    <template slot-scope="scope">
                        <div><span>机柜编号：{{scope.row.cabinetid}}</span></div>
                        <div><span>充电宝编号：{{scope.row.deviceid}}</span></div>

                    </template>
                </el-table-column>

                <el-table-column
                        label="计费信息"
                        width="200"
                >
                    <template slot-scope="scope">
                        <div>
                            押金：
                            <span>{{scope.row.deposit}}</span>
                        </div>
                        <div>
                            收费方式：
                            {{scope.row.per_price}} /
                            <span v-if="scope.row.billingunit == 1">小时</span>
                            <span v-else-if="scope.row.billingunit == 2">分钟</span>
                            <span v-else>未知</span>
                        </div>
                        <div>
                            免费时间：
                            <span>{{scope.row.freetime}}分钟</span>
                        </div>
                        <div>
                            每日封顶：
                            <span v-if="scope.row.ceiling > 0">{{scope.row.ceiling}}</span>
                            <span v-else>无封顶</span>
                        </div>
                        <div>
                            超时罚款：
                            <span>{{scope.row.cost_price ? scope.row.cost_price: '400.00'}}</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                        label="订单金额信息"
                        width="150"
                >
                    <template slot-scope="scope">


                        <div v-if="scope.row.status == 2 || scope.row.status == 3">
                            <div>
                                订单金额：
                                <span v-if="scope.row.status == 2 || scope.row.status == 3">{{scope.row.amount}}</span>
                            </div>
                            <div v-if="scope.row.discountamount > 0">
                                优惠金额：
                                <span v-if="scope.row.status == 2 || scope.row.status == 3">{{scope.row.discountamount ? scope.row.discountamount : "0.00"}}</span>
                            </div>
                            <div>
                                实际付款：
                                <span v-if="scope.row.status == 2 || scope.row.status == 3">{{scope.row.real_amount}}</span>
                            </div>
                            <div v-if="scope.row.status == 3">
                                超时罚款：
                                <span>{{scope.row.cost_price ? scope.row.cost_price : '400.00' }}</span>
                            </div>

                        </div>

                        <div v-else>
                            <!--                            租借中-->
                        </div>

                    </template>
                </el-table-column>

                <el-table-column
                        label="订单信息"
                        width="250"
                >
                    <template slot-scope="scope">

                        <div><span>订单名称:{{ scope.row.name }}</span></div>
                        <div><span>订单编号:{{ scope.row.order_no }}</span></div>
                        <div v-if="scope.row.status == 2 || scope.row.status == 3"><span>租借时间：<i
                                class="el-icon-time"></i>{{ scope.row.starttime | dateFormat }}</span></div>
                        <div v-if="scope.row.status == 2 || scope.row.status == 3"><span>归还时间：<i
                                class="el-icon-time"></i>{{ scope.row.endtime | dateFormat }}</span></div>
                        <div v-if="scope.row.remarks"><span>备注:{{ scope.row.remarks }}</span></div>
                    </template>
                </el-table-column>

                <el-table-column
                        label="状态"

                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.status == 1"><span>租借中</span></div>
                        <div v-else-if="scope.row.status == 2"><span>已完成</span></div>
                        <div v-else-if="scope.row.status == 3"><span style="color: red;">超时</span></div>
                        <div v-else-if="scope.row.status == 4"><span style="color: red;">异常</span></div>
                        <div v-else><span>未知</span></div>
                    </template>
                </el-table-column>


                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-popover
                                v-if="scope.row.status == 1 || scope.row.status == 4"
                                @confirm="handleDelete(scope.$index, scope.row)"
                        >
                            <p>确定要结束订单吗</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="endRentOrder(scope.row, 2)">不扣费</el-button>
                                <el-button type="primary" size="mini" @click="openDialog(scope.row)">扣费</el-button>
                            </div>
                            <el-button style="margin-left: 10px" size="mini"
                                       type="danger"
                                       slot="reference">结束订单
                            </el-button>
                        </el-popover>

                    </template>
                </el-table-column>

            </el-table>


        </template>

        <div style="margin-top: 10px;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="getListData"
                    :page-size="pageSize"
                    :total="tableTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    let Base64 = require('js-base64').Base64
    import Vue from 'vue'

    Vue.filter('dateFormat', originVal => {
        const dt = new Date(originVal * 1000)
        //年的时间
        const y = dt.getFullYear()
        //月的时间  .padStart 不足两位自动补0  2位长度
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        //日的时间
        const d = (dt.getDate() + '').padStart(2, '0')

        //小时
        const hh = (dt.getHours() + '').padStart(2, '0')
        //分钟
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        //秒数
        const ss = (dt.getSeconds() + '').padStart(2, '0')

        //将它们拼接成完整的字符串
        //return 'yyyy-mm-dd hh:mm:ss'  可以改成下面的方法
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    })

    export default {
        name: "Order",
        props: [
            'device_id',
            'uid'
        ],
        data() {
            return {
                search: {
                    status: 0,
                    userType: 0,
                    time: null,
                    orderStatus: 0,
                    merchantsInfo: '',
                    userInfo: '',
                    orderInfo: '',
                    equipmentInfo: '',
                    devInfo: '',
                    uid: '',
                },
                row: {
                    balance: 0.00,
                    deposit: 0.00,
                },
                userTypeList: [
                    {name: '全部', type: 0},
                    {name: '微信/小程序', type: 1},
                    {name: '手机号', type: 3},
                    {name: '微信/APP', type: 11},
                    {name: '支付宝/APP', type: 12},
                    {name: 'Goole', type: 13},
                    {name: 'Apple', type: 14},
                    {name: 'Line', type: 15},
                    {name: 'Facebook', type: 16},
                ],
                orderStatusList: [
                    {name: '全部', type: 0},
                    {name: '租借中', type: 1},
                    {name: '已完成', type: 2},
                    {name: '超时', type: 3},
                    {name: '异常', type: 4},
                ],
                form: {
                    endTime: '',
                    remarks: '',
                },
                pageSize: 20,
                currentPage: 1,
                tableTotal: 0,
                tabLoading: false,
                tableData: [],
                details: {},
                dialogVisible: false,
            }
        },
        mounted: function () {
            const _this = this;
            if (_this.device_id) {
                _this.search.equipmentInfo = _this.device_id;
            }
            if (_this.uid) {
                _this.search.uid = _this.uid;
            }
            _this.getListData();
        },
        methods: {
            //加载列表数据
            getListData(page) {

                const _this = this;
                _this.tabLoading = true;

                if (page != undefined) {
                    _this.currentPage = page;
                }

                _this.axios.get('api/op/orders', {
                    params: {},
                    headers: {
                        'page': _this.currentPage,
                        "page-size": _this.pageSize,
                        'status': _this.search.status,
                        'start-time': _this.search.time != null ? _this.search.time[0] / 1000 : '',
                        'end-time': _this.search.time != null ? _this.search.time[1] / 1000 : '',
                        'user-info': Base64.toBase64(_this.search.userInfo),
                        'uid': _this.search.uid,
                        'user-type': _this.search.userType,
                        'order-info': _this.search.orderInfo,
                        'equipment-info': _this.search.equipmentInfo,
                        'merchants-info': Base64.toBase64(_this.search.merchantsInfo),
                    },
                }).then(function (response) {


                        if (response.status != 200 || response.data.status == undefined) {
                            _this.$message.error('加载数据失败，正在重试');
                        } else if (response.data.status == 0) {
                            _this.$message.error(response.data.message);
                        } else {
                            _this.tableData = [];
                            _this.tableTotal = response.data.data.total;
                            _this.tableData = response.data.data.list;

                        }
                        _this.tabLoading = false;
                    }
                ).catch(function (error) {

                    console.log(error);
                    if (error.response.status != 200 || error.response.data.status == undefined) {
                        _this.$message.error('加载数据失败，正在重试');
                    } else if (error.response.data.status == 0) {
                        _this.$message.error(error.response.data.message);
                    }

                    _this.tabLoading = false;

                });
            },
            // 搜索
            handleSearch() {
                const _this = this;
                _this.currentPage = 1;
                _this.getListData();
            },
            // 打开表单弹层
            openDialog(row) {
                const _this = this;
                _this.details = JSON.parse(JSON.stringify(row));
                _this.details.starttime *= 1000;
                _this.form.remarks = '订单异常处理';
                _this.dialogVisible = true;
            },
            handleClose() {
                const _this = this;
                _this.details = {};
                _this.form.endTime = '';
                _this.dialogVisible = false;
            },
            //提交表单
            handleSubmit() {
                const _this = this;
                this.endRentOrder(_this.details, 1);
            },
            // 结束订单
            endRentOrder(row, model) {
                // model 1 扣费 2 = 不扣费
                const _this = this;

                if (model == 1) {
                    if (_this.form.endTime == '') {
                        _this.$notify.error({
                            title: '错误',
                            message: '请选择归还时间'
                        });
                        return false;
                    }
                }

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                const requestData = _this.form;

                requestData.endTime /= 1000;

                if (model != 1 && model != 2) {
                    requestData['model'] = 1;
                } else {
                    requestData['model'] = model;
                }


                _this.axios.put('/api/op/orders/' + row.id, requestData).then(function (response) {

                    loading.close();

                    const message = response.data.message == undefined ? "操作失败,请重试" : response.data.message;

                    if (response.data.status != undefined && response.data.status == 1) {

                        _this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success'
                        });
                        _this.dialogVisible = false;
                        _this.getListData();
                    } else {
                        _this.$notify.error({
                            title: '错误',
                            message: message
                        });
                    }

                }).catch(function (error) {

                    loading.close();
                    let msg = error.response.data.message == undefined ? error.message : error.response.data.message;

                    _this.$notify.error({
                        title: '错误',
                        message: msg
                    });

                })

            },

        }
    }
</script>

<style scoped>

</style>